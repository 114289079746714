import { Table } from "antd";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeLeaveModelPopup from "../../../components/modelpopup/EmployeeLeaveModelPopup";
import SearchBox from "../../../components/SearchBox";
import Spinnerloader from "../../../components/SpinnerLoader";
import InfoToast from "../../layout/InfoToast";
import { Popover } from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addEmployeeLeave,
  deleteEmployeeLeave,
  fetchAllEmployeesLeave,
  updateEmployeeLeave,
} from "../../../hooks/useEmployeeLeave";
import {
  disableClick,
  formatDate,
} from "../../../assets/scss/utils/helpers/helper_functions";
import GlobalLoader from "../../layout/GlobalLoader";

const EmployeeLeave = () => {
  const [leaveTypeList, setLeaveTypeList] = useState([]);
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedLeave, setSelectedLeave] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [disableEditDelete, setDisableEditDelete] = useState(false);
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const { data, refetch,isLoading } = useQuery({
    queryKey: ["leaveTypes"],
    queryFn: fetchAllEmployeesLeave,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setLeaveTypeList(data.leave_types);
      setEmployeeList(data.employees);
    }
  }, [data]);

  const { mutate: addEmployeeLeaveMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addEmployeeLeave,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Leave Added", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Adding Leave",
        type: "Error",
      });
    },
  });
  // ===============================================Add Leave===============================
  const addEmployeeLeaveData = (data) => {
    addEmployeeLeaveMutation(data);
  };
  // ===============================================update Leave===============================

  const { mutate: updateEmployeeLeaveMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateEmployeeLeave,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Leave Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating Leave",
        type: "Error",
      });
    },
  });

  const updateEmployeeData = (data) => {
    updateEmployeeLeaveMutation(data);
  };
 
  // ===============================================delete Leave===============================

  const { mutate: deleteEmployeeLeaveMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteEmployeeLeave,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Leave Deleted", type: "Deleted" });
      }, 300);
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting Leave",
        type: "Error",
      });
    },
  });

  const deleteEmployeeData = (id) => {
    if (id) {
      deleteEmployeeLeaveMutation(id);
    }
  };

  const userElements = data?.leaves?.map((leave, index) => ({
    key: index + 1,
    id: leave.id,
    leavetype: leave?.leave_type?.type,
    leave_id: leave?.leave_type_id,
    employee_id: leave?.employee_id,
    from: leave?.from,
    to: leave.to,
    noofdays: leave.noofdays,
    reason: leave.reason,
    role: leave.role,
    status: leave.status,
    approvedby: leave.approvedby,
  }));

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Leave Type",
      dataIndex: "leavetype",
      render: (text) => <span>{text}</span>,
      sorter: (a, b) => a.leavetype.length - b.leavetype.length,
    },
    {
      title: "From",
      dataIndex: "from",
      render: (text) => <span>{formatDate(text)}</span>,
      sorter: (a, b) => {
        const dateA = new Date(a.from);
        const dateB = new Date(b.from);
        return dateB - dateA; // Ascending order
      },
      defaultSortOrder: "ascend",
    },
    {
      title: "To",
      dataIndex: "to",
      render: (text) => <span>{formatDate(text)}</span>,
      sorter: (a, b) => a.to.length - b.to.length,
    },
    {
      title: "Reason",
      dataIndex: "reason",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Leave Reason"
          trigger="hover"
          overlayStyle={{ maxWidth: "400px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="action-label text-left">
          
            <i
              className={
                text === "Pending"
                  ? "far fa-dot-circle text-info"
                  : text === "Approved"
                  ? "far fa-dot-circle text-success"
                  : "far fa-dot-circle text-danger"
              }
            />{" "}
            {text}
          <div className="dropdown-menu">
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-info" /> Pending
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-success" /> Approved
            </Link>
            <Link className="dropdown-item" to="#">
              <i className="far fa-dot-circle text-danger" /> Declined
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            onClick={() => {
              record.status === "Approved" || record.status === "Declined"
                ? setDisableEditDelete(true)
                : setDisableEditDelete(false);
            }}
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className={`dropdown-item ${
                disableEditDelete ? "disabled text-secondary" : ""
              }`}
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_leave"
              onClick={(e) =>
                disableClick(
                  disableEditDelete,
                  (e) => {
                    setSelectedLeave(record);
                    setSelectedId(record.id);
                  },
                  e
                )
              }
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className={`dropdown-item ${
                disableEditDelete ? "disabled text-secondary" : ""
              }`}
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={(e) =>
                disableClick(
                  disableEditDelete,
                  (e) => {
                    setSelectedId(record.id);
                  },
                  e
                )
              }
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  if (isLoading || isRefetching) {
    return (
      <GlobalLoader />
    );
  }
  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Leaves"
            title="Dashboard"
            subtitle="Leaves"
            modal="#add_leave"
            name="Add New"
          />
          <div className="row">
            {data?.leave_types?.map((stat, index) => (
              <div className="col-md-3" key={index}>
                <div className="stats-info">
                  <h6>{stat.type}</h6>
                  <h4>{stat.days}</h4>
                </div>
              </div>
            ))}
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <EmployeeLeaveModelPopup
        employeeList={employeeList}
        leaveTypeList={leaveTypeList}
        selectedId={selectedId}
        selectedLeave={selectedLeave}
        updateEmployeeData={updateEmployeeData}
        addEmployeeLeaveData={addEmployeeLeaveData}
      />
      <DeleteModal
        deleteDepartment={() => deleteEmployeeData(selectedId)}
        Name="Delete Employee Leaves"
      />
      <InfoToast
        success={success}
        handleClose={handleSolidPrimaryToastClose}
        toastType={toastType}
      />
    </>
  );
};

export default EmployeeLeave;
