import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchAllPolicies = async () => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.get(`policies`);
    return data; // Access the employee_queries array
};


export const addPolicies = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'));
  
    try {
      const { data } = await axiosInstance.post('policies', payload, {
        headers: {
          // Don't manually set Content-Type; let Axios handle this for FormData
          'Content-Type': 'multipart/form-data',
        },
      });
  
      return data; // Return the response for further handling
    } catch (error) {
      console.error("Error uploading policy:", error);
      throw error; // Propagate the error
    }
  };
  

export const deletePolicies = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`policies?id=${id}`)
    return data?.data;
}

export const updatePolicies = async (formData) => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.post('policies-update', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return data;
  };
  


