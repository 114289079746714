import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchIncrementRecord = async () => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.get(`employee-salary-increment`);
    return data; 
};


export const addIncrementRecord = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.post(`employee-salary-increment`, payload);
    return data; // Return full response for detailed handling
  };
  

export const deleteIncrementRecord = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`employee-salary-increment?id=${id}`)
    return data?.data;
}

export const updateIncrementRecord = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`employee-salary-increment`,res)
    return data;
}


