// import React from "react";
// import { Link } from "react-router-dom";
// import { fetchAttendanceLeave } from "../../../../../hooks/useEmployeeDashboard";

// const attendanceLeaves = () => {
//   return (
//     <div>
//       <div className="">
//         <div className="card flex-fill">
//           <div className="card-body">
//             <div className="statistic-header">
//               <h4>Attendance &amp; Leaves</h4>
//               <div className="dropdown statistic-dropdown">
//                 <Link
//                   className="dropdown-toggle"
//                   data-bs-toggle="dropdown"
//                   to="#"
//                 >
//                   2024
//                 </Link>
//                 <div className="dropdown-menu dropdown-menu-end">
//                   <Link to="#" className="dropdown-item">
//                     2025
//                   </Link>
//                   <Link to="#" className="dropdown-item">
//                     2026
//                   </Link>
//                   <Link to="#" className="dropdown-item">
//                     2027
//                   </Link>
//                 </div>
//               </div>
//             </div>
//             <div className="attendance-list">
//               <div className="row">
//                 <div className="col-md-4">
//                   <div className="attendance-details">
//                     <h4 className="text-primary">9</h4>
//                     <p>Total Leaves</p>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="attendance-details">
//                     <h4 className="text-pink">5.5</h4>
//                     <p>Leaves Taken</p>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="attendance-details">
//                     <h4 className="text-success">04</h4>
//                     <p>Leaves Absent</p>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="attendance-details">
//                     <h4 className="text-purple">0</h4>
//                     <p>Pending Approval</p>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="attendance-details">
//                     <h4 className="text-info">214</h4>
//                     <p>Working Days</p>
//                   </div>
//                 </div>
//                 <div className="col-md-4">
//                   <div className="attendance-details">
//                     <h4 className="text-danger">2</h4>
//                     <p>Loss of Pay</p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//             <div className="view-attendance">
//               <Link to="/leaves-employee">
//                 Apply Leave
//                 <i className="fe fe-arrow-right-circle" />
//               </Link>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default attendanceLeaves;

// dynamic attendance leave

import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query"; // React Query for data fetching
import { fetchAttendanceLeave } from "../../../../../hooks/useEmployeeDashboard"; // API function

const AttendanceLeaves = () => {
  // Initial placeholder data
  const initialData = {
    totalLeaves: 9,
    leavesTaken: 5.5,
    leavesAbsent: 4,
    pendingApproval: 0,
    workingDays: 214,
    lossOfPay: 2,
  };

  // Fetch data using React Query
  const {
    data: attendance = initialData,
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["attendance_leave"],
    queryFn: fetchAttendanceLeave,
    initialData, // Placeholder while loading data
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      <div className="" >
        <div className="card flex-fill" style={{height:"298px"}}>
          <div className="card-body">
            <div className="statistic-header">
              <h4>Attendance &amp; Leaves</h4>
              {/* <div className="dropdown statistic-dropdown">
                <Link
                  className="dropdown-toggle"
                  data-bs-toggle="dropdown"
                  to="#"
                >
                  2024
                </Link>
                <div className="dropdown-menu dropdown-menu-end">
                  <Link to="#" className="dropdown-item">
                    2025
                  </Link>
                  <Link to="#" className="dropdown-item">
                    2026
                  </Link>
                  <Link to="#" className="dropdown-item">
                    2027
                  </Link>
                </div>
              </div> */}
            </div>
            <div className="attendance-list">
              {isLoading && <p>Loading attendance data...</p>}
              {isError && (
                <p className="text-danger">
                  Failed to load data: {error?.message}
                </p>
              )}
              {!isLoading && !isError && (
                <div className="row">
                  <div className="col-md-4">
                    <div className="attendance-details">
                      <h4 className="text-primary">{attendance.totalLeaves}</h4>
                      <p>Total Leaves</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="attendance-details">
                      <h4 className="text-pink">{attendance.leavesTaken}</h4>
                      <p>Leaves Taken</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="attendance-details">
                      <h4 className="text-success">
                        {attendance.leavesAbsent}
                      </h4>
                      <p>Leaves Absent</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="attendance-details">
                      <h4 className="text-purple">
                        {attendance.pendingApproval}
                      </h4>
                      <p>Pending Approval</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="attendance-details">
                      <h4 className="text-info">{attendance.workingDays}</h4>
                      <p>Working Days</p>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="attendance-details">
                      <h4 className="text-danger">{attendance.lossOfPay}</h4>
                      <p>Loss of Pay</p>
                    </div>
                  </div>
                </div>
              )}
            </div>
            <div className="view-attendance">
              <Link to="/leaves-employee">
                Apply Leave
                <i className="fe fe-arrow-right-circle" />
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendanceLeaves;
