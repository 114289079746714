import React from 'react';

// Generic PermissionWrapper component
const PermissionWrapper = ({ permissionKey, children }) => {
  // Get permissions from localStorage (stored as an array of strings)
  const userData =  JSON.parse(localStorage.getItem('userData'));
  const permissions = userData?.permissions || [];

  // Check if the required permission exists in the permissions array
  const hasPermission = permissions.includes(permissionKey);

  // Render children if the user has the required permission
  return hasPermission ? <>{children}</> : null;
};

export default PermissionWrapper;
