import { axiosInstance } from "./axios";
import { setAuthenticationToken } from "./auth";

export const fetchEmployeeAlert = async () => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.get(`employees/alert`);
  return data;
};

export const addEmployeeAlert = async (payload) => {
  setAuthenticationToken(localStorage.getItem("token"));
  try {
    const { data } = await axiosInstance.post('employees/alert', payload, {
      headers: {
        // Don't manually set Content-Type; let Axios handle this for FormData
        'Content-Type': 'multipart/form-data',
      },
    });

    return data; // Return the response for further handling
  } catch (error) {
    console.error("Error uploading policy:", error);
    throw error; // Propagate the error
  }
};

export const updateEmployeeAlert = async (res) => {
  setAuthenticationToken(localStorage.getItem("token"));
  const { data } = await axiosInstance.put(`employees/alert`, res);
  return data?.data;
};

export const deleteAlert = async (id) => {
  setAuthenticationToken(localStorage.getItem('token'))
  const { data } = await axiosInstance.delete(`employees/alert?id=${id}`)
  return data?.data;
}