import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { fetchAllEmployees } from "../../hooks/useEmployee"; // Assuming this is the hook that fetches employees

const AddIncrementRecordModal = ({ onSubmit }) => {
  const [selectStaff, setSelectStaff] = useState(null);
  const [current_salary, setCurrent_salary] = useState("");
  const [incremented_salary, setIncremented_salary] = useState("");
  const [description, setDescription] = useState("");
  const [employeeData, setEmployeeData] = useState(null);

  // Fetching employees using useQuery
  const { data, isLoading, error } = useQuery({
    queryKey: ["all_employees"],
    queryFn: fetchAllEmployees,
    refetchOnWindowFocus: false,
  });

  // Transform data for Select component
  const options = data?.employees?.map((employee) => ({
    value: employee.id,
    label: `${employee.firstname}`, // Assuming firstname and lastname are available
  })) || [];

 

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const newRecord = {
      employee_id: selectStaff?.value,
      current_salary,
      incremented_salary,
      description,
    };

    onSubmit(newRecord);
  };

  return (
    <div id="add_record" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Increment Record</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Select Staff</label>
                    <Select
                      placeholder="Select a Staff"
                      options={options}
                      onChange={setSelectStaff}
                      value={selectStaff}
                      className="select"
                      styles={customStyles}
                    />
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Current Salary</label>
                    <input
                      className="form-control"
                      type="number"
                      value={current_salary}
                      onChange={(e) => setCurrent_salary(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Incremented Salary</label>
                    <input
                      className="form-control"
                      type="number"
                      value={incremented_salary}
                      onChange={(e) => setIncremented_salary(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Description</label>
                    <textarea
                      className="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="submit-section">
                <button className="btn btn-primary submit-btn" data-bs-dismiss="modal" type="submit">
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddIncrementRecordModal;
