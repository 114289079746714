import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table, Popover } from "antd";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import Breadcrumbs from "../../../components/Breadcrumbs";
import {
  addEmployeeQuery,
  deleteEmployeeQuery,
  fetchAllEmployeesQuery,
  updateEmployeeQuery,
  updateEmployeeQueryStatus,
} from "../../../hooks/useEmployeeQuery";
import { useMutation, useQuery } from "@tanstack/react-query";
import InfoToast from "../../layout/InfoToast";
import GlobalLoader from "../../layout/GlobalLoader";
import { AddEmployeeQueryPopup } from "../../../components/modelpopup/AddEmloyeeQueryPopup";
import PermissionWrapper from "../../../authorization/PermissionWrapper";

const EmployeeQuery = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [editable, setEditable] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [uploadedImages, setUploadedImages] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 3000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const { data, error, status, refetch, isLoading } = useQuery({
    queryKey: ["employee_queries"],
    queryFn: fetchAllEmployeesQuery,
    refetchOnWindowFocus: false,
  });

  const { mutate: addEmployeeMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addEmployeeQuery,
    onSuccess: (response) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));

      setToastType({
        label: response.message || "Query Added",
        type: "Added",
      });
    },
    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "Error",
      });
    },
  });

  const { mutate: updateEmployeeMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateEmployeeQuery,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Query Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: error.response?.data?.data || "An error occurred",
        type: "Error",
      });
    },
  });

  const { mutate: deleteEmployeeMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteEmployeeQuery,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Query Deleted", type: "Deleted" });
    },
    onError: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting Query",
        type: "Error",
      });
    },
  });

  const { mutate: updateQueryStatusMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateEmployeeQueryStatus,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Query Status Updated", type: "Updated" });
    },
    onError: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating Query status",
        type: "Error",
      });
    },
  });

  const addEmployeeData = (data) => {
    addEmployeeMutation(data);
  };

  const updateEmployeeData = (data) => {
    updateEmployeeMutation(data);
  };
  const deleteEmployeeData = (data) => {
    deleteEmployeeMutation(data);
  };

  const updateQueryStatus = (item, status) => {
    const data = {
      id: item.id,
      employee_id: item.employee_id,
      status: status,
      title: item.title,
      description: item.description,
      image: uploadedImages.length > 0 ? uploadedImages : item.image,
    };

    updateQueryStatusMutation(data);
  };

  // const handleDownload = (imageUrl) => {
  //   if (!imageUrl) {
  //     alert("No image available for download.");
  //     return;
  //   }
  //   alert(`Downloading: ${imageUrl}`);
    
  //   const link = document.createElement("a");
  //   link.href = imageUrl; // Use the correct image URL
  //   link.download = imageUrl; // Use the image file name
  //   link.click();
  // };
  

  if (isLoading || isRefetching) {
    return <GlobalLoader />;
  }

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Employee Name",
      render: (_, record) => (
        <span className="table-avatar">
          {record?.employee[0]?.firstname || ""}{" "}
          {record?.employee[0]?.lastname || ""}
        </span>
      ),
    },
    {
      title: "Query Title",
      dataIndex: "title",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Query"
          trigger="hover"
          overlayStyle={{ maxWidth: "400px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Query Description",
      dataIndex: "description",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Description"
          trigger="hover"
          overlayStyle={{ maxWidth: "400px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
    },
    {
      title: "Query Image",
      render: (_, record, file) => (
        <div className="table-avatar" >
          {record?.image ? (
            <Link to={record.image} target="blank">
            <img
              src={record.image}
              alt="Query"
              style={{ width: 40, height: 40, borderRadius: "50%" }}
              />
              </Link>
          ) : (
            <span>No Image</span>
          )}
        </div>
      )
      
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-left">
          <PermissionWrapper permissionKey={"edit-employee-query"}>
            <Link
              className="btn btn-white btn-sm btn-rounded dropdown-toggle"
              to="#"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i
                className={
                  text === "Pending"
                    ? "far fa-dot-circle text-danger"
                    : text === "In-Process"
                    ? "far fa-dot-circle text-info"
                    : "far fa-dot-circle text-success"
                }
              />{" "}
              {text}
            </Link>
            <div className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => updateQueryStatus(record, "Pending")}
              >
                <i className="far fa-dot-circle text-danger" /> Pending
              </Link>
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => updateQueryStatus(record, "in-process")}
              >
                <i className="far fa-dot-circle text-info" /> In-Process
              </Link>
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => updateQueryStatus(record, "Reviewed")}
              >
                <i className="far fa-dot-circle text-success" /> Reviewed
              </Link>
              <Link
                className="dropdown-item"
                to="#"
                onClick={() => updateQueryStatus(record, "Completed")}
              >
                <i className="far fa-dot-circle text-success" /> Completed
              </Link>
            </div>
          </PermissionWrapper>
          {/* <PermissionWrapper permissionKey={"view-employee-query"}>
            <Link className="btn btn-white btn-sm btn-rounded pr-2" to="#">
              <i
                className={
                  text === "Pending"
                    ? "far fa-dot-circle text-danger"
                    : text === "In-Process"
                    ? "far fa-dot-circle text-info"
                    : "far fa-dot-circle text-success"
                }
              />{" "}
              {text}
            </Link>
          </PermissionWrapper> */}
        </div>
      ),
    },

    {
      title: "Action",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>

          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"edit-employee-query"}>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#edit_query"
                onClick={() => {
                  setSelectedEmployee(record);
                  setEditable(true);
                }}
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </Link>
            </PermissionWrapper>
            <PermissionWrapper permissionKey={"delete-employee-query"}>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedEmployee(record);
              }}
            >
              <i className="fa-regular fa-trash-can m-r-5" /> Delete
            </Link>
              </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div
            onClick={() => {
              setEditable(false);
            }}
          >
            <Breadcrumbs
              maintitle="Employees Query"
              title="Dashboard"
              subtitle="Employees Query"
              modal="#add_query"
              name="Add Query"
              permissionKey="add-employee-query"
            />
          </div>

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  columns={columns}
                  dataSource={data?.employee_queries}
                  style={{ overflowX: "auto" }}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
        <AddEmployeeQueryPopup
          selectedEmployee={selectedEmployee}
          addEmployeeData={addEmployeeData}
          updateEmployeeData={updateEmployeeData}
          editable={editable}
          employees={data?.employees}
        />

        <DeleteModal
          id={selectedEmployee.id}
          deleteDepartment={deleteEmployeeData}
          Name="Delete Queries"
        />
        {success && (
          <InfoToast
            success={success}
            handleClose={handleSolidPrimaryToastClose}
            toastType={toastType}
          />
        )}
      </div>
    </>
  );
};

export default EmployeeQuery;
