import React, { useState } from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { fetchAllowancesDeductions, fetchSalary } from "../../hooks/useSalary";

const AddSalaryModal = ({ addSalaryData }) => {
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [formData, setFormData] = useState({
    netSalary: "",
    earnings: {},
    deductions: {},
  });
  const [errors, setErrors] = useState({});

  // Fetch Allowances & Deductions
  const {
    data: allowancesData,
    isLoading: isLoadingAllowances,
    error: allowancesError,
  } = useQuery({
    queryKey: ["allowancesDeductions"],
    queryFn: fetchAllowancesDeductions,
  });

  // Fetch Employee names
  const {
    data: salaryData,
    isLoading: isLoadingSalary,
    error: salaryError,
  } = useQuery({
    queryKey: ["employee-salary"],
    queryFn: fetchSalary,
    refetchOnWindowFocus: false,
  });

  // Transform employee data for dropdown options
  const employeeOptions =
    salaryData?.data?.employees?.map((employee) => ({
      value: employee.id,
      label: `${employee.firstname}`,
    })) || [];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedStaff)
      newErrors.selectedStaff = "Please select a staff member.";
    if (!formData.netSalary) newErrors.netSalary = "Net Salary is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleInputChange = (e, type, key) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [key]: value,
      },
    }));
  };
  
  // Submit handler
  const handleSubmit = (e) => {
    e.preventDefault();
    if (validateForm()) {
      // Map valid allowances and deductions
      const validAllowancesPayload = Object.keys(formData.earnings)
        .map((key) => ({
          allowance_id: allowances.find((a) => a.allowance === key)?.id || key,
          amount: formData.earnings[key],
        }))
        .filter((allowance) => allowance.allowance_id && allowance.amount);
  
      const validDeductionsPayload = Object.keys(formData.deductions)
        .map((key) => ({
          deduction_id: deductions.find((d) => d.deduction === key)?.id || key,
          amount: formData.deductions[key],
        }))
        .filter((deduction) => deduction.deduction_id && deduction.amount);
  
      const payload = {
        employee_id: selectedStaff.value,
        net_salary: formData.netSalary,
        allowances: validAllowancesPayload,
        deductions: validDeductionsPayload,
      };
  
      addSalaryData(payload);
    }
  };
  

  if (isLoadingAllowances || isLoadingSalary) return <div>Loading...</div>;
  if (allowancesError || salaryError)
    return <div>Error loading data. Please try again later.</div>;

  const { allowances, deduction: deductions } = allowancesData.data;

  return (
    <div id="add_salary" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add Staff Salary</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Select Staff</label>
                    <Select
                      placeholder="Select a Staff"
                      options={employeeOptions}
                      onChange={setSelectedStaff}
                      className="select"
                      styles={customStyles}
                    />
                    {errors.selectedStaff && (
                      <div className="text-danger">{errors.selectedStaff}</div>
                    )}
                  </div>
                </div>
                <div className="col-sm-6">
                  <label className="col-form-label">Net Salary</label>
                  <input
                    className="form-control"
                    type="text"
                    name="netSalary"
                    value={formData.netSalary}
                    onChange={(e) =>
                      setFormData({ ...formData, netSalary: e.target.value })
                    }
                  />
                  {errors.netSalary && (
                    <div className="text-danger">{errors.netSalary}</div>
                  )}
                </div>
              </div>
              {/* Allowances and Deductions */}
              <div className="row">
                <div className="col-sm-6">
                  <h4 className="text-primary">Allowances</h4>
                  {allowances.map((allowance) => (
                    <div className="mb-3" key={allowance.id}>
                      <label className="col-form-label">
                        {allowance.allowance}
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        value={formData.earnings[allowance.allowance] || ""}
                        onChange={(e) =>
                          handleInputChange(e, "earnings", allowance.allowance)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="col-sm-6">
                  <h4 className="text-primary">Deductions</h4>
                  {deductions.map((deduction) => (
                    <div className="mb-3" key={deduction.id}>
                      <label className="col-form-label">
                        {deduction.deduction}
                      </label>
                      <input
                        className="form-control"
                        type="number"
                        value={formData.deductions[deduction.deduction] || ""}
                        onChange={(e) =>
                          handleInputChange(e, "deductions", deduction.deduction)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddSalaryModal;
