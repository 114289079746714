import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import EditSalaryModal from "../../../../../components/modelpopup/EditSalaryModal";
import DeleteModal from "../../../../../components/modelpopup/deletePopup";
import PermissionWrapper from "../../../../../authorization/PermissionWrapper";
import { fetchSalary, deleteSalary,updateSalary } from "../../../../../hooks/useSalary";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteSalary from "../../../../../components/modelpopup/deleteSalaryPopup";
import GlobalLoader from "../../../../layout/GlobalLoader";
import InfoToast from "../../../../layout/InfoToast";

const SalaryTable = () => {
  const queryClient = useQueryClient();
  const [selectedSalaryId, setSelectedSalaryId] = useState(null);
   const [IsRefetch, setIsRefetch] = useState(false);
    const [pagination, setPagination] = useState({
       current: 1,
       pageSize: 10,
     });
    const [success, setSuccess] = useState("");
    // const [error, setError] = useState("");
    const [toastType, setToastType] = useState("");

  // Fetch salary data
  const { data, isLoading, error } = useQuery({
    queryKey: ["fetchSalary"],
    queryFn: fetchSalary,
  });

  // Mutation for deleting salary
  const { mutate: deleteSalaryMutation } = useMutation({
    mutationFn: deleteSalary,
    onSuccess: (response) => {
      queryClient.invalidateQueries(["fetchSalary"]);
      setToastType({
        label: response.message || "Salary Deleted ",
        type:"Deleted"
      })
    },
    onError: (error) => {
      alert(
        error.response?.data?.message ||
          "An error occurred while deleting the salary."
      );
    },
  });
 const{mutate: updateSalaryMutation} = useMutation({
    onMutate: () => {
      setIsRefetch(true);
    },
    mutationFn: updateSalary,
    onSuccess:(response)=> {
      IsRefetch()
      .then(()=> setIsRefetch(false))
      .then(()=> setSuccess(true))
    setToastType({
      label:response.message || "Salary Updated",
      type: "Added",
    });
    },
    onError:(error) => {
      setToastType({
        label:error.response?.data.message || "An Error Occured",
        type: "Update"
      });
    },
  })

  const updateSalaryData = (data) => {
    console.log("DATA E OYE",data)
    updateSalaryMutation(data);
  };

  const handleDelete = () => {
    if (selectedSalaryId) {
      deleteSalaryMutation(selectedSalaryId);
      setSelectedSalaryId(null); // Reset selected ID
    }
  };

  // Map salary data based on API response
  const salaryData = data?.data["employee-salary"]?.map((item) => ({
    id: item.salary?.id,
    name: `${item.firstname} ${item.lastname || ""}`.trim(),
    companyEmail: item.company_email || "N/A",
    netSalary: item.salary?.net_salary || "0",
    avatar: item.avatar,
    deductions:item.deductions,
    allowances:item.allowances

  }));
  
  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  
  if (isLoading) {
    return <GlobalLoader />;
  }

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">
          <span>{text}</span>
        </div>
      ),
    },
    {
      title: "Company Email",
      dataIndex: "companyEmail",
      render: (text) => <span>{text}</span>,
    },
    {
      title: "Net Salary",
      dataIndex: "netSalary",
      render: (text) => <span>${text}</span>,
    },
    {
      title: "Payslip",
      render: (record) => (
        <Link
          className="btn btn-sm btn-primary"
          to={`/salary-view`}
        >
          Generate Slip
        </Link>
      ),
    },
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"edit-employee-salary"}>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#edit_salary"
                onClick={() => setSelectedSalaryId(record.id)}
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </Link>
            </PermissionWrapper>
            <PermissionWrapper permissionKey={"delete-employee-salary"}>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete"
                onClick={() => setSelectedSalaryId(record.id)}
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </Link>
            </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              className="table-striped"
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={salaryData}
              rowKey={(record) => record.id}
            />
          </div>
        </div>
      </div>
      {console.log(salaryData)}
      <EditSalaryModal
        editSalaryData={updateSalaryData}
        selectedSalary={
          salaryData?.find((salary) => salary.id === selectedSalaryId) || null
        }
      />
      <DeleteSalary Name="Delete Salary" handleDelete={handleDelete} />
      {success && (
          <InfoToast
            success={success}
            handleClose={handleSolidPrimaryToastClose}
            toastType={toastType}
          />
        )}
    </>
  );
};

export default SalaryTable;
