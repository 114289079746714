import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table, Popover } from "antd";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import AddSalaryModal from "../../../../components/modelpopup/AddSalaryModal";
import AddIncrementRecordModal from "../../../../components/modelpopup/AddIncrementRecordModal";
import { useQuery, useMutation } from "@tanstack/react-query";
import InfoToast from "../../../layout/InfoToast";
import {
  fetchIncrementRecord,
  addIncrementRecord,
  updateIncrementRecord,
  deleteIncrementRecord,
} from "../../../../hooks/useIncrementRecord";
import EditIncrementRecordModal from "../../../../components/modelpopup/EditIncrementRecordModal";
import PermissionWrapper from "../../../../authorization/PermissionWrapper";
import GlobalLoader from "../../../layout/GlobalLoader";

const IncrementRecord = () => {
  const [inputValue, setInputValue] = useState("");
  const [focused, setFocused] = useState(false);
  const [success, setSuccess] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [toastType, setToastType] = useState("");
  const [updatedRecord, setUpdatedRecord] = useState(null);

  const { data, refetch, isLoading, error, status } = useQuery({
    queryKey: ["increment_record"],
    queryFn: fetchIncrementRecord,
    refetchOnWindowFocus: false,
  });

  const { mutate: addIncrementRecordMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addIncrementRecord,
    onSuccess: (response) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));

      setToastType({
        label: response.message || "Increment Added",
        type: "Added",
      });
    },

    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "Error",
      });
    },
  });

  const { mutate: updateIncrementRecordMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateIncrementRecord,
    onSuccess: (response) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: response.message || "Salary Incremented Successfully",
        type: "updated",
      });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: error.response?.data?.message || "an error occurred",
        type: "error",
      });
    },
  });

  const { onMutate: deleteIncrementRecordMutation } = {
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteIncrementRecord,
    onSuccess: (response) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: error.response?.data?.message || "an error occurred",
        type: "error",
      });
    },
  };

  const addIncrementRecordData = (data) => {
    addIncrementRecordMutation(data);
  };
  const updateIncrementRecordData = (data) => {
    updateIncrementRecordMutation(data);
  };
  // const handleEdit = (record) => {
  //   console.log("Editing record:", record);
  //   // Pass the selected record to the EditIncrementRecordModal component
  //   setUpdatedRecord(record);  // You should have a state to store the selected record
  // };

  const deleteIncrementRecordData = (data) => {
    deleteIncrementRecordMutation(data);
  };
  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  if (isLoading || isRefetching) {
    return <GlobalLoader />;
  }

  const columns = [
    {
      title: "Employee Name",
      dataIndex: "employeeName",
      sorter: (a, b) => a.employeeName.localeCompare(b.employeeName),
    },
    {
      title: "Current Salary",
      dataIndex: "currentSalary",
      render: (text) => `$${text.toLocaleString()}`,
      sorter: (a, b) => a.currentSalary - b.currentSalary,
    },
    {
      title: "Incremented Salary",
      dataIndex: "incrementedSalary",
      render: (text) => `$${text.toLocaleString()}`,
      sorter: (a, b) => a.incrementedSalary - b.incrementedSalary,
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Description"
          trigger="hover"
          overlayStyle={{ maxWidth: "400px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
    },
    {
      title: "Updated At",
      dataIndex: "updatedAt",
      render: (text) => new Date(text).toLocaleString(),
      sorter: (a, b) => new Date(a.updatedAt) - new Date(b.updatedAt),
    },
    {
      title: "Action",
      render: (text, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"edit-increment-record"}>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#edit_record"
                onClick={() => setUpdatedRecord(record)}
              >
                <i className="fa fa-pencil m-r-5" /> Edit
              </Link>
            </PermissionWrapper>
            <PermissionWrapper permissionKey={"delete-increment-record"}>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete"
                onClick={() => handleDelete(record)}
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </Link>
            </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  // const handleEdit = (record) => {
  //   console.log("Editing record:", record);
  // };

  const handleDelete = (record) => {
    console.log("Deleting record:", record);
  };

  // Filter data based on the search input value
  const filteredData = data?.data?.employeeSalaryLog?.filter((record) =>
    record.employee.firstname.toLowerCase().includes(inputValue.toLowerCase())
  );

  // Transform data for the table
  const dataSource = filteredData?.map((item, index) => ({
    key: index,
    employeeName: item.employee.firstname, // Employee Name
    currentSalary: item.current_salary,
    incrementedSalary: item.incremented_salary,
    description: item.description,
    updatedAt: item.updated_at,
  }));

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Increment Record"
          title="Dashboard"
          subtitle="Payroll"
          modal="#add_record"
          name="Add Record"
          permissionKey="add-increment-record"
        />
        <div className="row filter-row d-flex justify-content-between">
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-4 col-12">
            <div
              className={
                focused || inputValue !== ""
                  ? "input-block form-focus focused"
                  : "input-block form-focus"
              }
            >
              <input
                type="text"
                className="form-control floating"
                value={inputValue}
                onFocus={() => setFocused(true)}
                onBlur={() => setFocused(false)}
                onChange={(e) => setInputValue(e.target.value)}
              />
              <label className="focus-label" onClick={() => setFocused(true)}>
                Employee Name
              </label>
            </div>
          </div>
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <Link to="#" className="btn btn-success w-100">
              {" "}
              Search{" "}
            </Link>
          </div>
        </div>

        {/* Table Section */}
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <Table
                className="table-striped"
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={dataSource}
                pagination={{ pageSize: 10 }}
                bordered
              />
            </div>
          </div>
        </div>
      </div>
      <AddSalaryModal />
      <AddIncrementRecordModal onSubmit={addIncrementRecordData} />
      <EditIncrementRecordModal
        onSubmit={updateIncrementRecordData}
        updatedRecord={updatedRecord}
      />

      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </div>
  );
};

export default IncrementRecord;
