import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchAllEmployeesQuery = async () => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.get(`employee-query`);
    return data.data; // Access the employee_queries array
};


export const addEmployeeQuery = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'));
    try {
        const { data } = await axiosInstance.post('employee-query', payload, {
          headers: {
            // Don't manually set Content-Type; let Axios handle this for FormData
            'Content-Type': 'multipart/form-data',
          },
        });
    
        return data?.data; // Return the response for further handling
      } catch (error) {
        console.error("Error uploading policy:", error);
        throw error; // Propagate the error
      }
  };
  

export const deleteEmployeeQuery = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`employee-query?id=${id}`)
    return data?.data;
}

export const updateEmployeeQuery = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`employee-query`,res)
    return data?.data;
}
export const updateEmployeeQueryStatus = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`employee-query?id=${res.id}`,res)
    return data?.data;
}

