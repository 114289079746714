import React, { useState, useEffect } from "react";
import SearchBox from "../../../../components/SearchBox";
import { Table } from "antd";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import LeaveTypeModal from "../../../../components/modelpopup/LeaveTypeModal";
import InfoToast from "../../../layout/InfoToast";

import {
  addLeaveType,
  deleteLeaveType,
  fetchLeaveTypes,
  updateLeaveType,
} from "../../../../hooks/useLeaveType";
import { useMutation, useQuery } from "@tanstack/react-query";
import GlobalLoader from "../../../layout/GlobalLoader";

const LeaveType = () => {
  const [selectedType, setSelectedType] = useState({});
  const [isRefetching, setIsRefetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
   const [pagination, setPagination] = useState({
      current: 1,
      pageSize: 10,
    });

  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const { data, error, status, refetch } = useQuery({
    queryKey: ["allLeaveTypes"],
    queryFn: fetchLeaveTypes,
    refetchOnWindowFocus: false,
  });
  const { mutate: addLeaveMutation } = useMutation({
    mutationFn: addLeaveType,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Leave Added", type: "Added" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Adding Leave",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });
  const { mutate: updateLeaveMutation } = useMutation({
    mutationFn: updateLeaveType,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Leave Updated", type: "Updated" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Updating Leave",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });
  const { mutate: deleteLeaveMutation } = useMutation({
    mutationFn: deleteLeaveType,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
      setTimeout(() => {
        setSuccess(true);
        setToastType({ label: "Leave Deleted", type: "Deleted" });
      }, 300);
    },
    onError: (error) => {
      setTimeout(() => {
        setToastType({
          label: "An Error Occurred While Deleting Leave",
          type: "Error",
        });
        setSuccess(true);
      }, 300);
    },
  });
  const addLeaveTypeData = (data) => {
    addLeaveMutation(data);
  };
  const updateLeaveTypeData = (data) => {
    updateLeaveMutation(data);
  };
  const deleteLeaveTypeData = (data) => {
    deleteLeaveMutation(data);
  };
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };
  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Leave Type",
      dataIndex: "type",
      render: (text) => (
        <div className="ant-table-row ant-table-row-level-0 ">
          <span>{text}</span>
        </div>
      ),
      sorter: (a, b) => b.type.length - a.type.length,
    },
    {
      title: "Leave Days",
      dataIndex: "days",
      render: (text) => (
        <div className="ant-table-row ant-table-row-level-0">
          <span>{text}</span>
        </div>
      ),
      sorter: (a, b) => b.days.length - a.days.length,
    },
    {
      title: "Status",
      dataIndex: "status",
      sorter: (a, b) => b.is_active.length - a.is_active.length,
      render: (_, record) => (
        <div className="ant-table-row ant-table-row-level-0 ">
          
            {record.is_active != 0 ? (
              <>
                <i className="far fa-dot-circle text-success" /> Active
              </>
            ) : (
              <>
                <i className="far fa-dot-circle text-danger" /> Inactive
              </>
            )}
          
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              to="#"
              className="dropdown-item"
              // onClick={() => updateLeaveTypeData()}
            >
              <i className="far fa-dot-circle text-success" /> Active
            </Link>
            <Link
              to="#"
              className="dropdown-item"
              // onClick={() => updateLeaveTypeData()}
            >
              <i className="far fa-dot-circle text-danger" /> Inactive
            </Link>
          </div>
        </div>
      ),
    },
    {
      title: "Action",
      sorter: (a, b) => b.id.length - a.id.length, // Reverse sorting for actions as well
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_leavetype"
              onClick={() => {
                setSelectedType(record);
                // setEditable(true);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedType(record);
                // setEditable(true);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
    },
  ];
  if (status === "pending" || isRefetching) {
    return <GlobalLoader />;
  }

  // if (status === "error") {
  //   return (
  //     <div className="page-wrapper">
  //       <span className="content container-fluid">Error: {error.message}</span>
  //     </div>
  //   );
  // }
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div
            onClick={() => {
              setSelectedType({});
              // setEditable(false);
            }}
          >
            <Breadcrumbs
              maintitle="Leave Type"
              title="Dashboard"
              subtitle="Leave Type"
              modal="#add_leavetype"
              name="Add Leave Type"
            />
          </div>
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={data}
                  rowKey={(record) => record.id}
                  pagination={{ current: currentPage }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <LeaveTypeModal
          selectedType={selectedType}
          addLeaveTypeData={addLeaveTypeData}
          updateLeaveTypeData={updateLeaveTypeData}
        />
        <DeleteModal
          deleteDepartment={deleteLeaveTypeData}
          Name="Delete Leave Type"
          id={selectedType?.id}
        />
      </div>
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default LeaveType;
