import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { fetchDepartments } from "../../hooks/useDepartment";

const EditPolicyModal = ({ editable, policyData, updatePolicyData, closeModal }) => {
  const [formState, setFormState] = useState({
    name: "",
    department_id: "",
    description: "",
    file: null,
  });

  const { data, error, isLoading } = useQuery({
    queryKey: ["departments"],
    queryFn: fetchDepartments,
    refetchOnWindowFocus: false,
  });

  const departmentOptions = data?.map((department) => ({
    value: department.id,
    label: department.department,
  })) || [];

  useEffect(() => {
    if (editable && policyData) {
      setFormState({
        name: policyData.name || "",
        department_id: policyData.department_id || "",
        description: policyData.description || "",
        file: null,
      });
    }
  }, [editable, policyData]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFileChange = (e) => {
    setFormState((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  const handleDepartmentChange = (selectedOption) => {
    setFormState((prevState) => ({
      ...prevState,
      department_id: selectedOption?.value || "",
    }));
  };

  const handleSubmit = () => {
    const formData = new FormData();
    formData.append("id", policyData.id); 
    formData.append("name", formState.name);
    formData.append("department_id", formState.department_id);
    formData.append("description", formState.description);

    if (formState.file) {
      formData.append("file", formState.file);
    }

    updatePolicyData({ ...policyData, ...formState });
    closeModal();
  };

  return (
    <div id="edit_policy" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Policy</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={closeModal}
            >
              <span aria-hidden="true">x</span>
            </button>
          </div>
          <div className="modal-body">
            <form>
              <div className="mb-3">
                <label htmlFor="policyname" className="form-label">
                  Policy Name <span className="text-danger">*</span>
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="policyname"
                  name="name"
                  value={formState.name}
                  onChange={handleInputChange}
                  placeholder="Enter policy name"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="description" className="form-label">
                  Description <span className="text-danger">*</span>
                </label>
                <textarea
                  className="form-control"
                  id="description"
                  name="description"
                  value={formState.description}
                  onChange={handleInputChange}
                  rows="4"
                  placeholder="Enter policy description"
                />
              </div>
              <div className="mb-3">
                <label className="form-label">Departments</label>
                <Select
                  placeholder="Select Department"
                  options={departmentOptions}
                  value={departmentOptions.find(
                    (option) => option.value === formState.department_id
                  ) || null}
                  onChange={handleDepartmentChange}
                  className="select"
                />
              </div>
              <div className="mb-3">
                <label htmlFor="policy_upload" className="form-label">
                  Upload Policy <span className="text-danger">*</span>
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="policy_upload"
                  onChange={handleFileChange}
                />
                {editable && policyData?.file && (
                  <div className="mt-2">
                    <a
                      href={policyData.file}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View Current File
                    </a>
                  </div>
                )}
              </div>
            </form>
          </div>
          <div className="submit-section" style={{ margin: "0px 0px 25px 0px" }}>
            <button
              type="button"
              className="btn btn-primary submit-btn"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={handleSubmit}
            >
              Update Policy
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditPolicyModal;
