import { Table, Input, Button } from "antd";
import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import EditUserModal from "../../../../components/Administration/Users/EditUseModal";
import { formatDate } from "../../../../assets/scss/utils/helpers/helper_functions";
import dayjs from "dayjs";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { useForm } from "react-hook-form";
import PermissionWrapper from "../../../../authorization/PermissionWrapper";

const UsersTable = ({ data, onSelectUserForDeletion, updateUser, roleData }) => {
  const { control } = useForm(); // Initialize useForm
  const [selectedId, setSelectedId] = useState(null);
  const [employeeCompany, setEmployeeCompany] = useState("XpertPrime");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [selectedUserData, setSelectedUserData] = useState({});
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState(data || []); 

  useEffect(() => {
    setFilteredData(data);
  }, [data]); // Re-run effect whenever `data` changes

  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  const handleDeleteClick = (id) => {
    onSelectUserForDeletion(id);
  };

  const formatDateTime = (date) => {
    return dayjs(date).format("DD-MM-YYYY h:mm A");
  };

  const handleSearch = (value) => {
    setSearchText(value); 
  };

  const handleSearchButtonClick = () => {
   
    const filtered = (data || []).filter((user) => {
      const name = user.name ? user.name.toLowerCase() : "";
      const email = user.email ? user.email.toLowerCase() : "";
      const company = user.company ? user.company.toLowerCase() : "";
      const role = user.role ? user.role.toLowerCase() : "";

      return (
        name.includes(searchText.toLowerCase()) ||
        email.includes(searchText.toLowerCase()) ||
        company.includes(searchText.toLowerCase()) ||
        role.includes(searchText.toLowerCase())
      );
    });
    setFilteredData(filtered); 
  };

  const handleResetButtonClick = () => {
    setSearchText("");
    setFilteredData(data); 
  };

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Name",
      dataIndex: "name",
      render: (text, record) => (
        <div className="table-avatar">
          <img alt="" src={record?.avatar} />
          {text}{" "}
          <span>
            {record?.firstname} {record?.lastname || ""}
          </span>
        </div>
      ),
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a.email.length - b.email.length,
    },
    {
      title: "Company",
      dataIndex: "company",
      render: (text, record) => record?.company || "Xpert Prime",
    },
    {
      title: "Created Date",
      dataIndex: "created_at",
      render: (text) => formatDate(text),
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      defaultSortOrder: "ascend",
    },
    {
      title: "Last Activity",
      dataIndex: "last_activity",
      render: (text, record) =>
        record?.last_activity == null
          ? "No Recent Activity"
          : formatDateTime(record?.last_activity),
      sorter: (a, b) => new Date(a.last_activity) - new Date(b.last_activity),
    },
    {
      title: "Role",
      dataIndex: "role",
      render: (text) => (
        <span
          className={
            text === "Admin"
              ? "badge bg-inverse-danger"
              : "badge bg-inverse-success"
          }
        >
          {text}
        </span>
      ),
      sorter: (a, b) => a.role.length - b.role.length,
    },
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"edit-user"}>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_user"
              onClick={() => {
                setSelectedId(record.id);
                setSelectedUserData(record);
              }}
              >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
              </PermissionWrapper>
              <PermissionWrapper permissionKey={"delete-user"}>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => handleDeleteClick(record.id)}
              >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
              </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="row filter-row space mb-4">
          <div className="col-sm-6 col-md-3">
            <div className="input-block">
              <input
                type="text"
                className="form-control p-4"
                value={searchText}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="User Email"
              />
            </div>
          </div>

          {/* Search Button */}
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <button
              className="btn btn-success btn-block w-100"
              onClick={handleSearchButtonClick}
            >
              Search
            </button>
          </div>

          {/* Reset Button */}
          <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
            <button
              className="linkStyle mt-2" style={{borderStyle:"none"}}
              onClick={handleResetButtonClick}
            >
              <MdOutlineSettingsBackupRestore className="iconStyle" />
            </button>
          </div>
        </div>

        <div className="table-responsive">
          <Table
            className="table-striped"
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={filteredData} 
            rowKey={(record) => record.id}
            pagination={pagination}
            onChange={handleTableChange}
          />
          <EditUserModal
            selectedId={selectedId}
            updateUser={updateUser}
            selectedUserData={selectedUserData}
            roleData={roleData}
            control={control}
            data={data}
          />
        </div>
      </div>
    </div>
  );
};

export default UsersTable;
