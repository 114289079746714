import React, { useState, useEffect } from "react";
import Select from "react-select";

const EditAssetpopup = ({ employees, selectedAsset, onSubmit }) => {
  const [formData, setFormData] = useState({
    id: "",
    serial_number: "",
    name: "",
    purchase_date: "",
    purchase_from: "",
    manufacturer: "",
    model: "",
    supplier: "",
    condition: "New",
    warranty: "",
    value: "",
    description: "",
    employee_id: "",
    status: "Pending",
    asset_images: "",
  });

  const [uploadedImages, setUploadedImages] = useState([]);
  const [conditionStatus, setConditionStatus] = useState({
    value: "",
    label: "",
  });

  useEffect(() => {
    setConditionStatus(
      conditionOptions.filter((item) => {
        return item.label === formData.condition;
      })
    );
  }, [formData.status]);

  const employeeOptions = employees.map((emp) => ({
    value: emp.id,
    label: `${emp.firstname || ""} ${emp.lastname || ""}`,
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const isFormValid = () => {
    return (
      formData.serial_number &&
      formData.name &&
      formData.purchase_date &&
      formData.purchase_from &&
      formData.manufacturer &&
      formData.model &&
      formData.supplier &&
      formData.value &&
      formData.condition &&
      formData.warranty &&
      formData.description &&
      formData.status
    );
  };

  const statusOptions = [
    { value: "Pending", label: "Pending" },
    { value: "Approved", label: "Approved" },
    { value: "Damaged", label: "Damaged" },
  ];

  const conditionOptions = [
    { value: "New", label: "New" },
    { value: "Used", label: "Used" },
  ];

  useEffect(() => {
    // debugger;
    if (selectedAsset) {
      setFormData({
        id: selectedAsset.id || "",
        serial_number: selectedAsset.serial_number || "",
        name: selectedAsset.name || "",
        purchase_date: selectedAsset.purchase_date || "",
        purchase_from: selectedAsset.purchase_from || "",
        manufacturer: selectedAsset.manufacturer || "",
        model: selectedAsset.model || "",
        supplier: selectedAsset.supplier || "",
        condition: selectedAsset.condition || "New",
        warranty: selectedAsset.warranty || "",
        value: selectedAsset.value || "",
        description: selectedAsset.description || "",
        employee_id: selectedAsset.employee_id || "",
        status: selectedAsset.status || "Pending",
        asset_images: selectedAsset.asset_images || "",
      });
      // Check if asset_images exist and set the uploaded images
      if (selectedAsset.asset_images && selectedAsset.asset_images.length > 0) {
        setUploadedImages(selectedAsset.asset_images);
      } else {
        setUploadedImages([]); // Clear images if there are none
      }
    }
  }, [selectedAsset]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({ ...prev, [name]: value }));
  };

  const handleSelectChange = (selectedOption, actionMeta) => {
    setFormData((prev) => ({
      ...prev,
      [actionMeta.name]: selectedOption ? selectedOption.value : null,
    }));
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedImages(files);
  };
  const removeImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    const payload = {
      ...formData,
      images: uploadedImages,
    };

    onSubmit(payload);
  };

  return (
    <div id="edit_asset" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-md asset-container"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Asset</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleFormSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-form-label">Asset Serial #</label>
                  <input
                    className="form-control"
                    type="text"
                    name="serial_number"
                    value={formData.serial_number}
                    onChange={handleInputChange}
                    required
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-label">Asset Name</label>
                  <input
                    className="form-control"
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-form-label">Purchase Date</label>
                  <input
                    className="form-control"
                    type="date"
                    name="purchase_date"
                    value={formData.purchase_date}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-label">Purchase From</label>
                  <input
                    className="form-control"
                    type="text"
                    name="purchase_from"
                    value={formData.purchase_from}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-form-label">Manufacturer</label>
                  <input
                    className="form-control"
                    type="text"
                    name="manufacturer"
                    value={formData.manufacturer}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-label">Model</label>
                  <input
                    className="form-control"
                    type="text"
                    name="model"
                    value={formData.model}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 ">
                  <label className="col-form-label">Supplier</label>
                  <input
                    className="form-control"
                    type="text"
                    name="supplier"
                    value={formData.supplier}
                    onChange={handleInputChange}
                  />
                </div>
                <div className="col-md-6 ">
                  <label className="col-form-label">Value ($)</label>
                  <input
                    className="form-control"
                    type="number"
                    name="value"
                    value={formData.value}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6">
                  <label className="col-form-label">Condition</label>
                  <Select
                    name="condition"
                    options={conditionOptions}
                    value={
                      conditionOptions.find(
                        (option) => option.value === formData.condition
                      ) || null
                    }
                    onChange={handleSelectChange}
                    className="select floating"
                    styles={customStyles}
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-label">Warranty (in months)</label>
                  <input
                    className="form-control"
                    type="number"
                    name="warranty"
                    value={formData.warranty}
                    onChange={handleInputChange}
                  />
                </div>
              </div>

              <div className="row ">
                <div className="col-md-12">
                  <label className="col-form-label">Description</label>
                  <textarea
                    className="form-control"
                    name="description"
                    rows="3"
                    value={formData.description}
                    onChange={handleInputChange}
                  ></textarea>
                </div>
              </div>

              <div className="row">
                <div className="col-md-6">
                  <label className="col-form-label">Assign to Employee</label>
                  <Select
                    name="employee_id"
                    options={employeeOptions}
                    value={
                      employeeOptions.find(
                        (option) => option.value === formData.employee_id
                      ) || null
                    }
                    onChange={handleSelectChange}
                    className="select floating"
                    styles={customStyles}
                  />
                </div>
                <div className="col-md-6">
                  <label className="col-form-label">Upload Images</label>
                  <input
                    className="form-control"
                    type="file"
                    multiple
                    onChange={handleImageUpload}
                    // value={formData.asset_images}
                  />
                </div>

                <div className="uploaded-images d-flex justify-content-end mt-2">
                  {uploadedImages.length > 0 &&
                    uploadedImages.map((images, index) => {
                      const imageSrc =
                        images instanceof File
                          ? URL.createObjectURL(images)
                          : images;

                      return (
                        <div key={index} className="image-preview">
                          <img
                            src={imageSrc.images}
                            alt={`Uploaded ${index}`}
                            className="img-thumbnail"
                            style={{ width: "50px", height: "50px" }}
                          />
                          <button
                            type="button"
                            onClick={() => removeImage(index)}
                            className="btn btn-danger btn-sm"
                            style={{
                              position: "relative",
                              bottom: "15px",
                              right: "5px",
                              borderRadius: "50%",
                              padding: "0px",
                              width: "20px",
                              height: "20px",
                            }}
                          >
                            X
                          </button>
                        </div>
                      );
                    })}
                </div>

                {/* <div className="col-md-6">
                  <label className="col-form-label">Status</label>
                  <Select
                    name="status"
                    options={statusOptions}
                    onChange={handleSelectChange}
                    className="select floating"
                    styles={customStyles}
                  />
                </div> */}
              </div>

              <div className="row mt-3"></div>

              <div className="submit-section mt-4">
                <button
                  className="btn btn-primary submit-btn"
                  type="submit"
                  data-bs-dismiss="modal"
                  disabled={!isFormValid()}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditAssetpopup;
