import React from "react";

const Reports = () => {
  // Static data instead of fetching from the API
  const users = [
    {
      title: "New Employees",
      value: "10",
      changePercentage: "+10%",
      changeDescription: "Over All Employees",
      previousValue: "72",
    },
    {
      title: "New Users",
      value: "5",
      changePercentage: "-5%",
      changeDescription: "Total Users",
      previousValue: "130",
    },
    {
      title: "Today Leaves",
      value: "4",
      changePercentage: "+8%",
      changeDescription: "Total Leaves this Month",
      previousValue: "40",
    },
    {
      title: "Pending Employee Queries",
      value: "6",
      changePercentage: "-5%",
      changeDescription: "Total Employee Queries this Month",
      previousValue: "23",
    },
  ];

  return (
    <div>
      <div className="row">
        <div className="col-md-12">
          <div className="card-group m-b-30">
            {Array.isArray(users) && users.length > 0 ? (
              users.map((item, index) => (
                <div className="card" key={index}>
                  <div className="card-body">
                    <div className="d-flex justify-content-between mb-3">
                      <div>
                        <span className="d-block">{item.title}</span>
                      </div>
                      <div>
                        {/* <span
                          className={`text-${
                            item.changePercentage.includes("+")
                              ? "success"
                              : "danger"
                          }`}
                        >
                          {item.changePercentage}
                        </span> */}
                      </div>
                    </div>
                    <h3 className="mb-3">{item.value}</h3>
                    <div className="progress mb-2" style={{ height: "5px" }}>
                      <div
                        className="progress-bar bg-primary"
                        role="progressbar"
                        style={{ width: "70%" }} // This can be dynamic, or fixed as needed
                        aria-valuenow={70} // You can adjust this value
                        aria-valuemin={0}
                        aria-valuemax={100}
                      />
                    </div>
                    <p className="mb-0">
                      {item.changeDescription}
                      <span className="text-muted"> {item.previousValue}</span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <p>No data available</p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reports;
