import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";
import RolesPermissionsModal from "../../../../components/modelpopup/RolesPermissionsModal";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { useMutation, useQuery } from "@tanstack/react-query";
import { addRole, deleteRole, fetchPermissionsAgainstRoles, fetchRoles, updatePermissions, updateRole } from "../../../../hooks/useRolePermissions";
import InfoToast from "../../../layout/InfoToast";
import GlobalLoader from "../../../layout/GlobalLoader";

const RolesPermissions = () => {
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [roleID, setRoleID] = useState("");
  const [ID, setID] = useState("");
  const [checkedPermissions, setCheckedPermissions] = useState({});
  const [activeIndex, setActiveIndex] = useState(0);

  const handleClick = (index, role) => {
    setActiveIndex(index);
    setID(role?.id);
  };

  // Handle checkbox state change
  const handleCheckboxChange = (section, permission) => {
    setCheckedPermissions(prevState => ({
      ...prevState,
      [`${section}.${permission}`]: !prevState[`${section}.${permission}`]
    }));
  };

  const getCheckedPermissionsArray = () => {
    return Object.entries(checkedPermissions)
      .filter(([_, isChecked]) => isChecked)
      .map(([key]) => key.split('.')[1]);
  };

  const logCheckedPermissions = () => {
    const checkedPermissionsArray = getCheckedPermissionsArray();
    updatePermissionsMutation({
      id: ID,
      permissions: checkedPermissionsArray,
    });
  };

  // Fetch permissions against roles
  const { data: permissionsData = {}, refetch: permissionsRefetch, isLoading: permissionsDataStatus } = useQuery({
    queryKey: ["fetchPermissionsAgainstRoles", ID],
    queryFn: () => fetchPermissionsAgainstRoles(ID),
    enabled: !!ID,
    refetchOnWindowFocus: false,
  });

  // Update checkedPermissions when permissionsData changes
  useEffect(() => {
    if (permissionsData.permissions) {
      const initialPermissions = {};
      Object.entries(permissionsData.permissions).forEach(([section, permissions]) => {
        Object.entries(permissions).forEach(([permission, isChecked]) => {
          initialPermissions[`${section}.${permission}`] = isChecked;
        });
      });
      setCheckedPermissions(initialPermissions);
    }
  }, [permissionsData]);

  // Update Permissions
  const { mutate: updatePermissionsMutation, isLoading: loadingPermission } = useMutation({
    mutationFn: updatePermissions,
    onSuccess: () => {
      refetch().then(() => setSuccess(true));
      setToastType({ label: "Permissions Updated", type: "Updated" });
    },
    onError: () => {
      setToastType({ label: "Error Updating Permissions", type: "Error" });
    },
  });

  // Fetch Roles
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["roles"],
    queryFn: fetchRoles,
    refetchOnWindowFocus: false,
  });

  // Add Role
  const { mutate: addRoleMutation, isLoading: loadingRoles } = useMutation({
    mutationFn: addRole,
    onSuccess: () => {
      refetch().then(() => setSuccess(true));
      setToastType({ label: "User Role Added", type: "Added" });
    },
    onError: () => {
      setToastType({ label: "Error Adding User Role", type: "Error" });
    },
  });

  // Update Role
  const { mutate: updateRoleMutation, isLoading: updateStatus } = useMutation({
    mutationFn: updateRole,
    onSuccess: () => {
      refetch().then(() => setSuccess(true));
      setToastType({ label: "User Role Updated", type: "Updated" });
    },
    onError: () => {
      setToastType({ label: "Error Updating User Role", type: "Error" });
    },
  });

  // Delete Role
  const { mutate: delRoleMutation, isLoading: deleteRoleStatus } = useMutation({
    mutationFn: deleteRole,
    onSuccess: () => {
      refetch().then(() => setSuccess(true));
      setToastType({ label: "User Role Deleted", type: "Deleted" });
    },
    onError: () => {
      setToastType({ label: "Error Deleting User Role", type: "Error" });
    },
  });

  useEffect(() => {
    if (success) {
      const timer = setTimeout(() => setSuccess(false), 3000);
      return () => clearTimeout(timer);
    }
  }, [success]);
  if (isLoading || loadingPermission || updateStatus || deleteRoleStatus || loadingRoles || permissionsDataStatus) {
    return <GlobalLoader />
  }
  return (
      <div className="page-wrapper">
        <div className="content container-fluid">
          <h1 style={{ marginBottom: "1.8rem" }}>Roles & Permissions</h1>
          <div className="row">
            <div className="col-sm-4 col-md-4 col-lg-4 col-xl-3">
              <Link
                to="#"
                className="btn btn-primary btn-block w-100"
                data-bs-toggle="modal"
                data-bs-target="#add_role"
              >
                <i className="fa fa-plus" /> Add Roles
              </Link>
              <div className="roles-menu">
                <ul>
                  {data?.map((item, index) => (
                    <li key={index} className={index === activeIndex ? 'active' : ''} onClick={() => handleClick(index, item)}>
                      <Link to="#" >
                        {item.name}
                        <span className="role-action">
                          <span
                            className="action-circle large me-1"
                            data-bs-toggle="modal"
                            data-bs-target="#edit_role"
                            onClick={() => {
                              setRoleID(item)
                            }}
                          >
                            <i className="material-icons">edit</i>
                          </span>
                          <span
                            className="action-circle large delete-btn"
                            data-bs-toggle="modal"
                            data-bs-target="#delete"
                            onClick={() => setRoleID(item)}
                          >
                            <i className="material-icons">delete</i>
                          </span>
                        </span>
                      </Link>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8 col-xl-9">
              <div className="table-responsive">
                <table className="table table-striped custom-table">
                  <thead>
                    <tr>
                      <th>Module Permission</th>
                      <th className="text-center">Create</th>
                      <th className="text-center">Write</th>
                      <th className="text-center">Read</th>
                      <th className="text-center">Delete</th>
                    </tr>
                  </thead>
                  <tbody>
                    {permissionsData.permissions && Object.entries(permissionsData.permissions).map(([section, permissions]) => (
                      <tr key={section}>
                        <td>{section}</td>
                        {Object.entries(permissions).map(([permission, isChecked]) => (
                          <td key={permission} className="text-center">
                            <label className="custom_check">
                              <input
                                type="checkbox"
                                checked={checkedPermissions[`${section}.${permission}`] || false}
                                onChange={() => handleCheckboxChange(section, permission)}
                              />
                              <span className="checkmark" />
                            </label>
                          </td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
                <button
                  className="btn btn-primary btn-block w-100"
                  onClick={logCheckedPermissions}
                >
                  <i className="fa fa-plus" /> Update Permissions
                </button>
              </div>
            </div>
          </div>
        </div>
        {success && (
          <InfoToast success={success} toastType={toastType} />
        )}
        <RolesPermissionsModal addRole={addRoleMutation} updateRole={updateRoleMutation} selectedRole={roleID} />
        <DeleteModal Name="Role" deleteDepartment={() => delRoleMutation(roleID.id)} />
      </div>
  );
};

export default RolesPermissions;
