import React, { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import { Table } from "antd";
import { Link } from "react-router-dom";
import DeleteModal from "../../../../components/modelpopup/deletePopup";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import {
  deleteAssets,
  updateAssets,
  updateAssetsStatus,
} from "../../../../hooks/useEmployeeAssets";
import EditAssetpopup from "../../../../components/modelpopup/Assets/EditAssetpopup";
import GlobalLoader from "../../../layout/GlobalLoader";
import InfoToast from "../../../layout/InfoToast";
import PermissionWrapper from "../../../../authorization/PermissionWrapper";

const AssetsTable = ({ data }) => {
  const [refetch, setRefetch] = useState("");
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [assetName, setAssetName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [filteredData, setFilteredData] = useState(data?.assets || []); 

  const handleSearch = () => {
    const filtered = data.assets.filter(
      (item) =>
        item.name.toLowerCase().includes(assetName.toLowerCase()) &&
        item.serial_number.toLowerCase().includes(serialNumber.toLowerCase())
    );
    setFilteredData(filtered);
  };

  useEffect(() => {
    setFilteredData(data.assets); 
  }, [data]);

  
  useEffect(() => {
    if (success) {
      const timeoutId = setTimeout(() => {
        setSuccess(false);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [success]);

  const handleResetButtonClick = () => {
    setAssetName(""); 
    setSerialNumber(""); 
    setFilteredData(data.assets); 
  };

  const { mutate: deleteAssetMutation } = useMutation({
    mutationFn: deleteAssets,
    onMutate: () => setIsRefetching(true),
    onSuccess: (response) => {
      setFilteredData(
        (prevData) => prevData.filter((asset) => asset.id !== selectedAssetId) 
      );
      setIsRefetching(false);
      setSuccess(true);
      setToastType({
        label: response.message || "Asset Deleted",
        type: "success",
      });
    },
    onError: (error) => {
      setIsRefetching(false);
      setToastType({
        label:
          error.response?.data?.message ||
          "An Error Occurred While Deleting Asset",
        type: "error",
      });
    },
  });

  const { mutate: updateAssetMutation } = useMutation({
    mutationFn: updateAssets,
    onMutate: () => setIsRefetching(true),
    onSuccess: (response) => {
      const updatedAsset = response.data; // This should be the updated asset
      
      // Update the filteredData state with the updated asset
      setFilteredData((prevData) => 
        prevData.map((asset) => 
          asset.id === updatedAsset.id ? { ...asset, ...updatedAsset } : asset
        )
      );
      
      // Reset loading state and show success toast
      setIsRefetching(false);
      setSuccess(true);
      setToastType({ label: "Asset Updated Successfully", type: "success" });
    },
    onError: (error) => {
      setIsRefetching(false);
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "error",
      });
    },
  });
  
  
  

  const { mutate: updateAssetsStatusMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateAssetsStatus,
    onSuccess: (updatedStatusAsset) => {
      setFilteredData((prevData) =>
        prevData.map((asset) =>
          asset.id === updatedStatusAsset.id
            ? { ...asset, ...updatedStatusAsset }
            : asset
        )
      );
      setIsRefetching(false);
      setSuccess(true);
      setToastType({ label: "Asset Status Updated", type: "success" });
    },
    onError: (error) => {
      setIsRefetching(false);
      setToastType({
        label: "An error occurred while updating asset status",
        type: "error",
      });
    },
  });

  const deleteAssetData = (id) => {
    deleteAssetMutation(id);
  };

  const updateAssetData = (asset) => {
    updateAssetMutation(asset);
  };

  const updateAssetsStatusData = (item, status) => {
    const data = {
      id: item.id, 
      employee_id: item.employee_id, 
      status: status, 
      name: item.name,
      manufacturer: item.manufacturer,
      model: item.model,
      serial_number: item.serial_number,
      supplier: item.supplier,
      condition: item.condition,
      warranty: item.warranty,
      purchase_date: item.purchase_date,
      purchase_from: item.purchase_from,
      value: item.value,
      title: item.title, 
      description: item.description, 
    };

    // console.log("Sending data to update query status:", data);
    updateAssetsStatusMutation(data); 
  };
  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  if (isRefetching) {
    return <GlobalLoader />;
  }

  const columns = [
    {
      title: "Asset Serial #",
      dataIndex: "serial_number",
      sorter: (a, b) => a.serial_number.length - b.serial_number.length,
    },
    {
      title: "Asset Name",
      dataIndex: "name",
      render: (text) => <strong>{text}</strong>,
      sorter: (a, b) => a.name.length - b.name.length,
    },
    {
      title: "Supplier",
      dataIndex: "supplier",
      sorter: (a, b) => a.supplier.length - b.supplier.length,
    },
    {
      title: "Purchase Date",
      dataIndex: "purchase_date",
      sorter: (a, b) => new Date(a.purchase_date) - new Date(b.purchase_date),
    },
    {
      title: "Warranty",
      dataIndex: "warranty",
      sorter: (a, b) => a.warranty - b.warranty,
    },
    {
      title: "Amount",
      dataIndex: "value",
      render: (text) => <span>$ {text}</span>,
      sorter: (a, b) => parseFloat(a.value) - parseFloat(b.value),
    },
    {
      title: "Status",
      dataIndex: "status",
      render: (text, record) => (
        <div className="dropdown action-label text-center">
          <PermissionWrapper permissionKey={"edit-assets"}>
          <Link
            className="btn btn-white btn-sm btn-rounded dropdown-toggle"
            to="#"
            data-bs-toggle="dropdown"
            aria-expanded="false"
            >
            <i
              className={
                text === "Pending"
                  ? "far fa-dot-circle text-info"
                  : text === "Approved"
                  ? "far fa-dot-circle text-danger"
                  : "far fa-dot-circle text-success"
              }
            />{" "}
            {text}
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateAssetsStatusData(record, "pending")}
              >
              <i className="far fa-dot-circle text-info" /> Pending
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateAssetsStatusData(record, "damaged")}
              >
              <i className="far fa-dot-circle text-danger" /> Damaged
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              onClick={() => updateAssetsStatusData(record, "approved")}
              >
              <i className="far fa-dot-circle text-success" /> Approved
            </Link>
          </div>
              </PermissionWrapper>
        </div>
      ),
      sorter: (a, b) => a.status.length - b.status.length,
    },
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"edit-assets"}> 

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_asset"
              onClick={() => setSelectedAsset(record)} 
              >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
              </PermissionWrapper>
              <PermissionWrapper permissionKey={"delete-assets"}>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => setSelectedAssetId(record.id)} 
              >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
              </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  return (
    <div>
      {/* Search Filters */}
      <div className="row filter-row space mb-4">
        {/* Asset Name Filter */}
        <div className="col-sm-6 col-md-3">
          <div className="input-block ">
            <input
              type="text"
              className="form-control p-4 "
              value={assetName}
              onChange={(e) => setAssetName(e.target.value)}
              placeholder="Asset Name"
            />
          </div>
        </div>

        {/* Serial Number Filter */}
        <div className="col-sm-6 col-md-3">
          <div className="input-block ">
            <input
              type="text"
              className="form-control p-4"
              value={serialNumber}
              onChange={(e) => setSerialNumber(e.target.value)}
              placeholder="Serial #"
            />
          </div>
        </div>

        {/* Search Button */}
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <button
            className="btn btn-success btn-block w-100"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

        {/* Reset Button */}
        <div className="col-sm-6 col-md-3 col-lg-3 col-xl-2 col-12">
          <button
            className="linkStyle mt-1"
            style={{ borderStyle: "none" }}
            onClick={handleResetButtonClick}
          >
            <MdOutlineSettingsBackupRestore className="iconStyle" />
          </button>
        </div>
      </div>

      {/* Assets Table */}
      <div className="row">
        <div className="col-md-12">
          <div className="table-responsive">
            <Table
              className="table-striped"
              style={{ overflowX: "auto" }}
              columns={columns}
              dataSource={filteredData}
              rowKey={(record) => record.id}
            />
            <DeleteModal
              Name="Delete Asset"
              deleteAsset={deleteAssetData}
              selectedAssetId={selectedAssetId}
            />
            <EditAssetpopup
              employees={data.employees || []}
              selectedAsset={selectedAsset} // Pass selected asset
              onSubmit={updateAssetData}
            />
            {success && (
              <InfoToast
                success={success}
                handleClose={handleSolidPrimaryToastClose}
                toastType={toastType}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssetsTable;
