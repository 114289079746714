import React, { useState, useEffect } from "react";
import InfoToast from "../../../layout/InfoToast";
import "react-datepicker/dist/react-datepicker.css";
import { useMutation, useQuery } from "@tanstack/react-query";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import AssetsSearchFilter from "./AssetsSearchFilter";
import AssetsTable from "./AssetsTable";
import AddAssetpopup from "../../../../components/modelpopup/Assets/AddAssetpopup";
import EditAssetpopup from "../../../../components/modelpopup/Assets/EditAssetpopup";
import DeleteModal from "../../../../components/modelpopup/deletePopup";
import {
  addAssets,
  deleteAssets,
  fetchAllAssets,
  updateAssets,
} from "../../../../hooks/useEmployeeAssets";
import GlobalLoader from "../../../layout/GlobalLoader";

const Assets = () => {
  const [selectedAssetId, setSelectedAssetId] = useState(null);
  const [employees, setEmployees] = useState([]);
  const [isRefetching, setIsRefetching] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [selectedAsset, setSelectedAsset] = useState(null);

  const { data, error, status, refetch, isLoading } = useQuery({
    queryKey: ["employee_assets"],
    queryFn: fetchAllAssets,
    refetchOnWindowFocus: false,
  });

  const { mutate: addAssetMutation } = useMutation({
    mutationFn: addAssets,
    onMutate: () => setIsRefetching(true),
    onSuccess: (response) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: response.message || "Asset Added Successfully",
        type: "success",
      });
    },
    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "error",
      });
    },
  });

  const { mutate: updateAssetMutation } = useMutation({
    mutationFn: updateAssets,
    onMutate: () => setIsRefetching(true),
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Asset Updated Successfully", type: "success" });
    },
    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "error",
      });
    },
  });

  const { mutate: deleteAssetMutation } = useMutation({
    mutationFn: deleteAssets,
    onMutate: () => setIsRefetching(true),
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Asset Deleted Successfully", type: "success" });
    },
    onError: () => {
      setToastType({
        label: "An Error Occurred While Deleting Asset",
        type: "error",
      });
    },
  });

  const addAssetData = (data) => addAssetMutation(data);

  const updateAssetData = (data) => updateAssetMutation(data);

  const deleteAssetData = (data) => deleteAssetMutation(data);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  useEffect(() => {
    if (success) {
      const timeoutId = setTimeout(() => {
        setSuccess(false);
      }, 3000);
      return () => clearTimeout(timeoutId);
    }
  }, [success]);


  if (isLoading || isRefetching) {
    return <GlobalLoader />;
  }

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Assets"
          title="Dashboard"
          subtitle="Assets"
          modal="#add_asset"
          name="Add Asset"
          permissionKey = "add-assets"
        />
        {/* <AssetsSearchFilter /> */}
        <AssetsTable data={data || []}
        refetch={refetch} />
        <AddAssetpopup
          employees={data?.employees || []}
          addAssetData={addAssetData} // Updated prop name
        />
        {/* <EditAssetpopup
          employees={data.employees || ""}
          selectedAsset={selectedAsset} // Pass selected asset
          onSubmit={updateAssetData}
        /> */}
        <DeleteModal Name="Delete Asset"
            deleteAsset = {deleteAssetData}
            selectedAssetId={selectedAssetId} />
        {success && (
          <InfoToast
            success={success}
            handleClose={handleSolidPrimaryToastClose}
            toastType={toastType}
          />
        )}
      </div>
    </div>
  );
};

export default Assets;
