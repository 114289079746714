import React,{useState,useEffect} from "react";
import { Link } from "react-router-dom";

const ProfileCard = () => {
  const [user, setUser] = useState({});
  const [logo,setLogo] = useState("");

  useEffect(() => {
    const userData = localStorage.getItem("userData");
    const logo = localStorage.getItem("logo");
    setLogo(logo)
    if (userData) {
      setUser(JSON.parse(userData));
    }
  }, []);

  const ProfileName = user?.firstname + " " + (user?.lastname != null ? user?.lastname : '');
  return (
    <div>
      <div className="">
        <div className="card employee-welcome-card flex-fill">
          <div className="card-body">
            <div className="welcome-info">
              <div className="welcome-content">
                <h4>Welcome Back, <span>{ProfileName ? `${ProfileName}` : "Admin"}</span>
                </h4>
                <p>
                Let's make <span>today</span>  amazing!
                </p>
              </div>
              <div className="welcome-img">
                {/* <img src={avatar19} className="img-fluid" alt="User" /> */}
              </div>
            </div>
            <div className="welcome-btn">
              <Link to={`/profile?id=${user.id+1}`} className="btn">
                View Profile
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
