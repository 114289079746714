import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchAllAssetsDetails } from "../../hooks/useUserAssetsDetails";

const UserAssetDetailModal = () => {
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["asset_details"],
    queryFn: fetchAllAssetsDetails,
    refetchOnWindowFocus: false,
  });

  const assetDetails = data?.data || {}; // Extract asset details from API response

  return (
    <>
      <div id="assets-details" className="modal custom-modal fade asset-modal-title " role="dialog">
        <div
          className="modal-dialog modal-dialog-centered asset-modal-container"
          role="document"

          // style={{ maxWidth: "60%" }}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Asset Details</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div className="user_asset">
                <div className="assign-head">
                  <div className="assign-content">
                    <h6>{assetDetails.name}</h6>
                    {/* Uncomment and update image URL if you have it */}
                    {/* <img src="https://i.ibb.co/NZSJ15B/download-1.png" alt={assetDetails.name} /> */}
                  </div>
                </div>

                <div className="card asset-box">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-lg-7">
                        <h5>Asset Info</h5>
                        <div className="asset-info">
                          <div className="asset-info-det">
                            <h6>{assetDetails.manufacturer} {assetDetails.model}</h6>
                            <p>{assetDetails.description}</p>
                            <ul>
                              <li>Model <span>{assetDetails.model}</span></li>
                              <li>Serial Number <span>{assetDetails.serial_number}</span></li>
                              <li>Manufacturer <span>{assetDetails.manufacturer}</span></li>
                            </ul>
                          </div>
                        </div>

                        <div className="assets-image">
                          <h5>Asset Images</h5>
                          <ul>
                            {/* Use existing images section */}
                            <li>
                              <img src="https://i.ibb.co/M1pc0Cc/partner2.png" alt="Asset Image 1" />
                            </li>
                            <li>
                              <img src="https://i.ibb.co/NZSJ15B/download-1.png" alt="Asset Image 2" />
                            </li>
                            <li>
                              <img src="https://i.ibb.co/0y5Vvgb/xplogo.png" alt="Asset Image 3" />
                            </li>
                            <li>
                              <img src="https://i.ibb.co/94nvx5R/banner.jpg" alt="Asset Image 4" />
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-lg-5">
                        <div className="asset-history">
                          <h5>Additional Details</h5>
                          <ul>
                            <li>
                              <div className="asset-inf">
                                <h6>Supplier</h6>
                                <p>{assetDetails.supplier}</p>
                              </div>
                            </li>
                            <li>
                              <div className="asset-inf">
                                <h6>Condition</h6>
                                <p>{assetDetails.condition}</p>
                              </div>
                            </li>
                            <li>
                              <div className="asset-inf">
                                <h6>Warranty</h6>
                                <p>{assetDetails.warranty}</p>
                              </div>
                            </li>
                            <li>
                              <div className="asset-inf">
                                <h6>Purchase Date</h6>
                                <p>{assetDetails.purchase_date}</p>
                              </div>
                            </li>
                            <li>
                              <div className="asset-inf">
                                <h6>Purchase From</h6>
                                <p>{assetDetails.purchase_From}</p>
                              </div>
                            </li>
                            <li>
                              <div className="asset-inf">
                                <h6>Value</h6>
                                <p>{assetDetails.value}</p>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="submit-section mt-4">
                <button className="btn btn-primary submit-btn w-100" data-bs-dismiss="modal">
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserAssetDetailModal;
