import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchEmployeeAlert } from "../../../../../hooks/useEmployeeAlert";

const EmployeeOfMonth = () => {
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["employee_alert"],
    queryFn: fetchEmployeeAlert,
    refetchOnWindowFocus: false,
  });

  const employeeAlerts = data?.data?.employeeAlert || []; // Correct path to the array
  const latestEmployee = employeeAlerts.reduce((latest, current) => {
    return new Date(current.created_at) > new Date(latest.created_at)
      ? current
      : latest;
  }, employeeAlerts[0]);

  return (
    <div>
      <div className="card employee-month-card flex-fill">
        <div className="card-body employee-month-container" >
          <div className="row align-items-center" >
            <div className="col-lg-9 col-md-12">
              <div className="employee-month-details">
                <h4 className="employee-month-details">
                  {isLoading
                    ? "Loading..."
                    : latestEmployee?.title || "No Title"}
                </h4>
                <p>
                  {isLoading
                    ? "Loading message..."
                    : latestEmployee?.message || "There is no Alert available to show"}
                </p>
              </div>
              {isError && (
                <p className="text-danger">
                  Failed to load data: {error?.message}
                </p>
              )}
            </div>
            <div className="col-lg-3 col-md-12">
              <div className="employee-month-img">
                {latestEmployee?.image && (
                  <img
                    src={latestEmployee.image}
                    className="img-employee"
                    alt={latestEmployee?.title || "Employee"}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeOfMonth;
