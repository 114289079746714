import React, { useEffect, useState } from "react";
// import DatePicker from "react-datepicker";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import dayjs from "dayjs";
import { DatePicker } from "antd";
import moment from "moment";

const AllEmployeeAddPopup = (props) => {
  const [department, setDepartment] = useState();
  const [designation, setDesignation] = useState([]);
  const [shift, setShift] = useState({
    value: "",
    label: "Select",
  });
  const [filteredDesignations, setFilteredDesignations] = useState();
  const [eye, setEye] = useState(true);
  const [focus, setFocus] = useState(false);
  const [imageData, setImageData] = useState(null);
  const [uploadedImages, setUploadedImages] = useState([]);
  const [employeeStatus, setEmployeeStatus] = useState({
    value: "",
    label: "",
  });
  const [shiftStatus, setShiftStatus] = useState({
    value: "",
    label: "",
  });
  const [designationStatus, setDesignationStatus] = useState({
    value: "",
    label: "",
  });
  const onEyeClick = () => setEye((prevEye) => !prevEye);

  const companies = [{ value: 1, label: "Xpert Prime" }];
  const genderOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];
  const statusOptions = [
    { value: "pending", label: "Pending" },
    { value: "active", label: "Active" },
    { value: "terminated", label: "Terminated" },
    { value: "resigned", label: "Resigned" },
    { value: "suspended", label: "Suspended" },
    { value: "probationary", label: "Probationary" },
    { value: "contract", label: "Contract" },
    { value: "retired", label: "Retired" },
    { value: "inactive", label: "Inactive" },
  ];

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageData(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    if (props.department) {
      setDepartment(
        props.department.map((item) => ({
          value: item.id,
          label: item.name,
        }))
      );
    }
  }, [props.department]);

  useEffect(() => {
    if (props?.shiftData) {
      setShift(
        props.shiftData?.shifts?.map((item) => ({
          value: item.id,
          label: item.shift_name,
        }))
      );
    }
  }, [props.shiftData]);

  useEffect(() => {
    if (props.department) {
      setDesignation(
        props.designation.map((item) => ({
          value: item.id,
          label: item.name,
          department_id: item.department_id,
        }))
      );
    }
  }, [props.designation]);

  useEffect(() => {
    const filteredData = props?.designation?.filter((item) => {
      return item.id === props.employee.designation_id;
    });
    setDesignationStatus({
      value: filteredData?.[0]?.id,
      label: filteredData?.[0]?.name,
    });

    setEmployeeStatus(
      statusOptions.filter((item) => {
        return item.label === props.employee.status;
      })
    );
    const selectedShift = props?.shiftData?.shifts?.filter((item) => {
      return item.id === props.employee.shift_id;
    });
    if (selectedShift?.length > 0) {
      
      setShiftStatus({
        value: selectedShift?.[0]?.id,
        label: selectedShift?.[0]?.shift_name,
      });
    }
  }, [props.employee, props.shiftData, props.designation]);

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };
  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const validationSchema = yup.object().shape({
    firstName: yup.string().required("First Name is required"),
    lastName: yup.string().required("Last Name is required"),
    gender: yup.object().required("Gender is required"),
    address: yup.string().required("Address is required"),
    email: yup.string().email("Invalid email").required("Email is required"),
    company_email: yup
      .string()
      .email("Invalid email")
      .required("Company Email is required"),
    employeeId: yup.string().required("Employee ID is required"),
    joiningDate: yup.date().required("Joining Date is required"),
    birthday: yup.date().required("Birthday date is required"),
    department: yup.object().required("Department is required"),
    designation: yup.object().required("Designation is required"),
    shift_id: yup.object().required("Shift is required"),
    company: yup.object().required("Company is required"),
    password: props.edit
      ? yup.string()
      : yup
          .string()
          .required("Password is required")
          .min(8, "Password must be at least 8 characters")
          .matches(
            /^(?=.*[A-Z])/,
            "Password must contain at least one uppercase letter"
          )
          .matches(
            /^(?=.*[a-z])/,
            "Password must contain at least one lowercase letter"
          )
          .matches(
            /^(?=.*[0-9])/,
            "Password must contain at least one numeric digit"
          )
          .matches(
            /^(?=.*[!@#$%^&*()_+{}\[\]:<>,.?/~\|])/,
            "Password must contain at least one special character"
          ),
    confirmPassword: props.edit
      ? yup.string().oneOf([yup.ref("password"), null], "Passwords must match")
      : yup
          .string()
          .required("Confirm Password is required")
          .oneOf([yup.ref("password"), null], "Passwords must match"),
    phone: yup
      .string()
      .required("Phone number is required")
      .max(14, "Phone number must be less than or equal to 14 digits"),
  });

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors, isValid },
    reset,
  } = useForm({
    resolver: yupResolver(validationSchema),
    mode: "onChange",
    defaultValues: {
      firstName: "",
      lastName: "",
      address: "",
      gender: "",
      email: "",
      avatar: "",
      password: "",
      confirmPassword: "",
      employeeId: "",
      birthday: "",
      joiningDate: "",
      phone: "",
      company: {
        value: "",
        label: "Select",
      },
      company_email: "",
      department: {
        value: "",
        label: "Select",
      },
      designation: {
        value: "",
        label: "Select",
      },
      shift_id: {
        value: "",
        label: "Select",
      },
      status: "",
    },
  });

  useEffect(() => {
    if (props.employee) {
      reset();
      setImageData(props.employee.avatar);
      setValue("firstName", props.employee.firstname || "");
      setValue("lastName", props.employee.lastname || "");
      // setValue("username", props.employee.username || "");
      setValue("address", props.employee.address || "");
      setValue(
        "gender",
        genderOptions.find(
          (option) => option.value === props.employee.gender
        ) || ""
      );
      setValue(
        "status",
        statusOptions.find(
          (option) => option.label === props.employee.status
        ) || ""
      );
      setValue("email", props.employee.email || "");
      setValue("employeeId", props.employee.uuid || "");
      setValue("birthday", props.employee.birthday || null);
      setValue("joiningDate", props.employee.joining_date || null);
      setValue(
        "department",
        props.employee.department
          ? {
              value: props.employee.department.id,
              label: props.employee.department.name,
            }
          : {
            value: "",
            label: "Select",
          }
      );

      setValue(
        "designation",
        props.employee.designation
          ? {
              value: props.employee.designation.id,
              label: props.employee.designation.name,
            }
          : {
              value: "",
              label: "Select",
            }
      );
      setValue("company", {
        value: 1,
        label: props.employee.company || "Xpert Prime",
      });
      if(props.employee){
        const filteredShift = props?.shiftData?.shifts?.filter((item) => {
          return item?.id === props?.employee?.shift_id;
        });
        setValue("shift_id", {
          value: filteredShift?.[0]?.id,
          label: filteredShift?.[0]?.shift_name,
        });
      }
      setValue("phone", props.employee.phone || "");
      setValue("company_email", props.employee.company_email || "");
    }
  }, [props.employee, reset, setValue]);

  const selectedDepartment = watch("department");
  useEffect(() => {
    setDesignationStatus({
      value: "",
      label: "Select",
    });
    setValue("designation", {
      value: "",
      label: "Select",
    });

    if (selectedDepartment) {
      const filtered = designation.filter(
        (item) => item.department_id === selectedDepartment.value
      );
      setFilteredDesignations(filtered);
      if (filtered.length > 0) {
        const selectedDepartmentValue = filtered.filter((item) => {
          if (item.value === props.employee.designation?.id)
            return {
              value: props.employee.designation?.id,
              label: props.employee.designation.name,
            };
        });
        if (selectedDepartmentValue.length > 0) {
          setDesignationStatus({
            label: selectedDepartmentValue[0].label,
            value: selectedDepartmentValue[0].value,
          });
          setValue("designation", selectedDepartmentValue[0]);
        } else {
          setDesignationStatus({
            value: "",
            label: "Select",
          });
        }
      }
    }
  }, [selectedDepartment]);

  const onSubmit = (data) => {
    const splittedId = data.employeeId.split("-")[1];
    const employeeID = parseInt(splittedId);
    const formattedJoiningDate = dayjs(data.joiningDate).format("YYYY-MM-DD");
    const formattedBirthday = dayjs(data.birthday).format("YYYY-MM-DD");
    const formData = new FormData();
    formData.append("uuid", employeeID);
    formData.append("firstname", data.firstName);
    formData.append("lastname", data.lastName);
    if (
      imageData &&
      imageData !==
        "https://phpstack-1280463-4782840.cloudwaysapps.com/public/storage/employees/default.png"
    ) {
      if (imageData.startsWith("data:image/")) {
        const contentType = imageData.substring(
          imageData.indexOf(":") + 1,
          imageData.indexOf(";")
        );
        const blob = base64ToBlob(imageData, contentType);
        formData.append("avatar", blob, "avatar.png");
      } else {
        // If imageData is not a Base64 string, it's likely an existing image URL
        formData.append("avatar_url", imageData);
      }
    }

    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("gender", data.gender.value);
    formData.append("status", data.status.value);
    formData.append("company", data.company.label);
    formData.append("company_email", data.company_email);
    formData.append("department", data.department.value);
    formData.append("designation", data.designation.value);
    
    formData.append("shift_id", data.shift_id?.value);
    formData.append("password", data.password);
    formData.append("password_confirmation", data.confirmPassword);
    formData.append("birthday", formattedBirthday);
    formData.append("joining_date", formattedJoiningDate);

    props.addEmployeeData(formData);
  };
  const base64ToBlob = (base64String, contentType = "") => {
    const byteCharacters = atob(base64String.split(",")[1]); // Decode base64 string
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  };
  const onSubmitUpdate = (data) => {
    const employeeID = parseInt(data.employeeId);
    const formattedJoiningDate = dayjs(data.joiningDate).format("YYYY-MM-DD");
    const formattedBirthday = dayjs(data.birthday).format("YYYY-MM-DD");
    const formData = new FormData();
    formData.append("uuid", employeeID);
    formData.append("firstname", data.firstName);
    formData.append("lastname", data.lastName);
    if (
      imageData &&
      imageData !==
        "https://phpstack-1280463-4782840.cloudwaysapps.com/public/storage/employees/default.png"
    ) {
      if (imageData.startsWith("data:image/")) {
        const contentType = imageData.substring(
          imageData.indexOf(":") + 1,
          imageData.indexOf(";")
        );
        const blob = base64ToBlob(imageData, contentType);
        formData.append("avatar", blob, "avatar.png");
      } else {
        // If imageData is not a Base64 string, it's likely an existing image URL
        formData.append("avatar_url", imageData);
      }
    }
    formData.append("email", data.email);
    formData.append("phone", data.phone);
    formData.append("address", data.address);
    formData.append("gender", data.gender.value);
    formData.append("status", data.status.value);
    formData.append("company", data.company.label);
    formData.append("company_email", data.company_email);
    formData.append("department", data.department.value);
    formData.append("designation", data.designation.value);
    formData.append("shift_id", data.shift_id.value);
    formData.append("password", data.password);
    formData.append("password_confirmation", data.confirmPassword);
    formData.append("birthday", formattedBirthday);
    formData.append("joining_date", formattedJoiningDate);
    formData.append("id", props.employee.id);

    props.updateEmployeeData(formData);
  };

  return (
    <>
      <div id="add_employee" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Add Employee</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="profile-img-wrap edit-img">
                  <img
                    className="inline-block"
                    src={imageData ?? "default-imageData.jpg"}
                    alt="user"
                  />
                  <div className="fileupload btn">
                    <span className="btn-text">Add</span>
                    <input
                      className="upload"
                      type="file"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>

                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.firstName && (
                              <p className="text-danger">
                                {errors.firstName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Employee ID <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="employeeId"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.employeeId ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.employeeId && (
                              <p className="text-danger">
                                {errors.employeeId.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Password <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <>
                            <div style={{ position: "relative" }}>
                              <input
                                {...field}
                                className={`form-control ${
                                  errors.password ? "is-invalid" : ""
                                }`}
                                type={eye ? "password" : "text"}
                              />
                              <span
                                onClick={onEyeClick}
                                style={{
                                  position: "absolute",
                                  right: "30px",
                                  top: "32%", // Center the icon vertically
                                  // transform: "translateY(-100%)", // Adjust for perfect centering
                                  cursor: "pointer",
                                }}
                                className={`fa-solid ${
                                  eye ? "fa-eye-slash" : "fa-eye"
                                }`}
                              />
                            </div>
                          </>
                        )}
                      />

                      {errors.password && (
                        <span className="text-danger">
                          {errors.password.message}
                        </span>
                      )}
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Personal Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              type="email"
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.phone && (
                        <p className="text-danger">{errors.phone.message}</p>
                      )}
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="gender"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={genderOptions}
                            placeholder="Select Gender"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                          />
                        )}
                      />
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={companies}
                            placeholder="Select"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                          />
                        )}
                      />
                    </div>
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Designation <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="designation"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={filteredDesignations}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.designation && (
                              <p className="text-danger">
                                {errors.designation.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                    {/* 

                    <div className="input-block mb-3" >
                      <label className="col-form-label">
                        Upload Attachment <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="attachment"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${errors.attachment ? "is-invalid" : ""
                                }`}
                              type="file"
                            />
                            {errors.attachment && (
                              <p className="text-danger">
                                {errors.attachment.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div> */}

                    <div className="input-block mb-3">
                      <label className="col-form-label">Upload Image(s)</label>
                      <input
                        className="form-control"
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                      />
                      <div className="mt-2">
                        {uploadedImages?.length > 0 && (
                          <ul>
                            {uploadedImages.map((image, index) => (
                              <li key={index}>
                                {image.name}{" "}
                                <button
                                  type="button"
                                  onClick={() => handleRemoveImage(index)}
                                  style={{
                                    margin: "5px 5px",
                                    border: "none",
                                    background: "none",
                                  }}
                                >
                                  <i className="fas fa-times" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className="form-control"
                              type="text"
                            />
                            {errors.lastName && (
                              <p className="text-danger">
                                {errors.lastName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company_email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.company_email && (
                        <p className="text-danger">
                          {errors.company_email.message}
                        </p>
                      )}
                    </div>

                    <div
                      className="input-block mb-3"
                      style={{ position: "relative" }}
                    >
                      <label className="col-form-label">
                        Confirm Password <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <div style={{ position: "relative" }}>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.confirmPassword ? "is-invalid" : ""
                              }`}
                              type={eye ? "password" : "text"}
                              style={{ paddingRight: "2.5rem" }} // Space for the icon
                            />
                            <span
                              onClick={onEyeClick}
                              style={{
                                position: "absolute",
                                right: "30px",
                                top: "32%", // Center the icon vertically
                                // transform: "translateY(-100%)", // Adjust for perfect centering
                                cursor: "pointer",
                              }}
                              className={`fa-solid ${
                                eye ? "fa-eye-slash" : "fa-eye"
                              }`}
                            />
                          </div>
                        )}
                      />

                      {errors.confirmPassword && (
                        <span className="text-danger">
                          {errors.confirmPassword.message}
                        </span>
                      )}
                    </div>
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Joining Date <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="joiningDate"
                        control={control}
                        render={({ field }) => (
                          <div className="relative">
                            <DatePicker
                              value={field.value ? dayjs(field.value) : null}
                              // disabledDate={disabledDate}
                              format="DD-MM-YYYY"
                              onChange={(date) =>
                                field.onChange(date ? date.toISOString() : null)
                              }
                              className={`form-control cal-icon ${
                                errors.joiningDate ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.joiningDate && (
                              <p className="text-danger">
                                {errors.joiningDate.message}
                              </p>
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Birthday <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              // disabledDate={disabledDate}
                              format="DD-MM-YYYY"
                              value={field.value ? dayjs(field.value) : null}
                              onChange={(date) => field.onChange(date)}
                              className={`form-control cal-icon ${
                                errors.birthday ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.birthday && (
                              <p className="text-danger">
                                {errors.birthday.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.address && (
                        <p className="text-danger">{errors.address.message}</p>
                      )}
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Department <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="department"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={department}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.department && (
                              <p className="text-danger">
                                {errors.department.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Shift <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="shift_id"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={shift}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.shift && (
                              <p className="text-danger">
                                {errors.shift.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>

                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Status <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            placeholder="Status"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                          />
                        )}
                      />
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    data-bs-dismiss="modal"
                    disabled={!isValid}
                  >
                    Submit
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {/* ==============================================Edit Employee======================================== */}
      <div id="edit_employee" className="modal custom-modal fade" role="dialog">
        <div
          className="modal-dialog modal-dialog-centered modal-lg"
          role="document"
        >
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">Edit Employee</h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmitUpdate)}>
                <div className="profile-img-wrap edit-img">
                  <img
                    className="inline-block"
                    src={imageData ?? "default-imageData.jpg"}
                    alt="user"
                  />
                  <div className="fileupload btn">
                    <span className="btn-text">edit</span>
                    <input
                      className="upload"
                      type="file"
                      onChange={handleImageChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        First Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="firstName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.firstName ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.firstName && (
                              <p className="text-danger">
                                {errors.firstName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Last Name <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="lastName"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className="form-control"
                              type="text"
                            />
                            {errors.lastName && (
                              <p className="text-danger">
                                {errors.lastName.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Employee ID <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="employeeId"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.employeeId ? "is-invalid" : ""
                              }`}
                              type="text"
                            />
                            {errors.employeeId && (
                              <p className="text-danger">
                                {errors.employeeId.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company_email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.company_email && (
                        <p className="text-danger">
                          {errors.company_email.message}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-sm-6" style={{ display: "none" }}>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Password</label>
                      <Controller
                        name="password"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.password ? "is-invalid" : ""
                              }`}
                              type="password"
                            />
                            {errors.password && (
                              <p className="text-danger">
                                {errors.password.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6" style={{ display: "none" }}>
                    <div className="input-block mb-3">
                      <label className="col-form-label">Confirm Password</label>
                      <Controller
                        name="confirmPassword"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.confirmPassword ? "is-invalid" : ""
                              }`}
                              type="password"
                            />
                            {errors.confirmPassword && (
                              <p className="text-danger">
                                {errors.confirmPassword.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Personal Email <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <>
                            <input
                              {...field}
                              className={`form-control ${
                                errors.email ? "is-invalid" : ""
                              }`}
                              type="email"
                            />
                            {errors.email && (
                              <p className="text-danger">
                                {errors.email.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Joining Date <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="joiningDate"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              value={field.value ? dayjs(field.value) : null}
                              format="DD-MM-YYYY"
                              onChange={(date) => field.onChange(date)}
                              className={`form-control cal-icon ${
                                errors.joiningDate ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.joiningDate && (
                              <p className="text-danger">
                                {errors.joiningDate.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Phone <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.phone && (
                        <p className="text-danger">{errors.phone.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="input-block mb-3"
                      style={{ position: `relative` }}
                    >
                      <label className="col-form-label">
                        Birthday <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="birthday"
                        control={control}
                        render={({ field }) => (
                          <>
                            <DatePicker
                              selected={field.value}
                              value={field.value ? dayjs(field.value) : null}
                              format="DD-MM-YYYY"
                              onChange={(date) => field.onChange(date)}
                              className={`form-control cal-icon ${
                                errors.birthday ? "is-invalid" : ""
                              }`}
                              getPopupContainer={(trigger) =>
                                trigger.parentElement
                              }
                            />
                            {errors.birthday && (
                              <p className="text-danger">
                                {errors.birthday.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Gender <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="gender"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={genderOptions}
                            placeholder="Select Gender"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                            value={field.value}
                          />
                        )}
                      />
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Address <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="address"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            className="form-control"
                            type="text"
                          />
                        )}
                      />
                      {errors.address && (
                        <p className="text-danger">{errors.address.message}</p>
                      )}
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Company <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="company"
                        control={control}
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={companies}
                            placeholder="Select"
                            styles={customStyles}
                            onChange={(option) => field.onChange(option)}
                          />
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Department <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="department"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={department}
                              placeholder="Select"
                              styles={customStyles}
                              onChange={(option) => field.onChange(option)}
                            />
                            {errors.department && (
                              <p className="text-danger">
                                {errors.department.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Designation <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="designation"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={filteredDesignations}
                              placeholder="Select"
                              styles={customStyles}
                              value={designationStatus}
                              onChange={(option) => {
                                field.onChange(option);
                                setDesignationStatus(option);
                              }}
                            />
                            {errors.designation && (
                              <p className="text-danger">
                                {errors.designation.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">Upload Image(s)</label>
                      <input
                        className="form-control"
                        type="file"
                        multiple
                        onChange={handleImageUpload}
                      />
                      <div className="mt-2">
                        {uploadedImages.length > 0 && (
                          <ul>
                            {uploadedImages.map((image, index) => (
                              <li key={index}>
                                {image.name}{" "}
                                <button
                                  type="button"
                                  onClick={() => handleRemoveImage(index)}
                                  style={{
                                    margin: "5px 5px",
                                    border: "none",
                                    background: "none",
                                  }}
                                >
                                  <i className="fas fa-times" />
                                </button>
                              </li>
                            ))}
                          </ul>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Shift <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="shift_id"
                        control={control}
                        render={({ field }) => (
                          <>
                            <Select
                              {...field}
                              options={shift}
                              placeholder="Select"
                              styles={customStyles}
                              value={shiftStatus}
                              onChange={(option) => {
                                field.onChange(option);
                                setShiftStatus(option);
                              }}
                            />
                            {errors.shift && (
                              <p className="text-danger">
                                {errors.shift.message}
                              </p>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-block mb-3">
                      <label className="col-form-label">
                        Status <span className="text-danger">*</span>
                      </label>
                      <Controller
                        name="status"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            options={statusOptions}
                            placeholder="Status"
                            styles={customStyles}
                            value={employeeStatus}
                            onChange={(option) => {
                              field.onChange(option);
                              setEmployeeStatus(option);
                            }}
                          />
                        )}
                      />
                      {errors.gender && (
                        <p className="text-danger">{errors.gender.message}</p>
                      )}
                    </div>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    type="submit"
                    className="btn btn-primary submit-btn"
                    disabled={!isValid}
                    data-bs-dismiss="modal"
                  >
                    Save Changes
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AllEmployeeAddPopup;
