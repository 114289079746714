export const filteredSidebarData = [
  {
    title: 'MAIN',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Dashboard',
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-dashcube",
        permissions: ['view-admin-dashboard','view-employee-dashboard'], // Adjusted to hyphen format
        subMenus: [
          {
            menuValue: 'Admin Dashboard',
            route: "/admin-dashboard",
            permissions: ['view-admin-dashboard'], // Adjusted
          },
          {
            menuValue: 'Employee Dashboard',
            route: "/employee-dashboard",
            permissions: ['view-employee-dashboard'], // Adjusted
          },
          
        ],
      },
    ],
  },
  {
    title: 'EMPLOYEES',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Employees',
        hasSubRoute: true,
        showSubRoute: false,
        route: "#",
        icon: "la la-user",
        permissions: ['view-employee','view-holiday','view-leave','view-leaves-employee','view-monthly-attendance','view-attendance','view-attendance-request','view-department','view-designation','view-employee-query','view-performance-matrix','view-policies'],
        subMenus: [
          {
            menuValue: 'All Employees',
            route: "/employees",
            permissions: ['view-employee'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Holidays',
            route: "/holidays",
            permissions: ['view-holiday'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Leaves',
            route: "/leaves",
            permissions: ['view-leave'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Leaves (Employee)',
            route: "/leaves-employee",
            permissions: ['view-leaves-employee'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Monthly Attendance',
            route: "/admin-attendance",
            permissions: ['view-monthly-attendance'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Attendance',
            route: "/attendance-employee",
            permissions: ['view-attendance'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Attendance Request',
            route: "/attendance-request",
            permissions: ['view-attendance-request'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Departments',
            route: "/departments",
            permissions: ['view-department'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Designations',
            route: "/designations",
            permissions: ['view-designation'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Employees Query',
            route: "/employees-query",
            permissions: ['view-employee-query']
          },
          {
            menuValue: 'Performance',
            route:"/performance",
            permissions:['view-performance']
          },
          {
            menuValue:'Performance Indicator',
            route: "/performance-indicator",
            permissions:['view-performance-matrix']
          },
          {
            menuValue: "Policies",
            route: "/policies",
            permissions: ['view-policies'], // Adjusted to hyphen format
          },
          {
            menuValue:'OverTime',
            route: "/overtime",
            permissions:['view-overtime']
          },
          {
            menuValue:'Shift Scheduling',
            route: "/shift-scheduling",
            permissions:['view-shift-scheduling']
          }
          
        ],
      },
    ],
  },
  {
    title: 'HR',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Payroll',
        hasSubRoute: true,
        showSubRoute: false,
        icon: "la la-money",
        permissions: ['view-increment-record','view-payslip','view-employee-salary'], // Only accessible by HR
        subMenus: [
          {
            menuValue: 'Employee Salary',
            route: "/salary",
            permissions: ['view-employee-salary'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Payslip',
            route: "/salary-view",
            permissions: ['view-payslip'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Increment Record',
            route: "/increment-record",
            permissions: ['view-increment-record'], // Adjusted to hyphen format
          },
        ],
      },
    ],
  },
  {
    title: 'ADMINISTRATION',
    showAsTab: false,
    separateRoute: false,
    menu: [
      {
        menuValue: 'Users',
        hasSubRoute: false,
        showSubRoute: false,
        route: "/users",
        icon: "la la-user-plus",
        permissions: ['view-user'], // Only accessible by Admin
      },
      {
        menuValue: "Settings",
        hasSubRoute: true,
        showAsTab: false,
        icon: "la la-cog",
        permissions: ['view-company-settings','view-theme-settings','view-roles-permissions','view-attendance-settings','view-leave-settings','view-leave-type','view-assets',  'view-employee-alert','view-alerts'], // Accessible by Admin
        subMenus: [
          {
            menuValue: "Company Settings",
            route: "/company-settings",
            permissions: ['view-company-settings'], // Adjusted to hyphen format
          },
          {
            menuValue: "Theme Settings",
            route: "/theme-settings",
            permissions: ['view-theme-settings'], // Adjusted to hyphen format
          },
          {
            menuValue: "Roles Permissions",
            route: "/roles-permissions",
            permissions: ['view-roles-permissions'], // Adjusted to hyphen format
          },
          {
            menuValue: "Attendance Settings",
            route: "/attendance-settings",
            permissions: ['view-attendance-settings'], // Adjusted to hyphen format
          },
          {
            menuValue: 'Leave Settings',
            route: "/leave-settings",
            permissions: ['view-leave-settings'], // Adjusted to hyphen format
          },
          {
            menuValue: "Leave Type",
            route: "/leave-type",
            permissions: ['view-leave-type'], // Adjusted to hyphen format
          },
          {
            menuValue: "Assets",
            route: "/assets",
            permissions: ['view-assets'], // Adjusted to hyphen format
          },
         
          {
            menuValue: "Employee Alert",
            route: "/employee-alert",
            permissions: ['view-employee-alert'], // Adjusted to hyphen format
          },
          {
            menuValue: "All Alerts",
            route: "/all-alerts",
            permissions: ['view-alerts'], // Adjusted to hyphen format
          }
        ],
      },
    ],
  },
]; 