import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import DepartmentModal from "../../../components/modelpopup/DepartmentModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  addDepartmentPost,
  delDepartment,
  fetchDepartments,
  updDepartment,
} from "../../../hooks/useDepartment";
import InfoToast from "../../layout/InfoToast";
import Spinnerloaders from "../../../components/SpinnerLoader";
import ImageUpload from "../../layout/ImageUpload";
import GlobalLoader from "../../layout/GlobalLoader";
import PermissionWrapper from "../../../authorization/PermissionWrapper";

const Department = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedId, setSelectedId] = useState(null);
  const [selectedDep, setSelectedDep] = useState("");
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const handleTableChange = (pagination) => {
    setPagination({
      current: pagination.current,
      pageSize: pagination.pageSize,
    });
  };

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };
  // ========================================Fetch Department===========================
  const { data, error, status, refetch,isLoading } = useQuery({
    queryKey: ["todos"],
    queryFn: fetchDepartments,
    refetchOnWindowFocus: false,
  });

  // ========================================Add Department===========================
  const { mutate: addDepMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addDepartmentPost,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Department Added", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Adding Department",
        type: "Error",
      });
    },
  });
  const addDepartment = (data) => {
    addDepMutation(data);
  };
  // ========================================Update Department===========================
  const { mutate: updDepMutation } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updDepartment,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Department Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Updating Department",
        type: "Error",
      });
    },
  });
  const updateDepartment = (data) => {
    updDepMutation({ id: selectedId, name: data });
  };
  // ========================================Del Department===========================
  const { mutate: delDepMutation } = useMutation({
    mutationFn: delDepartment,
    onMutate: () => {
      setIsRefetching(true);
    },
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({ label: "Department Deleted", type: "Deleted" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: "An Error Occurred While Deleting Department",
        type: "Error",
      });
    },
  });
  const deleteDepartment = (id) => {
    delDepMutation(id);
  };
  if (isLoading) {
    return <GlobalLoader />
  }
  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }

  const userElements = data?.map((user, index) => ({
    key: index,
    id: user.id,
    department: user.department,
  }));

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {((pagination.current - 1) * pagination.pageSize) + index + 1}
        </span>
      ),
    },
    {
      title: "Department Name",
      dataIndex: "department",
      sorter: (a, b) => a.department.length - b.department.length,
      width: "80%",
    },
    {
      title: "Action",
      className: "text-end",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"edit-department"}>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_department"
              onClick={() => {
                setSelectedId(record.id);
                setSelectedDep(record.department);
              }}
              >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
              </PermissionWrapper>
              <PermissionWrapper permissionKey={"delete-department"}>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedId(record.id);
              }}
              >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
              </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];


  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Department"
            title="Dashboard"
            subtitle="Department"
            modal="#add_department"
            name="Add Department"
            permissionKey="add-department"
          />
          {/* /Page Header */}
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  columns={columns}
                  dataSource={userElements?.length > 0 ? userElements : []}
                  className="table-striped"
                  rowKey={(record) => record.id}
                  pagination={pagination}
                  onChange={handleTableChange}
                />
                {isRefetching && <Spinnerloaders />}
              </div>
            </div>
          </div>
        </div>
      </div>

      <DepartmentModal
        addDepartment={addDepartment}
        selectedDep={selectedDep}
        updateDepartment={updateDepartment}
      />
      <DeleteModal
        Name="Delete Department"
        deleteDepartment={() => deleteDepartment(selectedId)}
      />
      <InfoToast
        success={success}
        handleClose={handleSolidPrimaryToastClose}
        toastType={toastType}
      />
    </>
  );
};

export default Department;
