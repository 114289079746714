import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Table,Popover } from "antd";
import { Link } from "react-router-dom";
import GlobalLoader from "../../../layout/GlobalLoader";
import AddandEditPolicy from "../../../../components/modelpopup/AddandEditPolicy";
import DeleteModal from "../../../../components/modelpopup/deletePopup";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchAllPolicies,
  addPolicies,
  deletePolicies,
  updatePolicies,
} from "../../../../hooks/usePolicies";
import InfoToast from "../../../layout/InfoToast";
import DeletePolicyPopup from "../../../../components/modelpopup/deletePolicyPopup";
import AddPolicyModal from "../../../../components/modelpopup/AddPolicyModal";
import EditPolicyModal from "../../../../components/modelpopup/EditPolicyModal";
import PermissionWrapper from "../../../../authorization/PermissionWrapper";

const Policies = () => {
  const [showModal, setShowModal] = useState(false);
  const [policyData, setPolicyData] = useState(null);
  const [isRefetching, setIsRefetching] = useState(false);
  const [editable, setEditable] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const { data, error, status, refetch, isLoading } = useQuery({
    queryKey: ["policies"],
    queryFn: fetchAllPolicies,
    refetchOnWindowFocus: false,
    select: (response) => response?.data?.policies || [], 
  });

  useEffect(() => {}, [data]);
  

  const { mutate: addPolicyMutation } = useMutation({
    onMutate: () => setIsRefetching(true),
    mutationFn: addPolicies,
    onSuccess: (response) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: response.message || "Policy Added",
        type: "Added",
      });
    },
    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "Error",
      });
    },
  });
  const { mutate: updatePolicyMutation } = useMutation({
    onMutate: () => setIsRefetching(true),
    mutationFn: updatePolicies,
    onSuccess: (response) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(() => setSuccess(true));
      setToastType({
        label: response.message || "Policy Updated",
        type: "Updated",
      });
    },
    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "Error",
      });
    },
  });
  const { mutate: deletePolicyMutation } = useMutation({
    mutationFn: deletePolicies,
    onMutate: (deletedPolicyId) => {
      // Optimistically update the table by removing the deleted policy from the data
      const newData = data.filter((policy) => policy.id !== deletedPolicyId);
      setPolicyData(newData);
    },
    onSuccess: (response) => {
      refetch().then(() => {
        setSuccess(true);
        setToastType({
          label: response.message || "Policy Deleted",
          type: "Deleted",
        });
      });
    },
    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "Error",
      });
    },
  });

  const deletePolicyData = (policyId) => {
    deletePolicyMutation(policyId);
  };

  const updatePolicyData = (data) => {
    updatePolicyMutation(data);
  };
  // const deletePolicyData = (data) => {
  //   deletePolicyMutation(data);
  // };
  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(pagination.current - 1) * pagination.pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Policy Name",
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Description",
      dataIndex: "description",
      render: (text) => (
          <Popover
              content={<span>{text}</span>}
              title="Description"
              trigger="hover"
              overlayStyle={{ maxWidth: "400px" }}
          >
              <span
                  style={{
                      display: "inline-block",
                      maxWidth: "250px",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      cursor: "pointer",
                  }}
              >
                  {text}
              </span>
          </Popover>
      ),
  },
    {
      title: "Department",
      render: (_, record) => (
        <span>
          {record.department ? record.department.name : "N/A"}
        </span>
      ),
      sorter: (a, b) => {
        const departmentA = a.department ? a.department.name : '';
        const departmentB = b.department ? b.department.name : '';
        return departmentA.localeCompare(departmentB);
    }
  },
    {
      title: "Action",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
          {record.file && (
            <Link
              className="dropdown-item"
              to={record.file}
              target="_blank"
              rel="noopener noreferrer"
            >
              <i className="fa fa-eye m-r-5" /> View Policy
            </Link>
          )}
          <PermissionWrapper permissionKey={"edit-policies"}>
           <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_policy"
              onClick={() => {
                setPolicyData(record);
                setEditable(true);
              }}
              >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
              </PermissionWrapper>
            <PermissionWrapper permissionKey={"delete-policies"}>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setPolicyData(record.id);
              }}
              >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
            </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  if (isLoading || isRefetching) {
    return <GlobalLoader />;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="Policies"
            title="Dashboard"
            subtitle="Policies"
            modal="#add_policy" 
            name="Add Policy"
            permissionKey = "add-policies"
          />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data || []} 
                  rowKey={(record) => record.id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <AddandEditPolicy
        editable={editable}
        policyData={policyData}
        addPolicyData={addPolicyMutation}
        updatePolicyData={updatePolicyMutation}
        closeModal={() => setShowModal(false)}
      /> */}

      <AddPolicyModal 
      addPolicyData={addPolicyMutation}
      closeModal={() => setShowModal(false)}
      />
      <EditPolicyModal
       editable={editable}
       policyData={policyData}
       updatePolicyData={updatePolicyMutation}
       closeModal={() => setShowModal(false)}
       />

      <DeletePolicyPopup
        Name="Delete Policy"
        deletePolicies={deletePolicyData}
        policyData={policyData}
      />
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default Policies;
