import React, { useEffect, useRef, useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { logo3 } from "../../../../Routes/ImagePath/index";
import { useQuery } from "@tanstack/react-query";
import { fetchThemeSettings } from "../../../../hooks/useSettings";
import GlobalLoader from "../../../layout/GlobalLoader";
import InfoToast from "../../../layout/InfoToast";

const ThemeSettings = () => {
  const [uploadedFavicon, setUploadedFavicon] = useState(null);
  const [uploadedLogo, setUploadedLogo] = useState(null);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState({ label: "", type: "" });
  const [formData, setFormData] = useState({
    site_name: "",
    currency_symbol: "",
    currency_code: "",
    logo: null,
    favicon: null,
  });

  const fileUploadRef = useRef();
  const faviconUploadRef = useRef();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const uploadImageDisplay = () => {
    const uploadedFile = fileUploadRef.current.files[0];
    if (uploadedFile) {
      setUploadedLogo(uploadedFile);
    }
  };

  const uploadFaviconDisplay = () => {
    const uploadedFile = faviconUploadRef.current.files[0];
    if (uploadedFile) {
      setUploadedFavicon(uploadedFile);
    }
  };

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["theme-settings"],
    queryFn: fetchThemeSettings,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      setFormData({
        site_name: data.site_name,
        currency_symbol: data.currency_symbol,
        currency_code: data.currency_code,
        logo: data.logo || null,
        favicon: data.favicon || null,
      });
    }
  }, [data]);
  useEffect(() => {
    localStorage.setItem("logo", formData.logo);
    localStorage.setItem("favicon", formData.favicon);
  }, [formData.logo, formData.favicon])


  const handleFormSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem("token");

      const formDataToSend = new FormData();
      formDataToSend.append("site_name", formData.site_name);
      formDataToSend.append("currency_code", formData.currency_code);
      formDataToSend.append("currency_symbol", formData.currency_symbol);
      if (uploadedLogo) {
        formDataToSend.append("logo", uploadedLogo);
      }
      if (uploadedFavicon) {
        formDataToSend.append("favicon", uploadedFavicon);
      }

      const response = await fetch("https://phpstack-1280463-4782840.cloudwaysapps.com/api/settings-theme", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          // Do not set 'Content-Type' header for FormData; it will be set automatically
        },
        body: formDataToSend,
      });

      if (response.ok) {
        const responseData = await response.json();
        setSuccess(true);
        setToastType({ label: "Theme Settings Updated", type: "Updated" });
        refetch(); // Refetch the settings to get the updated data
      } else {
        setToastType({
          label: "An Error Occurred While Updating Theme Settings",
          type: "Error",
        });
        setSuccess(true);
      }
    } catch (error) {
      console.error(error);
    }
  };


  const getBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });
  };

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              <Breadcrumbs maintitle="Theme Settings" />
              <form onSubmit={handleFormSubmit}>
                <div className="input-block row theme-label">
                  <label className="col-lg-3 col-form-label">Website Name</label>
                  <div className="col-lg-9">
                    <input
                      name="site_name"
                      className="form-control"
                      value={formData.site_name}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row theme-label">
                  <label className="col-lg-3 col-form-label">Currency Symbol</label>
                  <div className="col-lg-9">
                    <input
                      name="currency_symbol"
                      className="form-control"
                      value={formData.currency_symbol}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row theme-label">
                  <label className="col-lg-3 col-form-label">Currency Code</label>
                  <div className="col-lg-9">
                    <input
                      name="currency_code"
                      className="form-control"
                      value={formData.currency_code}
                      onChange={handleInputChange}
                      type="text"
                    />
                  </div>
                </div>
                <div className="input-block row theme-label">
                  <label className="col-lg-3 col-form-label">Light Logo</label>
                  <div className="col-lg-7">
                    <input
                      type="file"
                      ref={fileUploadRef}
                      className="form-control"
                      onChange={uploadImageDisplay}
                    />
                    <span className="form-text text-muted">
                      Recommended image size is 40px x 40px
                    </span>
                  </div>
                  <div className="col-lg-2 float-end">
                    <img
                      src={uploadedLogo ? URL.createObjectURL(uploadedLogo) : formData.logo || logo3}
                      alt="Logo"
                      width={45}
                      height={45}
                    />
                  </div>
                </div>
                <div className="input-block row theme-label">
                  <label className="col-lg-3 col-form-label">Favicon</label>
                  <div className="col-lg-7">
                    <input
                      type="file"
                      ref={faviconUploadRef}
                      className="form-control"
                      onChange={uploadFaviconDisplay}
                    />
                    <span className="form-text text-muted">
                      Recommended image size is 40px x 40px
                    </span>
                  </div>
                  <div className="col-lg-2 float-end">
                    <img
                      src={uploadedFavicon ? URL.createObjectURL(uploadedFavicon) : formData.favicon || logo3}
                      alt="Favicon"
                      width={45}
                      height={45}
                    />
                  </div>
                </div>
                <div className="submit-section">
                  <button className="btn btn-primary submit-btn" type="submit">
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {success && (
        <InfoToast
          success={success}
          handleClose={() => setSuccess(false)}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default ThemeSettings;
