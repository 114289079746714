// import React from "react";
// import { Link } from "react-router-dom";
// const employeeStatics = () => {
//   return (
//     <div>
//       <div className="card flex-fill">
//         <div className="card-body">
//           <div className="statistic-header">
//             <h4>Statistics</h4>
//             <div className="dropdown statistic-dropdown">
//               <Link
//                 className="dropdown-toggle"
//                 data-bs-toggle="dropdown"
//                 to="#"
//               >
//                 Today
//               </Link>
//               <div className="dropdown-menu dropdown-menu-end">
//                 <Link to="#" className="dropdown-item">
//                   Week
//                 </Link>
//                 <Link to="#" className="dropdown-item">
//                   Month
//                 </Link>
//                 <Link to="#" className="dropdown-item">
//                   Year
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="clock-in-info">
//             <div className="clock-in-content">
//               <p>Work Time</p>
//               <h4>6 Hrs : 54 Min</h4>
//             </div>
//             <div className="clock-in-btn">
//               <Link to="#" className="btn btn-primary">
//                 {/* <img src={clockin} alt="Icon" />{" "} */}
//                 Clock-In
//               </Link>
//             </div>
//           </div>
//           <div className="clock-in-list">
//             <ul className="nav">
//               <li>
//                 <p>Remaining</p>
//                 <h6>2 Hrs 36 Min</h6>
//               </li>
//               <li>
//                 <p>Overtime</p>
//                 <h6>0 Hrs 00 Min</h6>
//               </li>
//               <li>
//                 <p>Break</p>
//                 <h6>1 Hrs 20 Min</h6>
//               </li>
//             </ul>
//           </div>
//           <div className="view-attendance">
//             <Link to="/admin-attendance">
//               View Attendance <i className="fe fe-arrow-right-circle" />
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default employeeStatics;

// dynamic the statics with timer reset on page freshed





// import React, { useState, useEffect } from "react";
// import { Link } from "react-router-dom";

// const EmployeeStatistics = () => {
//   const [clockedIn, setClockedIn] = useState(false); // Track clock-in/out state
//   const [startTime, setStartTime] = useState(null); // Store the start time
//   const [totalWorkTime, setTotalWorkTime] = useState(0); // Total work time in seconds
//   const [overtime, setOvertime] = useState(0); // Overtime in seconds
//   const [breakTime, setBreakTime] = useState(60 * 60); // Default break time 1 hour (in seconds)
//   const [remainingTime, setRemainingTime] = useState(9 * 60 * 60); // 9 hours in seconds

//   // Update total work time and overtime when the clock is running
//   useEffect(() => {
//     let interval;

//     if (clockedIn) {
//       interval = setInterval(() => {
//         const elapsed = Math.floor((Date.now() - startTime) / 1000);
//         setTotalWorkTime(elapsed);
//         // Calculate overtime if total work time exceeds 9 hours
//         if (elapsed > 9 * 60 * 60) {
//           setOvertime(elapsed - 9 * 60 * 60);
//         }
//         // Update remaining time (9 hours - total work time)
//         setRemainingTime(9 * 60 * 60 - elapsed);
//       }, 1000);
//     } else if (!clockedIn && startTime !== null) {
//       clearInterval(interval);
//     }

//     return () => clearInterval(interval);
//   }, [clockedIn, startTime]);

//   const handleClockInOut = () => {
//     if (clockedIn) {
//       // Clock out logic: stop the timer
//       setClockedIn(false);
//     } else {
//       // Clock in logic: start the timer
//       setStartTime(Date.now());
//       setClockedIn(true);
//     }
//   };

//   const formatTime = (seconds) => {
//     const hours = Math.floor(seconds / 3600);
//     const minutes = Math.floor((seconds % 3600) / 60);
//     const formatted = `${hours} Hrs : ${minutes} Min`;
//     return formatted;
//   };

//   return (
//     <div>
//       <div className="card flex-fill">
//         <div className="card-body">
//           <div className="statistic-header">
//             <h4>Statistics</h4>
//             {/* <div className="dropdown statistic-dropdown">
//               <Link
//                 className="dropdown-toggle"
//                 data-bs-toggle="dropdown"
//                 to="#"
//               >
//                 Today
//               </Link>
//               <div className="dropdown-menu dropdown-menu-end">
//                 <Link to="#" className="dropdown-item">
//                   Week
//                 </Link>
//                 <Link to="#" className="dropdown-item">
//                   Month
//                 </Link>
//                 <Link to="#" className="dropdown-item">
//                   Year
//                 </Link>
//               </div>
//             </div> */}
//           </div>
//           <div className="clock-in-info">
//             <div className="clock-in-content">
//               <p>Work Time</p>
//               <h4>{formatTime(totalWorkTime)}</h4>
//             </div>
//             <div className="clock-in-btn">
//               <Link
//                 to="#"
//                 className="btn btn-primary"
//                 onClick={handleClockInOut}
//               >
//                 {clockedIn ? "Clock-Out" : "Clock-In"}
//               </Link>
//             </div>
//           </div>
//           <div className="clock-in-list">
//             <ul className="nav">
//               <li>
//                 <p>Remaining</p>
//                 <h6>{formatTime(remainingTime)}</h6>
//               </li>
//               <li>
//                 <p>Overtime</p>
//                 <h6>{formatTime(overtime)}</h6>
//               </li>
//               <li>
//                 <p>Break</p>
//                 <h6>{formatTime(breakTime)}</h6>
//               </li>
//             </ul>
//           </div>
//           <div className="view-attendance">
//             <Link to="/admin-attendance">
//               View Attendance <i className="fe fe-arrow-right-circle" />
//             </Link>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default EmployeeStatistics;


// dynamic the employee statics page refreshed only when clock out 



import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

const EmployeeStatistics = () => {
  const [clockedIn, setClockedIn] = useState(false); // Track clock-in/out state
  const [startTime, setStartTime] = useState(null); // Store the start time
  const [totalWorkTime, setTotalWorkTime] = useState(0); // Total work time in seconds
  const [overtime, setOvertime] = useState(0); // Overtime in seconds
  const [breakTime, setBreakTime] = useState(0); // Break time in seconds (start at 0)
  const [remainingTime, setRemainingTime] = useState(9 * 60 * 60); // 9 hours in seconds
  const [onBreak, setOnBreak] = useState(false); // Track whether the user is on break

  // Load clock-in status, start time, and break time from localStorage on page load
  useEffect(() => {
    const savedClockedIn = JSON.parse(localStorage.getItem("clockedIn"));
    const savedStartTime = localStorage.getItem("startTime");
    const savedBreakTime = Number(localStorage.getItem("breakTime")) || 0; // Load breakTime from localStorage
    const savedOnBreak = JSON.parse(localStorage.getItem("onBreak")); // Get onBreak status from localStorage

    if (savedClockedIn && savedStartTime) {
      setClockedIn(true);
      setStartTime(Number(savedStartTime));
    }

    setBreakTime(savedBreakTime); // Set break time to the saved value
    setOnBreak(savedOnBreak); // Restore onBreak state
  }, []);

  // Update total work time and overtime when the clock is running
  useEffect(() => {
    let interval;

    if (clockedIn && startTime !== null) {
      interval = setInterval(() => {
        const elapsed = Math.floor((Date.now() - startTime) / 1000);
        setTotalWorkTime(elapsed);
        // Calculate overtime if total work time exceeds 9 hours
        if (elapsed > 9 * 60 * 60) {
          setOvertime(elapsed - 9 * 60 * 60);
        }
        // Update remaining time (9 hours - total work time)
        setRemainingTime(9 * 60 * 60 - elapsed);
      }, 1000);
    } else if (!clockedIn && startTime !== null) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [clockedIn, startTime]);

  // Update break time when break status changes (continue when onBreak is true)
  useEffect(() => {
    let breakInterval;

    if (onBreak) {
      breakInterval = setInterval(() => {
        setBreakTime((prevBreakTime) => {
          const newBreakTime = prevBreakTime + 1;
          localStorage.setItem("breakTime", newBreakTime); // Store break time in localStorage
          return newBreakTime;
        });
      }, 1000);
    }

    // When break is not active, clear the interval (but don't stop it on page refresh)
    return () => clearInterval(breakInterval);
  }, [onBreak]);

  const handleClockInOut = () => {
    if (clockedIn) {
      // Clock out logic: stop the timer and reset the stored data
      setClockedIn(false);
      setStartTime(null);
      setTotalWorkTime(0);
      setOvertime(0);
      setRemainingTime(9 * 60 * 60);
      setBreakTime(0); // Reset break time when clocking out
      setOnBreak(false); // Reset break status when clocking out
      // Remove the data from localStorage
      localStorage.removeItem("clockedIn");
      localStorage.removeItem("startTime");
      localStorage.removeItem("breakTime");
      localStorage.removeItem("onBreak"); // Reset break status in localStorage
    } else {
      // Clock in logic: start the timer and store the start time
      const currentStartTime = Date.now();
      setStartTime(currentStartTime);
      setClockedIn(true);
      // Store the data in localStorage
      localStorage.setItem("clockedIn", true);
      localStorage.setItem("startTime", currentStartTime);
    }
  };

  const handleBreakInOut = () => {
    // Toggle the break status (start/stop break)
    setOnBreak((prevState) => {
      const newState = !prevState;
      localStorage.setItem("onBreak", newState); // Store break status in localStorage
      return newState;
    });
  };

  const formatTime = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const formatted = `${hours} Hrs : ${minutes} Min`;
    return formatted;
  };

  return (
    <div>
      <div className="card flex-fill">
        <div className="card-body">
          <div className="statistic-header">
            <h4>Statistics</h4>
          </div>
          <div className="clock-in-info">
            <div className="clock-in-content">
              <p>Work Time</p>
              <h4>{formatTime(totalWorkTime)}</h4>
            </div>
            <div className="clock-in-btn">
              <Link
                to="#"
                className="btn btn-primary"
                onClick={handleClockInOut}
              >
                {clockedIn ? "Clock-Out" : "Clock-In"}
              </Link>
            </div>
          </div>

          

          <div className="clock-in-list">
            <ul className="nav">
              <li>
                <p>Remaining</p>
                <h6>{formatTime(remainingTime)}</h6>
              </li>
              <li>
                <p>Overtime</p>
                <h6>{formatTime(overtime)}</h6>
              </li>
              <li>
                <p>Break</p>
                <h6>{formatTime(breakTime)}</h6>
              </li>
            </ul>
          </div>
          <div className="view-attendance d-flex justify-content-between">
            <Link to="/admin-attendance">
              View Attendance <i className="fe fe-arrow-right-circle" />
            </Link>
            {/* Break In / Break Out button */}
          {clockedIn && (
            <div className="break-btn">
              <Link
                to="#"
                className= "btn btn-primary"
                onClick={handleBreakInOut}
              >
                {onBreak ? "Break-Out" : "Break-In"}
              </Link>
            </div>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeStatistics;


