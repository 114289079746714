// /* eslint-disable react/no-unescaped-entities */
// import React, { useState } from "react";

// import { Link } from "react-router-dom";
// import {
//   avatar1,
//   avatar13,
//   avatar16,
//   avatar18,
//   avatar19,
//   avatar2,
//   avatar20,
//   avatar21,
//   avatar23,
//   avatar26,
//   avatar4,
//   avatar6,
//   avatar8,
//   clockin,
//   employeeimg,
//   holidaycalendar,
// } from "../../../../../Routes/ImagePath";
// import Chart from "react-apexcharts";
// import { ArrowRightCircle } from "react-feather";
// import { OverlayTrigger, Tooltip } from "react-bootstrap";
// import ProfileCard from "./profileCard";
// import UpcomingHolidays from "../AdminDashboard/upcomingHolidays";
// import EmployeeStatics from "./employeeStatics";
// import ImportantNotifications from "../AdminDashboard/importantNotifications";
// import AttendanceLeaves from "./attendanceLeaves";
// import WorkingHours from "./workingHours";
// import CompanyPolicy from "./companyPolicy";
// import EmployeeOfMonth from "../AdminDashboard/employeeOfMonth";

// const EmployeeDashboard = () => {
//   const tooltip = <Tooltip id="tooltip-id">Lesley Grauer</Tooltip>;
//   const tooltip1 = <Tooltip id="tooltip-id">Richard Miles</Tooltip>;
//   const tooltip2 = <Tooltip id="tooltip-id">Loren Gatlin</Tooltip>;
//   const tooltip3 = <Tooltip id="tooltip-id">Jeffery Lalor</Tooltip>;
//   const tooltip4 = <Tooltip id="tooltip-id">Tarah Shropshire</Tooltip>;
//   const tooltip5 = <Tooltip id="tooltip-id">Catherine Manseau</Tooltip>;
//   const tooltip6 = <Tooltip id="tooltip-id">Richard Miles</Tooltip>;
//   const tooltip7 = <Tooltip id="tooltip-id">Jeffery Lalor</Tooltip>;
//   const tooltip8 = <Tooltip id="tooltip-id">Lesley Grauer</Tooltip>;
//   const tooltip9 = <Tooltip id="tooltip-id">Loren Gatlin</Tooltip>;
//   const tooltip10 = <Tooltip id="tooltip-id">Tarah Shropshire</Tooltip>;
//   const tooltip11 = <Tooltip id="tooltip-id">John Smith"</Tooltip>;
//   const tooltip12 = <Tooltip id="tooltip-id">Richard Miles</Tooltip>;
//   const tooltip13 = <Tooltip id="tooltip-id">Jeffery Lalor</Tooltip>;
//   const tooltip14 = <Tooltip id="tooltip-id">Lesley Grauer</Tooltip>;
//   const tooltip15 = <Tooltip id="tooltip-id">Tarah Shropshire</Tooltip>;
//   const tooltip16 = <Tooltip id="tooltip-id">Loren Gatlin</Tooltip>;
//   // const [menu, setMenu] = useState(false);

//   // const toggleMobileMenu = () => {
//   //   setMenu(!menu);
//   // };

//   const settingsprojectslide = {
//     dots: false,
//     infinite: true,
//     speed: 500,
//     slidesToShow: 3,
//     slidesToScroll: 1,
//   };

//   return (
//     <>
//       <div className="page-wrapper">
//         {/* Page Content */}
//         <div className="content container-fluid pb-0">
//           <div className="row">
//             <div className="col-md-12">
//               <div className="employee-alert-box">
//                 <div className="alert alert-outline-success alert-dismissible fade show">
//                   <div className="employee-alert-request">
//                     <i className="far fa-circle-question" />
//                     Your Leave Request on <span>“24th April 2024”</span> has
//                     been Approved!!!
//                   </div>
//                   <button
//                     type="button"
//                     className="btn-close"
//                     data-bs-dismiss="alert"
//                     aria-label="Close"
//                   >
//                     <i className="fas fa-xmark" />
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>

//           <div className="row">
//             <div className="col-xxl-8 col-lg-12 col-md-12">
//               <div className="row">
//                 <ProfileCard />
//                 <UpcomingHolidays />
//                 <EmployeeStatics />
//                 <ImportantNotifications />
//                 <AttendanceLeaves />
//                 <WorkingHours />
//                 <CompanyPolicy />
//                 <EmployeeOfMonth />
                
//               </div>
//             </div>
//           </div>
//           {/* <div className="row">
//             <div className="col-md-12">
//               <div className="card">
//                 <div className="card-body">
//                   <div className="row align-items-center">
//                     <div className="col-sm-8">
//                       <div className="statistic-header">
//                         <h4>On Going Projects</h4>
//                       </div>
//                   </div>
//                     <div className="col-sm-4 text-sm-end">
//                       <div className="owl-nav project-nav nav-control" />
//                     </div>
//                   </div>
//                   <Slider {...settingsprojectslide} className="project-slider owl-carousel"> */}
//           {/* Project Grid */}
//           {/* <div className="project-grid">
//                       <div className="project-top">
//                         <h6>
//                           <Link to="/project-view">Deadline : 10 Feb 2024</Link>
//                         </h6>
//                         <h5>
//                           <Link to="/project-view">Office Management</Link>
//                         </h5>
//                         <p>
//                           Creating an online platform that enables various
//                           administrative tasks within an organization
//                         </p>
//                       </div>
//                       <div className="project-middle">
//                         <ul className="nav">
//                           <li>
//                             <div className="project-tasks">
//                               <h4>20</h4>
//                               <p>Total Tasks</p>
//                             </div>
//                           </li>
//                           <li>
//                             <div className="project-tasks">
//                               <h4>15</h4>
//                               <p>Total Completed</p>
//                             </div>
//                           </li>
//                         </ul>
//                       </div>
//                       <div className="project-bottom">
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Project Leader :</li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Jeffery Lalor"
//                                 data-bs-original-title="Jeffery Lalor"
//                               >
//                                 <img
//                                   src={avatar19}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Members :</li>
//                             <li>

//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar20}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip1}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar19}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip2}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar20}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip3}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar16}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip4}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar23}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <Link
//                                 to="#"
//                                 className="more-team-members"
//                               >
//                                 +16
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div> */}
//           {/* /Project Grid */}
//           {/* Project Grid */}
//           {/* <div className="project-grid">
//                       <div className="project-top">
//                         <h6>
//                           <Link to="/project-view">Deadline : 15 Feb 2024</Link>
//                         </h6>
//                         <h5>
//                           <Link to="/project-view">Video Calling App</Link>
//                         </h5>
//                         <p>
//                           Design and developing a software application that enables
//                           users to make video calls over the internet.
//                         </p>
//                       </div>
//                       <div className="project-middle">
//                         <ul className="nav">
//                           <li>
//                             <div className="project-tasks">
//                               <h4>30</h4>
//                               <p>Total Tasks</p>
//                             </div>
//                           </li>
//                           <li>
//                             <div className="project-tasks">
//                               <h4>12</h4>
//                               <p>Total Completed</p>
//                             </div>
//                           </li>
//                         </ul>
//                       </div>
//                       <div className="project-bottom">
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Project Leader :</li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip5}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar18}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Members :</li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip6}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar21}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip7}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar16}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip8}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar20}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>

//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip9}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar1}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip10}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar23}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <Link
//                                 to="#"
//                                 className="more-team-members"
//                               >
//                                 +10
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div> */}
//           {/* /Project Grid */}
//           {/* Project Grid */}
//           {/* <div className="project-grid">
//                       <div className="project-top">
//                         <h6>
//                           <Link to="/project-view">Deadline : 12 Apr 2024</Link>
//                         </h6>
//                         <h5>
//                           <Link to="/project-view">Hospital Administration</Link>
//                         </h5>
//                         <p>
//                           Creating an online platform that serves as a central hub
//                           for hospital admin, staff, patients.
//                         </p>
//                       </div>
//                       <div className="project-middle">
//                         <ul className="nav">
//                           <li>
//                             <div className="project-tasks">
//                               <h4>40</h4>
//                               <p>Total Tasks</p>
//                             </div>
//                           </li>
//                           <li>
//                             <div className="project-tasks">
//                               <h4>02</h4>
//                               <p>Total Completed</p>
//                             </div>
//                           </li>
//                         </ul>
//                       </div>
//                       <div className="project-bottom">
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Project Leader :</li>
//                             <li>

//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip11}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar4}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Members :</li>
//                             <li>

//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip12}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar6}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>

//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip13}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar13}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>

//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip14}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar18}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip15}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar23}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
                             
//                               <OverlayTrigger
//                                 placement="top"
//                                 overlay={tooltip16}
//                               >
//                                 <Link to="/project-view">
//                                   <img
//                                     src={avatar1}
//                                     alt="User"
//                                   />
//                                 </Link>
//                               </OverlayTrigger>
//                             </li>
//                             <li>
//                               <Link
//                                 to="#"
//                                 className="more-team-members"
//                               >
//                                 +12
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div> */}
//           {/* /Project Grid */}
//           {/* Project Grid */}
//           {/* <div className="project-grid">
//                       <div className="project-top">
//                         <h6>
//                           <Link to="/project-view">Deadline : 25 Apr 2024</Link>
//                         </h6>
//                         <h5>
//                           <Link to="/project-view">Digital Marketpace</Link>
//                         </h5>
//                         <p>
//                           Creating an online platform that connects sellers with
//                           buyers, facilitating the exchange of goods,
//                         </p>
//                       </div>
//                       <div className="project-middle">
//                         <ul className="nav">
//                           <li>
//                             <div className="project-tasks">
//                               <h4>50</h4>
//                               <p>Total Tasks</p>
//                             </div>
//                           </li>
//                           <li>
//                             <div className="project-tasks">
//                               <h4>10</h4>
//                               <p>Total Completed</p>
//                             </div>
//                           </li>
//                         </ul>
//                       </div>
//                       <div className="project-bottom">
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Project Leader :</li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Jeffery Lalor"
//                                 data-bs-original-title="Jeffery Lalor"
//                               >
//                                 <img
//                                   src={avatar1}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Members :</li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Loren Gatlin"
//                                 data-bs-original-title="Loren Gatlin"
//                               >
//                                 <img
//                                   src={avatar26}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Lesley Grauer"
//                                 data-bs-original-title="Lesley Grauer"
//                               >
//                                 <img
//                                   src={avatar18}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Richard Miles"
//                                 data-bs-original-title="Richard Miles"
//                               >
//                                 <img
//                                   src={avatar6}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Jeffery Lalor"
//                                 data-bs-original-title="Jeffery Lalor"
//                               >
//                                 <img
//                                   src={avatar13}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Tarah Shropshire"
//                                 data-bs-original-title="Tarah Shropshire"
//                               >
//                                 <img
//                                   src={avatar8}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="#"
//                                 className="more-team-members"
//                               >
//                                 +13
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div> */}
//           {/* /Project Grid */}
//           {/* Project Grid */}
//           {/* <div className="project-grid">
//                       <div className="project-top">
//                         <h6>
//                           <Link to="/project-view">Deadline : 15 Feb 2024</Link>
//                         </h6>
//                         <h5>
//                           <Link to="/project-view">Video Calling App</Link>
//                         </h5>
//                         <p>
//                           Design and developing a software application that enables
//                           users to make video calls over the internet.
//                         </p>
//                       </div>
//                       <div className="project-middle">
//                         <ul className="nav">
//                           <li>
//                             <div className="project-tasks">
//                               <h4>30</h4>
//                               <p>Total Tasks</p>
//                             </div>
//                           </li>
//                           <li>
//                             <div className="project-tasks">
//                               <h4>12</h4>
//                               <p>Total Completed</p>
//                             </div>
//                           </li>
//                         </ul>
//                       </div>
//                       <div className="project-bottom">
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Project Leader :</li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Catherine Manseau"
//                                 data-bs-original-title="Catherine Manseau"
//                               >
//                                 <img
//                                   src={avatar18}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                         <div className="project-leader">
//                           <ul className="nav">
//                             <li>Members :</li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Richard Miles"
//                                 data-bs-original-title="Richard Miles"
//                               >
//                                 <img
//                                   src={avatar21}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Jeffery Lalor"
//                                 data-bs-original-title="Jeffery Lalor"
//                               >
//                                 <img
//                                   src={avatar16}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Lesley Grauer"
//                                 data-bs-original-title="Lesley Grauer"
//                               >
//                                 <img
//                                   src={avatar20}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Loren Gatlin"
//                                 data-bs-original-title="Loren Gatlin"
//                               >
//                                 <img
//                                   src={avatar1}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="/project-view"
//                                 data-bs-toggle="tooltip"
//                                 aria-label="Tarah Shropshire"
//                                 data-bs-original-title="Tarah Shropshire"
//                               >
//                                 <img
//                                   src={avatar23}
//                                   alt="User"
//                                 />
//                               </Link>
//                             </li>
//                             <li>
//                               <Link
//                                 to="#"
//                                 className="more-team-members"
//                               >
//                                 +10
//                               </Link>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div> */}
//           {/* /Project Grid */}
//           {/* </Slider> */}
//           {/* </div>
//               </div>
//             </div>
//           </div> */}
//         </div>
//       </div>
//     </>
//   );
// };

// export default EmployeeDashboard;








import React, { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import ProfileCard from "./profileCard";
import UpcomingHolidays from "../AdminDashboard/upcomingHolidays";
import EmployeeStatics from "./employeeStatics";
// import ImportantNotifications from "../AdminDashboard/importantNotifications";
import AttendanceLeaves from "./attendanceLeaves";
import WorkingHours from "./workingHours";
import CompanyPolicy from "./companyPolicy";
import EmployeeOfMonth from "../AdminDashboard/employeeOfMonth";
import EmployeeNotifications from "./EmployeeNotifications";
import NotificationAlert from "./notificationAlert";
import EmployeeUpcomingHolidays from "./employeeUpcomingHolidays";

const EmployeeDashboard = () => {
  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid pb-0">
          <div className="row">
            <NotificationAlert/>
          </div>

         
              
                <div className="row">
                <div className="col-lg-4 col-md-12">
                <ProfileCard />
                <EmployeeStatics />
                <EmployeeUpcomingHolidays/>
                </div>
                <div className="col-lg-4 col-md-12">
                <AttendanceLeaves />
                <WorkingHours />
                </div>
                <div className="col-lg-4 col-md-12">
                <EmployeeNotifications/>
                </div>
                </div>
                
                <div className="row">
                <div className="col-lg-6 col-md-12">
                <EmployeeOfMonth />
                </div>
                <div className="col-lg-6 col-md-12">
                <CompanyPolicy />
                </div>
                </div>
                
              </div>
            </div>
          
    </>
  );
};



  export default EmployeeDashboard;
