import React, { useEffect, useState } from "react";
import { fetchEmployeePerformance } from "../../../../hooks/usePerformanceIndicator";

const PersonalExcellence = () => {
  const [rows, setRows] = useState([]);
  const [feedbacks, setFeedbacks] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchEmployeePerformance();
      const personalData = data["Personal Excellence"].map((item, index) => ({
        id: item.id,
        keyResultArea: item.description, // Map description
        kpi: item.name, // Map name
        weightage: 10, // Default weightage, adjust if required
        selfScore: 0,
        roScore: 0,
        error: "",
      }));
      setRows(personalData);
    };

    fetchData();
  }, []);

  const handleInputChange = (e, rowIndex, field) => {
    const value = e.target.value;
    const weightage = rows[rowIndex].weightage;

    const isValid = value === "" || (parseFloat(value) >= 0 && parseFloat(value) <= weightage);

    const newRows = [...rows];
    if (!isValid) {
      newRows[rowIndex].error = `Score cannot exceed the weightage of ${weightage}`;
    } else {
      newRows[rowIndex][field] = value === "" ? "" : parseFloat(value);
      newRows[rowIndex].error = "";
    }
    setRows(newRows);
  };
  const handleFeedbackChange = (e, rowId) => {
    const { value } = e.target;
    setFeedbacks((prev) => ({
      ...prev,
      [rowId]: value,
    }));
  };

  return (
    <section className="review-section personal-excellence">
      <div className="review-header text-center">
        <h3 className="review-title">Personal Excellence</h3>
      </div>
      <div className="table-responsive">
        <table className="table table-bordered review-table mb-0">
          <thead>
            <tr>
              <th>#</th>
              <th>Key Result Area</th>
              {/* <th>Key Performance Indicators</th> */}
              <th>Weightage</th>
              <th>Score</th>
              <th>Feedback</th>
            </tr>
          </thead>
          <tbody>
            {rows.map((row, index) => (
              <tr key={row.id}>
                <td>{row.id}</td>
                <td>{row.keyResultArea}</td>
                {/* <td>{row.kpi}</td> */}
                <td>
                  <input type="text" className="form-control" readOnly value={row.weightage} />
                </td>
                <td>
                  <input
                    type="text"
                    className={`form-control ${row.error ? "is-invalid" : ""}`}
                    value={row.roScore}
                    onChange={(e) => handleInputChange(e, index, "roScore")}
                  />
                  {row.error && <div className="invalid-feedback">{row.error}</div>}
                </td>
                <td className="feedback-container">
                  <input className="feedback form-control" type="text" placeholder="Enter Feedback" value={feedbacks[row.id] || ""}
                      onChange={(e) => handleFeedbackChange(e, row.id)}
                      maxLength={200}  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  );
};

export default PersonalExcellence;
