import React, { useState, useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Link } from "react-router-dom";
import Select from "react-select";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import "../assets/css/searchBarButtons.css";
import { fetchAllEmployees } from "../hooks/useEmployee";

const EmployeeListFilter = (props) => {
  const [inputValue, setInputValue] = useState("");
  const prefix = "TXP - ";
  const [inputValue1, setInputValue1] = useState("");
  const [focused, setFocused] = useState(false);
  const [focused1, setFocused1] = useState(false);
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const [selectedDesignation, setSelectedDesignation] = useState(null);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [filteredDesignations, setFilteredDesignations] = useState([]);
  const [value, setValue] = useState("designation");
  const [designationStatus, setDesignationStatus] = useState({
    value: "",
    label: "",
  });

  // ==================================Get Employee data================================
  const { data, error, refetch, isLoading } = useQuery({
    queryKey: ["allEmployees"],
    queryFn: fetchAllEmployees,
    refetchOnWindowFocus: false,
  });

  const designation = props?.designation?.map((item) => ({
    value: item.id,
    label: item.name,
    department_id: item.department_id,
  }));

  const department = props?.department?.map((item) => ({
    value: item.id,
    label: item.name,
  }));

  useEffect(() => {
    if (selectedDepartment) {
      const filtered = designation.filter(
        (item) => item.department_id === selectedDepartment.value
      );

      if (filtered.length !== filteredDesignations?.length) {
        setFilteredDesignations(filtered);
      }

      const selectedDepartmentValue = filtered.find(
        (item) => item.value === props.employee?.designation?.id
      );

      if (selectedDepartmentValue) {
        if (selectedDepartmentValue.value !== designationStatus.value) {
          setDesignationStatus({
            label: selectedDepartmentValue.label,
            value: selectedDepartmentValue.value,
          });
          setSelectedDesignation(selectedDepartmentValue);
        }
      } else {
        if (designationStatus.value !== "") {
          setDesignationStatus({ value: "", label: "Select" });
          setSelectedDesignation(null);
        }
      }
    } else {
      if (filteredDesignations?.length > 0) {
        setFilteredDesignations([]);
        setSelectedDesignation(null);
      }
    }
  }, [
    selectedDepartment,
    props.employee?.designation?.id,
    designation,
    filteredDesignations,
    designationStatus,
  ]);

  const statusOptions = props?.status || [];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleLabelClick = () => {
    setFocused(true);
  };

  const handleLabelClick2 = () => {
    setFocused1(true);
  };

  const handleInputBlur = () => {
    if (inputValue === "") {
      setFocused(false);
    }
  };

  const handleInputBlur2 = () => {
    if (inputValue1 === "") {
      setFocused1(false);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/[^0-9]/g, "");
    setInputValue(value);
  };

  const handleInputChange2 = (e) => {
    const value = e.target.value;
    setInputValue1(value);
    if (value !== "" && !focused1) {
      setFocused1(true);
    }
  };

  const handleDepartmentChange = (selectedOption) => {
    setSelectedDepartment(selectedOption);
  };

  const handleDesignationChange = (selectedOption) => {
    setSelectedDesignation(selectedOption);
  };

  const handleStatusChange = (selectedOption) => {
    setSelectedStatus(selectedOption);
  };

  const handleSubmit = () => {
    props.searchEmployees({
      employee_id: inputValue,
      name: inputValue1,
      department: selectedDepartment ? selectedDepartment.value : null,
      designation: selectedDesignation ? selectedDesignation.value : null,
      status: selectedStatus ? selectedStatus.value : null,
    });
  };

  const handleReset = () => {
    props.searchEmployees({
      employee_id: "",
      name: "",
      department: null,
      designation: null,
      status: null,
    });
    setInputValue("");
    setInputValue1("");
    setSelectedDepartment(null);
    setSelectedDesignation(null);
    setSelectedStatus(null);
    setFocused(false);
    setFocused1(false);
  };

  return (
    <div>
      <div className="row filter-row">
        <div className="col-sm-6 col-md-2">
          <div className={"input-block form-focus focused"}>
            <input
              type="text"
              className="form-control floating"
              value={`TXP - ${inputValue || ""}`}
              onFocus={handleLabelClick}
              onBlur={handleInputBlur}
              onChange={handleInputChange}
            />
            <label className="focus-label" onClick={handleLabelClick}>
              Employee ID
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-2">
          <div className={"input-block form-focus focused"}>
            <input
              type="text"
              className="form-control floating"
              value={inputValue1}
              onFocus={handleLabelClick2}
              onBlur={handleInputBlur2}
              onChange={handleInputChange2}
            />
            <label className="focus-label" onClick={handleLabelClick2}>
              Employee Name
            </label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={department}
              placeholder="Select Department"
              styles={customStyles}
              value={selectedDepartment}
              onChange={handleDepartmentChange}
            />
            <label className="focus-label">Department</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-3">
          <div className="input-block form-focus select-focus">
            <Select
              options={filteredDesignations}
              placeholder="Select Designation"
              styles={customStyles}
              value={selectedDesignation}
              onChange={handleDesignationChange}
            />
            <label className="focus-label">Designation</label>
          </div>
        </div>
        <div className="col-sm-6 col-md-2 containerStyle">
          <Link to="#" onClick={handleSubmit} className="me-2 linkStyle">
            <BsSearch className="iconStyle" />
          </Link>
          <Link to="#" onClick={handleReset} className="linkStyle">
            <MdOutlineSettingsBackupRestore className="iconStyle" />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default EmployeeListFilter;
