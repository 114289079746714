import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

// Attendance & Leave 

export const fetchAttendanceLeave = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`attendance-leave`)
    return data.data;
}

// Working Hours 

export const fetchWorkingHours = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`working-hours`)
    return data.data;
}

// Statics

export const fetchStatics = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{ data } = await axiosInstance.get(`statics`)
    return data.data;
}

// Emoployee of the month

export const fetchEmployeeMonth = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} = await axiosInstance.get(`employee-month`)
    return data.data;
}

// Upcoming Holidays

export const fetchUpcomingHolidays = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const {data} = await axiosInstance.get(`upcoming-holidays`)
    return data.data;
}

// company policy 

export const fetchCompanyPolicy = async () =>{
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} = await axiosInstance.get(`company-policy`)
    return data.data;
}

// Employee Statics 

export const AddEmployeeStatics = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} =await axiosInstance.post(`employee-statics`,payload)
    return data;
}