import React, { useState, useEffect } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import { Table, Popover } from "antd";
import { Link } from "react-router-dom";
import GlobalLoader from "../../../layout/GlobalLoader";
import InfoToast from "../../../layout/InfoToast";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchEmployeeAlert,
  deleteAlert,
} from "../../../../hooks/useEmployeeAlert";
import DeleteAlertPopup from "../../../../components/modelpopup/DeleteAlertPopup";
import PermissionWrapper from "../../../../authorization/PermissionWrapper";

const AllAlerts = () => {
  const [alertData, setAlertData] = useState();
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 10; // Adjust to your pagination size

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };

  const { data, error, status, refetch, isLoading } = useQuery({
    queryKey: ["alerts"],
    queryFn: fetchEmployeeAlert,
    refetchOnWindowFocus: false,
    select: (response) => response?.data?.employeeAlert || [],
  });

  const { mutate: deleteAlertMutation } = useMutation({
    mutationFn: deleteAlert,
    onSuccess: (response) => {
      refetch().then(() => {
        setSuccess(true);
        setToastType({
          label: response.message || "Alert Deleted",
          type: "Deleted",
        });
      });
    },
    onError: (error) => {
      console.error("Delete Error:", error); // Log the error
      setToastType({
        label: error.message || "An error occurred while deleting the alert",
        type: "Error",
      });
      setSuccess(true); // Show toast for error
    },
  });
  

  const deleteAlertData = (alertId) => {
    deleteAlertMutation(alertId);
  };

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  const columns = [
    {
      title: "Sr #",
      render: (text, record, index) => (
        <span className="table-avatar">
          {(currentPage - 1) * pageSize + index + 1}
        </span>
      ),
    },
    {
      title: "Title",
      dataIndex: "title",
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: "Message",
      dataIndex: "message",
      render: (text) => (
        <Popover
          content={<span>{text}</span>}
          title="Message"
          trigger="hover"
          overlayStyle={{ maxWidth: "400px" }}
        >
          <span
            style={{
              display: "inline-block",
              maxWidth: "250px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              cursor: "pointer",
            }}
          >
            {text}
          </span>
        </Popover>
      ),
    },
      // {
      //   title: "Department ID",
      //   dataIndex: "department_id",
      //   render: (text) => <span>{text || "N/A"}</span>,
      // },
    {
      title: "Created At",
      dataIndex: "created_at",
      render: (text) => <span>{new Date(text).toLocaleDateString()}</span>,
    },
    {
      title: "Action",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"delete-alerts"}>

            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => setAlertData(record.id)}
              >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
              </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <GlobalLoader />;
  }

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle="All Alerts"
            title="Dashboard"
            subtitle="Alerts"
            name="Add Alert"
            Link="/employee-alert"
            permissionKey="add-alerts"
          />

          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <Table
                  className="table-striped"
                  style={{ overflowX: "auto" }}
                  columns={columns}
                  dataSource={data || []}
                  rowKey={(record) => record.id}
                  pagination={{ pageSize }}
                  onChange={handleTableChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <DeleteAlertPopup
        name="Delete Alert"
        deleteAlert={deleteAlertData}
        alertData={alertData}
      />

      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default AllAlerts;
