// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import Chart from "react-apexcharts";

// const WorkingHours = () => {
//   const [chartOptions] = useState({
//     series: [
//       {
//         name: "Sales",
//         data: [-50, -120, -80, -180, -80, -70, -100],
//       },
//       {
//         name: "Purchase",
//         data: [200, 250, 200, 290, 220, 300, 250],
//       },
//     ],
//     colors: ["#FC133D", "#55CE63"],
//     chart: {
//       type: "bar",
//       height: 210,
//       stacked: true,

//       zoom: {
//         enabled: true,
//       },
//     },
//     responsive: [
//       {
//         breakpoint: 280,
//         options: {
//           legend: {
//             position: "bottom",
//             offsetY: 0,
//           },
//         },
//       },
//     ],
//     plotOptions: {
//       bar: {
//         horizontal: false,
//         borderRadius: 6,
//         borderRadiusApplication: "end", // "around" / "end"
//         borderRadiusWhenStacked: "all", // "all"/"last"
//         columnWidth: "30%",
//         endingShape: "rounded",
//       },
//     },
//     dataLabels: {
//       enabled: false,
//     },
//     yaxis: {
//       min: -200,
//       max: 300,
//       tickAmount: 5,
//     },
//     xaxis: {
//       categories: ["S", "M", "T", "W", "T", "F", "S"],
//     },
//     legend: { show: false },
//     fill: {
//       opacity: 1,
//     },
//   });
//   return (
//     <div>
//       <div className="card flex-fill">
//         <div className="card-body">
//           <div className="statistic-header">
//             <h4>Working hours</h4>
//             <div className="dropdown statistic-dropdown">
//               <Link
//                 className="dropdown-toggle"
//                 data-bs-toggle="dropdown"
//                 to="#"
//               >
//                 This Week
//               </Link>
//               <div className="dropdown-menu dropdown-menu-end">
//                 <Link to="#" className="dropdown-item">
//                   Last Week
//                 </Link>
//                 <Link to="#" className="dropdown-item">
//                   This Month
//                 </Link>
//                 <Link to="#" className="dropdown-item">
//                   Last 30 Days
//                 </Link>
//               </div>
//             </div>
//           </div>
//           <div className="working-hour-info">
//             <div id="working_chart" />
//             <Chart
//               options={chartOptions}
//               series={chartOptions.series}
//               type="bar"
//               height={210}
//             />
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default WorkingHours;


// dynamic the working hours 

import React, { useState } from "react";
import { Link } from "react-router-dom";
import Chart from "react-apexcharts";
import { useQuery } from "@tanstack/react-query"; // React Query for data fetching
import { fetchWorkingHours } from "../../../../../hooks/useEmployeeDashboard"; // API function

const WorkingHours = () => {
  const [chartOptions, setChartOptions] = useState({
    series: [
      { name: "Employee Hours", data: [8, 7.5, 8, 7, 6.5] }, // Default working hours data
    ],
    colors: ["#55CE63"], // Green color for employee hours
    chart: {
      type: "bar",
      height: 210,
      stacked: false, // Not stacked as it's only one series now
      zoom: { enabled: true },
    },
    responsive: [
      {
        breakpoint: 280,
        options: {
          legend: { position: "bottom", offsetY: 0 },
        },
      },
    ],
    plotOptions: {
      bar: {
        horizontal: false,
        borderRadius: 6,
        borderRadiusApplication: "end",
        borderRadiusWhenStacked: "all",
        columnWidth: "30%",
        endingShape: "rounded",
      },
    },
    dataLabels: { enabled: false },
    yaxis: {
      min: 0, // Adjust to show positive hours
      max: 12, // Max hours in a day
      tickAmount: 6, // Show hourly ticks
    },
    xaxis: {
      categories: ["M", "T", "W", "T", "F"], // Default categories for weekdays
    },
    legend: { show: false },
    fill: { opacity: 1 },
  });

  // Fetch data using React Query
  const { data, isLoading, isError, error } = useQuery({
    queryKey: ["working_hours"],
    queryFn: fetchWorkingHours,
    onSuccess: (response) => {
      const { workingHoursData, categories } = response;
      setChartOptions((prev) => ({
        ...prev,
        series: [
          { name: "Employee Hours", data: workingHoursData },
        ],
        xaxis: { ...prev.xaxis, categories },
      }));
    },
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      <div className="card flex-fill">
        <div className="card-body">
          <div className="statistic-header">
            <h4>Working hours</h4>
            {/* <div className="dropdown statistic-dropdown">
              <Link
                className="dropdown-toggle"
                data-bs-toggle="dropdown"
                to="#"
              >
                This Week
              </Link>
              <div className="dropdown-menu dropdown-menu-end">
                <Link to="#" className="dropdown-item">
                  Week 1
                </Link>
                <Link to="#" className="dropdown-item">
                  Week 2
                </Link>
                <Link to="#" className="dropdown-item">
                  Week 3
                </Link>
                <Link to="#" className="dropdown-item">
                  Week 4
                </Link>
              </div>
            </div> */}
          </div>
          <div className="working-hour-info">
            {/* {!isLoading && isError && (
              <p className="text-danger">
                Failed to load data: {error?.message}
              </p>
            )} */}
            <Chart
              options={chartOptions}
              series={chartOptions.series}
              type="bar"
              height={210}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingHours;
