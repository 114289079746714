import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchAllPolicies } from "../../../../../hooks/usePolicies";

const CompanyPolicy = () => {
  const {
    data: { data } = { data: { policies: [] } },
    isLoading,
    isError,
    error,
  } = useQuery({
    queryKey: ["company_policy"],
    queryFn: fetchAllPolicies,
    refetchOnWindowFocus: false,
  });

  const policiesArray = Array.isArray(data?.policies) ? data.policies : [];

  const getShortDescription = (description) => {
    const words = description.split(" ");
    const firstThreeWords = words.slice(0, 3).join(" ");
    return firstThreeWords.length < description.length
      ? firstThreeWords + "..."
      : firstThreeWords;
  };

  const handleDownload = (fileUrl, fileName) => {
    // Create a link element dynamically
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileName; // Set the download filename
    link.click(); // Programmatically trigger the download
  };

  return (
    <div>
      <div className="card flex-fill policy-container">
        <div className="card-body" style={{padding:"17px 24px"}}>
          <div className="row align-items-center mb-3">
            {/* Title and "View All" */}
            <div className="col-12 position-relative">
              <h4 className="d-inline-block">Company Policy</h4>
              <Link
                to="/policies"
                className="position-absolute top-0 end-0 text-primary text-decoration-none"
                style={{ fontSize: "0.9rem" }}
              >
                View All
              </Link>
            </div>
          </div>

          {isLoading && <p>Loading policies...</p>}
          {isError && <p className="text-danger">Error: {error?.message}</p>}

          {!isLoading && !isError && (
            <div className="row">
              {policiesArray.length > 0 ? (
                policiesArray.slice(0, 2).map((policy, index) => {
                  const isFirstCard = index === 0;
                  const cardClass = isFirstCard ? "success" : "tertiary";
                  return (
                    <div className="col-md-6 mb-3" key={policy.id}>
                      <div className={`company-grid company-soft-${cardClass}`}>
                        <div className="company-top">
                          <div className="company-icon">
                            <span
                              className={`company-icon-${cardClass} rounded-circle`}
                            >
                              {policy.name.substring(0, 2).toUpperCase()}{" "}
                            </span>
                          </div>
                          <div className="company-link">
                            <Link to="/policies">{policy.name}</Link>
                          </div>
                        </div>
                        <div className="company-bottom d-flex">
                          <ul>
                            <li>
                              Description:{" "}
                              {getShortDescription(policy.description)}
                            </li>
                            <li>
                              Updated on:{" "}
                              {new Date(policy.updated_at).toLocaleDateString()}
                            </li>
                          </ul>
                          <a
                            to={policy.file}
                            onClick={(e) => {
                              e.preventDefault(); // Prevent navigation if using Link
                              handleDownload(policy.file, policy.name);
                            }}
                          >
                            <i className="la la-download" />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <p>No policies available</p>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default CompanyPolicy;
