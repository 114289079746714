import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'


// reports 

export const fetchReports = async()=> {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data}= await axiosInstance.get(`reports`)
    return data.data;
}


// Important Notifications 

export const fetchImportantNotifications = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`important-notifications`)
    return data.data;
}

// All Activities 

export const fetchAllActivities = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.get(`all-activities`)
    return data.data;
}

// Statics

export const fetchStatics = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{ data } = await axiosInstance.get(`statics`)
    return data.data;
}

// Emoployee of the month

export const fetchEmployeeMonth = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} = await axiosInstance.get(`employee-month`)
    return data.data;
}

// Upcoming Holidays

export const fetchUpcomingHolidays = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const {data} = await axiosInstance.get(`upcoming-holidays`)
    return data.data;
}