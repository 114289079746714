import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import * as bootstrap from "bootstrap"; // Import Bootstrap as a module

const AddUserModal = ({ createNewUser, roleData,employees,selectedEmployee,data }) => {
  const [eye, setEye] = useState(true);
  const [roles, setRoles] = useState([]);
  const [previouslyFocusedElement, setPreviouslyFocusedElement] = useState(null);

  const onEyeClick = () => setEye(prevEye => !prevEye);
  const employeeDetails = data?.map((emp) => ({
    value: emp?.id,
    label: (emp?.firstname || "") + " " + (emp?.lastname || ""),
  }));

  useEffect(() => {
    const data = roleData?.map((item) => ({
      value: item.id,
      label: item.name,
    }));
    setRoles(data);
  }, [roleData]);


  const validationSchema = Yup.object().shape({
    firstname: Yup.string().required("First Name is required"),
    lastname: Yup.string().required("Last Name is required"),
    email: Yup.string().email("Invalid email format").required("Email is required"),
    password: Yup.string()
      .required("Password is required")
      .matches(/(?=.*[A-Z])/, "Must contain an uppercase letter")
      .matches(/(?=.*[a-z])/, "Must contain a lowercase letter")
      .matches(/(?=.*[0-9])/, "Must contain a number")
      .matches(/(?=.*[!@#$%^&.,;":*])/, "Must contain a special character"),
    password_confirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Password confirmation is required"),
    phone: Yup.string().required("Phone is required"),
    role: Yup.string().required("Role is required"),
    company: Yup.string().required("Company is required"),
    employeeId: Yup.number().required("Employee ID is required").positive("Employee ID must be a positive number"),
  });

  const { control, handleSubmit,reset, setValue, formState: { errors, isSubmitting, isValid } } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      firstname: "",
      lastname: "",
      email: "",
      password: "",
      password_confirmation: "",
      phone: "",
      role: "",
      company: "",
      employeeId: "",
    },
    mode: "onChange",
  });

  const onSubmit = (data) => {
    createNewUser(data);
    reset()
  };

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const optionsTwo = [{ value: 1, label: "Xpert Prime" }];

  return (
    <div id="add_user" className="modal custom-modal fade" role="dialog">
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Add User</h5>
            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">×</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row">
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      First Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="firstname"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    {errors.firstname && <div className="text-danger">{errors.firstname.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Last Name <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="lastname"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    {errors.lastname && <div className="text-danger">{errors.lastname.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Employee ID <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="employeeId"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="number"
                          className="form-control"
                          {...field}
                          min="0"
                        />
                      )}
                    />
                    {errors.employeeId && <div className="text-danger">{errors.employeeId.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Email <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="email"
                          {...field}
                        />
                      )}
                    />
                    {errors.email && <div className="text-danger">{errors.email.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3" style={{ position: "relative" }}>
                    <label className="col-form-label">
                      Password <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="password"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type={eye ? "password" : "text"}
                          {...field}
                        />
                      )}
                    />
                    <span
                      onClick={onEyeClick}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "60%",
                        cursor: "pointer",
                      }}
                      className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    />
                    {errors.password && <div className="text-danger">{errors.password.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3" style={{ position: "relative" }}>
                    <label className="col-form-label">
                      Confirm Password <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="password_confirmation"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type={eye ? "password" : "text"}
                          {...field}
                        />
                      )}
                    />
                    <span
                      onClick={onEyeClick}
                      style={{
                        position: "absolute",
                        right: "15px",
                        top: "60%",
                        cursor: "pointer",
                      }}
                      className={`fa-solid ${eye ? "fa-eye-slash" : "fa-eye"}`}
                    />
                    {errors.password_confirmation && <div className="text-danger">{errors.password_confirmation.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Phone <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="phone"
                      control={control}
                      render={({ field }) => (
                        <input
                          className="form-control"
                          type="text"
                          {...field}
                        />
                      )}
                    />
                    {errors.phone && <div className="text-danger">{errors.phone.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Role <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="role"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Select Role"
                          onChange={(selectedOption) => setValue('role', selectedOption ? selectedOption.label : '')}
                          options={roles}
                          className="select floating"
                          styles={customStyles}
                          isClearable
                        />
                      )}
                    />
                    {errors.role && <div className="text-danger">{errors.role.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">
                      Company <span className="text-danger">*</span>
                    </label>
                    <Controller
                      name="company"
                      control={control}
                      render={({ field }) => (
                        <Select
                          placeholder="Select Company"
                          onChange={(selectedOption) => setValue('company', selectedOption ? selectedOption.label : '')}
                          options={optionsTwo}
                          className="select floating"
                          styles={customStyles}
                          isClearable
                        />
                      )}
                    />
                    {errors.company && <div className="text-danger">{errors.company.message}</div>}
                  </div>
                </div>
                <div className="col-sm-6">
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Select Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employee_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={employeeDetails}
                        placeholder="Select"
                        isMulti
                        // value={employeeDetails}
                        // defaultValue={employees[0]}
                        // styles={customStyles}
                      />
                    )}
                  />
                  {errors.employee && (
                    <p className="text-danger">{errors.employee.message}</p>
                  )}
                </div>
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                   data-bs-dismiss="modal" aria-label="Close"
                  type="submit"
                  disabled={!isValid || isSubmitting}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserModal;
