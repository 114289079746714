import React, { useState } from "react";
import { Link } from "react-router-dom";
import "react-datepicker/dist/react-datepicker.css";
import { MdOutlineSettingsBackupRestore } from "react-icons/md";
import { BsSearch } from "react-icons/bs";
import "../assets/css/searchBarButtons.css";
import { DatePicker } from "antd";
import dayjs from "dayjs";

const InputField = ({ label, children }) => (
  <div className="col-sm-3">
    <div className="input-block form-focus select-focus">
      {children}
      <label className="focus-label">{label}</label>
    </div>
  </div>
);

const MonthlyAttendanceFilter = ({ setInitialFilters }) => {
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [employeeName, setEmployeeName] = useState("");

  const handleMonthChange = (date, dateString) => {
    setSelectedMonth(dateString);
  };
  const handleNameChange = (e) => setEmployeeName(e.target.value);

  const handleSearch = () => {
    const month = selectedMonth ? selectedMonth : "";
    setInitialFilters({
      employee_name: employeeName,
      month: month,
    });
  };

  const handleReset = () => {
    setEmployeeName("");
    setSelectedMonth(null);
    setInitialFilters({
      employee_name: "",
      month: "",
      year: "",
    });
  };

  return (
    <div className="row filter-row">
      <InputField label="Employee Name">
        <input
          type="text"
          className="form-control floating"
          value={employeeName}
          onChange={handleNameChange}
        />
      </InputField>
      <InputField label="Select Month & Year">
        <DatePicker
          picker="month"
          format="MMMM YYYY"
          className="form-control floating datetimepicker"
          onChange={handleMonthChange}
          value={selectedMonth ? dayjs(selectedMonth, "MMMM YYYY") : null}
          placeholder="Select Month & Year"
        />
      </InputField>
      <div className="col-sm-2 mb-2 containerStyle">
        <Link to="#" onClick={handleSearch} className="me-2 linkStyle">
          <BsSearch className="iconStyle" />
        </Link>
        <Link to="#" onClick={handleReset} className="linkStyle">
          <MdOutlineSettingsBackupRestore className="iconStyle" />
        </Link>
      </div>
    </div>
  );
};

export default MonthlyAttendanceFilter;
