import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchAllAssets = async () => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.get(`assets`);
    return data.data; // Access the employee_queries array
};


export const addAssets = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'));
  
    // Post the formData to the backend
    const { data } = await axiosInstance.post('assets', payload, {
      headers: {
        'Content-Type': 'multipart/form-data', // Make sure the content type is set to multipart/form-data
      },
    });
  
    return data; // Return full response for detailed handling
  };
  

export const deleteAssets = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`assets?id=${id}`)
    return data?.data;
}

export const updateAssets = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`assets?id=${res.id}`,res)
    return data;
}
export const updateAssetsStatus = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`assets?id=${res.id}`,res)
    return data?.data;
}

