import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchHolidays } from "../../../../../hooks/useHoliday"; // Adjust the import based on your structure
import { formatDate } from "../../../../../assets/scss/utils/helpers/helper_functions"; // Assuming this function is used for formatting the date

const EmployeeUpcomingHolidays = () => {
  const [upcomingHoliday, setUpcomingHoliday] = useState(null);

  const { data, isLoading, error } = useQuery({
    queryKey: ["holidays"],
    queryFn: fetchHolidays,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (data) {
      const today = new Date();

      const upcoming = data
        .filter((holiday) => new Date(holiday.holiday_date) > today)
        .sort((a, b) => new Date(a.holiday_date) - new Date(b.holiday_date));

      if (upcoming.length > 0) {
        setUpcomingHoliday(upcoming[0]);
      }
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error loading holidays</div>;
  }

  if (!upcomingHoliday) {
    return (
      <div className="card info-card">
        <div className="card-body">
          <h4>Upcoming Holidays</h4>
          <div className="holiday-details">
            <p>No upcoming holidays available.</p>
            <div className="holiday-btn">
              <Link to="/holidays" className="btn">
                View All
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }

  // Format the date to display
  const holidayDate = new Date(upcomingHoliday.holiday_date);
  const formattedDate = formatDate(holidayDate);
  const dayOfWeek = holidayDate.getDay();
  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  const dayName = daysOfWeek[dayOfWeek];

  return (
    <div className="card info-card">
      <div className="card-body">
        <h4>Upcoming Holidays</h4>
        <div className="holiday-details">
          <div className="holiday-calendar">
            <div className="holiday-calendar-icon">
              <img
                src="https://i.ibb.co/0Bm8MWD/holiday-calendar-1.png"
                alt="Icon"
              />
            </div>
            <div className="holiday-calendar-content">
              <h6>{upcomingHoliday.name}</h6>
              <p>
                {dayName}, {formattedDate}
              </p>
            </div>
          </div>
          <div className="holiday-btn">
            <Link to="/holidays" className="btn">
              View All
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeUpcomingHolidays;
