import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import Breadcrumbs from "../../../components/Breadcrumbs";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import SearchBox from "../../../components/SearchBox";
import Spinnerloaders from "../../../components/SpinnerLoader";
import { fetchEmployeeID, searchEmployee } from "../../../hooks/useEmployee";
import {
  addEmployee,
  deleteEmployee,
  fetchAllEmployees,
  updateEmployeePost,
} from "../../../hooks/useEmployee";
import { useMutation, useQuery } from "@tanstack/react-query";

const EmployeeList = () => {
  const [isRefetching, setIsRefetching] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [editable, setEditable] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const { data, error, status, refetch } = useQuery({
    queryKey: ["allEmployees"],
    queryFn: fetchAllEmployees,
    refetchOnWindowFocus: false,
  });
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const { mutate: addEmployeeMutation } = useMutation({
    mutationFn: addEmployee,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
    },
  });
  const { mutate: updateEmployeeMutation } = useMutation({
    mutationFn: updateEmployeePost,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
    },
  });
  const { mutate: deleteEmployeeMutation } = useMutation({
    mutationFn: deleteEmployee,
    onSuccess: () => {
      setIsRefetching(true);
      refetch().then(() => setIsRefetching(false));
    },
  });
  // ============================Search employee ============================
  const {
    mutate: searchEmployeesMutation,
    isPending: searchEmployeeIsPending,
  } = useMutation({
    mutationFn: fetchEmployeeID,
    onSuccess: (data) => {
      // console.log(data)
      setSearchResults(data?.employees);
      setSuccess(true);
      setToastType({ label: "Search", type: "Updated" });
    },
  });
  const addEmployeeData = (data) => {
    addEmployeeMutation(data);
  };
  const updateEmployeeData = (data) => {
    updateEmployeeMutation(data);
  };
  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current);
  };
  const deleteEmployeeData = (data) => {
    deleteEmployeeMutation(data);
  };

  const searchEmployees = (filters) => {
    if (
      filters.employee_id ||
      filters.name ||
      filters.department ||
      filters.designation ||
      filters.status
    ) {
      searchEmployeesMutation(filters);
    } else {
      setSearchResults(null);
      refetch();
    }
  };

  useEffect(() => {
    setSearchResults(data?.employees)
  }, [data])
  
  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }
  const columns = [
    // {
    //   title: "Avatar",
    //   dataIndex: "avatar",
    //   render: (text, record) => (
    //     <span className="table-avatar">
    //       <Link to={`/profile?id=${record?.id}`} className="avatar">
    //       <img alt="" src={record?.avatar} />
    //       <p>{record?.firstname}</p>
    //       </Link>
    //       <Link to="/profile">
    //         {text} <span>{record.role}</span>
    //       </Link>
    //     </span>
    //   ),
    //   sorter: (a, b) => a?.name?.length - b?.name?.length,
    // },
    {
      title: "ID",
      dataIndex: "uuid",
      render: (text, record) => (
        <div className="table-avatar">
          <span>TXP-{record?.uuid}</span>
        </div>
      ),
      sorter: (a, b) => a?.uuid?.length - b?.uuid?.length,
    },
    {
      title: "Employee Name",
      dataIndex: "firstname",
      render: (text, record) => (
        <Link to={`/profile?id=${record?.id}`} className="">
          {record?.firstname} {record?.lastname}
        </Link>
      ),
      width: "30%",
      sorter: (a, b) => a?.firstname?.length - b?.firstname?.length,
    },

    {
      title: "Email",
      dataIndex: "email",
      sorter: (a, b) => a?.email?.length - b?.email?.length,
    },

    {
      title: "Mobile",
      dataIndex: "phone",
      sorter: (a, b) => a?.phone?.length - b?.phone?.length,
    },

    {
      title: "Department",
      dataIndex: "department",
      sorter: (a, b) => a?.department?.length - b?.department?.length,
      render: (_, record) => <span>{record?.department?.name}</span>,
    },

    // {
    //   title: "Designation",
    //   dataIndex: "designation",
    //   sorter: (a, b) => a?.designation?.length - b?.designation?.length,
    //   render: (_, record) => <span>{record?.designation?.name}</span>,
    // },

    // {
    //   title: "Join Date",
    //   dataIndex: "joining_date",
    //   sorter: (a, b) => a?.joining_date?.length - b?.joining_date?.length,
    // },
    // {
    //   title: "Role",
    //   sorter: true,
    //   render: () => (
    //     <div className="dropdown">
    //       <Link
    //         to="#"
    //         className="btn btn-white btn-sm btn-rounded dropdown-toggle"
    //         data-bs-toggle="dropdown"
    //         aria-expanded="false"
    //       >
    //         Web Developer{" "}
    //       </Link>
    //       <div className="dropdown-menu">
    //         <Link className="dropdown-item" to="#">
    //           Software Engineer
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           Software Tester
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           Frontend Developer
    //         </Link>
    //         <Link className="dropdown-item" to="#">
    //           UI/UX Developer
    //         </Link>
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Action",
      render: (_, record) => (
        <div className="dropdown dropdown-action text-center">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#edit_employee"
              onClick={() => {
                setSelectedEmployee(record);
                setEditable(true);
              }}
            >
              <i className="fa fa-pencil m-r-5" /> Edit
            </Link>
            <Link
              className="dropdown-item"
              to="#"
              data-bs-toggle="modal"
              data-bs-target="#delete"
              onClick={() => {
                setSelectedEmployee(record);
              }}
            >
              <i className="fa fa-trash m-r-5" /> Delete
            </Link>
          </div>
        </div>
      ),
      width: "7%",
    },
  ];
  return (
    <div>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <div
            onClick={() => {
              setSelectedEmployee({});
              setEditable(false);
            }}
          >
            <Breadcrumbs
              maintitle="Employee"
              title="Dashboard"
              subtitle="Employee"
              modal="#add_employee"
              name="Add Employee"
              Linkname="/employees"
              Linkname1="/employees-list"
            />
          </div>
          {/* /Page Header */}
          <EmployeeListFilter
            department={data?.department}
            designation={data?.designations}
            searchEmployees={searchEmployees}
          />
          <div className="row">
            <div className="col-md-12">
              <div className="table-responsive">
                <SearchBox />
                <Table
                  className="table-striped"
                  columns={columns}
                  dataSource={searchResults}
                  rowKey={(record) => record.id}
                  pagination={{ current: currentPage }}
                  onChange={handleTableChange}
                />
              </div>
              {isRefetching && <Spinnerloaders />}
            </div>
          </div>
        </div>
        {/* /Page Content */}
        <AllEmployeeAddPopup
          addEmployeeData={addEmployeeData}
          department={data?.department}
          designation={data?.designations}
          employee={selectedEmployee}
          edit={editable}
          updateEmployeeData={updateEmployeeData}
        />

        <DeleteModal
          deleteDepartment={deleteEmployeeData}
          id={selectedEmployee?.id}
          Name="Delete Employee"
        />
      </div>
    </div>
  );
};

export default EmployeeList;
