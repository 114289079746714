import React,{useState} from "react";
import { fetchPayslip } from "../../../../../hooks/useSalary";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { Applogo } from "../../../../../Routes/ImagePath";
import { headerlogoblack } from "../../../../../Routes/ImagePath";
import { useQuery,useMutation } from "@tanstack/react-query";

const PaySlip = () => {

  const {data,error,isLoading,isRefetch} = useQuery({
    queryKey:["payslip"],
    queryFn: fetchPayslip,
    refetchOnWindowFocus:false,
  }) 
  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Payslip"
          title="Dashboard"
          subtitle="Payslip"
          modal="#add_categories"
          name="Add Salary"
        />

        <div className="row">
          <div className="col-md-12">
            <div className="card">
              <div className="card-body">
                <h4 className="payslip-title">
                  Payslip for the month of Feb 2019
                </h4>
                <div className="row">
                  <div className="col-sm-6 m-b-20">
                    <img src={headerlogoblack} className="inv-logo" alt="Logo" />
                    <ul className="list-unstyled mb-0">
                      <li>Xpert Prime</li>
                      <li>43, Block L Johar Town</li>
                      <li>Lahore, Punjab 54600</li>
                    </ul>
                  </div>
                  <div className="col-sm-6 m-b-20">
                    <div className="invoice-details">
                      <h3 className="text-uppercase">Payslip #49029</h3>
                      <ul className="list-unstyled">
                        <li>
                          Salary Month: <span>March, 2019</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12 m-b-20">
                    <ul className="list-unstyled">
                      <li>
                        <h5 className="mb-0">
                          <strong className="employee-name">John Doe</strong>
                        </h5>
                      </li>
                      <li>
                        <span className="designation">Web Designer</span>
                      </li>
                      <li className="employee-id">Employee ID: TXP-0009</li>
                      <li className="joining-date">Joining Date: 1 Jan 2023</li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>Earnings</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Basic Salary</strong>{" "}
                              <span className="float-end">$6500</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>House Rent Allowance (H.R.A.)</strong>{" "}
                              <span className="float-end">$55</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Conveyance</strong>{" "}
                              <span className="float-end">$55</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Other Allowance</strong>{" "}
                              <span className="float-end">$55</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Earnings</strong>{" "}
                              <span className="float-end">
                                <strong>$55</strong>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-6">
                    <div>
                      <h4 className="m-b-10">
                        <strong>Deductions</strong>
                      </h4>
                      <table className="table table-bordered">
                        <tbody>
                          <tr>
                            <td>
                              <strong>Tax Deducted at Source (T.D.S.)</strong>{" "}
                              <span className="float-end">$0</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Provident Fund</strong>{" "}
                              <span className="float-end">$0</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>ESI</strong>{" "}
                              <span className="float-end">$0</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Loan</strong>{" "}
                              <span className="float-end">$300</span>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <strong>Total Deductions</strong>{" "}
                              <span className="float-end">
                                <strong>$59698</strong>
                              </span>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-sm-12">
                    <p>
                      <strong  className="net-salary">Net Salary: $59698</strong> (Fifty nine thousand
                      six hundred and ninety eight only.)
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaySlip;


// dynamic the data 

// import React from 'react';
// import { useQuery } from '@tanstack/react-query';
// import Breadcrumbs from '../../../../../components/Breadcrumbs';
// import { Applogo } from '../../../../../Routes/ImagePath';
// import { fetchSalary } from '../../../../../hooks/useSalary';

// const PaySlip = () => {
//   const { data, error, isLoading, isRefetch } = useQuery({
//     queryKey: ['payslip'],
//     queryFn: fetchSalary,
//     refetchOnWindowFocus: false,
//   });

//   if (isLoading) return <p>Loading...</p>;
//   if (error) return <p>Error loading payslips</p>;

//   return (
//     <div className="page-wrapper">
//       <div className="content container-fluid">
//         <Breadcrumbs
//           maintitle="Payslip"
//           title="Dashboard"
//           subtitle="Payslip"
//           modal="#add_categories"
//           name="Add Salary"
//         />

//         <div className="row">
//           <div className="col-md-12">
//             <div className="card">
//               <div className="card-body">
//                 <h4 className="payslip-title">Payslip for the month of February 2019</h4>

//                 <div className="row">
//                   <div className="col-sm-6 m-b-20">
//                     <img src={Applogo} className="inv-logo" alt="Logo" />
//                     <ul className="list-unstyled mb-0">
//                       <li>Dreamguy's Technologies</li>
//                       <li>3864 Quiet Valley Lane,</li>
//                       <li>Sherman Oaks, CA, 91403</li>
//                     </ul>
//                   </div>
//                   <div className="col-sm-6 m-b-20">
//                     <div className="invoice-details">
//                       <h3 className="text-uppercase">Payslip #49029</h3>
//                       <ul className="list-unstyled">
//                         <li>
//                           Salary Month: <span>March, 2019</span>
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="col-lg-12 m-b-20">
//                     <ul className="list-unstyled">
//                       <li>
//                         <h5 className="mb-0">
//                           <strong>{data.employees?.[0].firstname} {data.employees?.[0].lastname}</strong>
//                         </h5>
//                       </li>
//                       <li>
//                         <span>{data.employees?.[0].designation_id}</span>
//                       </li>
//                       <li>Employee ID: FT-0009</li>
//                       <li>Joining Date: {new Date(data.employees?.[0].joining_date).toLocaleDateString()}</li>
//                     </ul>
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="col-sm-6">
//                     <div>
//                       <h4 className="m-b-10">
//                         <strong>Earnings</strong>
//                       </h4>
//                       <table className="table table-bordered">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <strong>Basic Salary</strong> <span className="float-end">${data.employees?.[0].salary.net_salary}</span>
//                             </td>
//                           </tr>
//                           {/* Add other earnings if needed */}
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                   <div className="col-sm-6">
//                     <div>
//                       <h4 className="m-b-10">
//                         <strong>Deductions</strong>
//                       </h4>
//                       <table className="table table-bordered">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <strong>Total Deductions</strong> <span className="float-end"><strong>$59698</strong></span>
//                             </td>
//                           </tr>
//                           {/* Add other deductions if needed */}
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>
//                   <div className="col-sm-12">
//                     <p>
//                       <strong>Net Salary: ${data.employees?.[0].salary.net_salary}</strong> (Fifty-nine thousand six hundred and ninety-eight only.)
//                     </p>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaySlip;


// import React, { useEffect, useState } from "react";
// import { useQuery,} from "@tanstack/react-query";
// import Breadcrumbs from "../../../../../components/Breadcrumbs";
// import { Applogo, headerlogoblack } from "../../../../../Routes/ImagePath";
// import { fetchPayslip, fetchSalary } from "../../../../../hooks/useSalary";
// import { useParams,useNavigate } from "react-router-dom";

// const PaySlip = () => {
//   const { id } = useParams(); // Get the selected salary ID from the URL
//   const navigate = useNavigate();
//   const { data, error, isLoading } = useQuery({
//     queryKey: ["payslip", id],
//     queryFn: fetchSalary,
//     refetchOnWindowFocus: false,
//   });

//   const [employeeDetails, setEmployeeDetails] = useState(null);

//   useEffect(() => {
//     if (data && data.data) {
//       const employeeInfo = data.data["employee-salary"];
//       setEmployeeDetails(employeeInfo);
//     }
//   }, [data]);

//   if (isLoading) {
//     return <div>Loading...</div>; // Loading spinner or message
//   }

//   if (error) {
//     return <div>Error loading payslip details.</div>; // Handle error
//   }

//   if (!employeeDetails) {
//     return <div>No payslip details available.</div>;
//   }

//   const { firstname, lastname, designation, employee_id, joining_date, net_salary } = employeeDetails;

//   return (
//     <div className="page-wrapper">
//       <div className="content container-fluid">
//         <Breadcrumbs
//           maintitle="Payslip"
//           title="Dashboard"
//           subtitle="Payslip"
//           modal="#add_categories"
//           name="Add Salary"
//         />

//         <div className="row">
//           <div className="col-md-12">
//             <div className="card">
//               <div className="card-body">
//                 <h4 className="payslip-title">
//                   Payslip for the month of {new Date().toLocaleString("default", { month: "long" })} {new Date().getFullYear()}
//                 </h4>

//                 <div className="row">
//                   <div className="col-sm-6 m-b-20">
//                     <img src={headerlogoblack} className="inv-logo" alt="Logo" />
//                     <ul className="list-unstyled mb-0">
//                       <li>Xpert Prime</li>
//                       <li>43, Block L Johar Town</li>
//                       <li>Lahore, Punjab 54600</li>
//                     </ul>
//                   </div>
//                   <div className="col-sm-6 m-b-20">
//                     <div className="invoice-details">
//                       <h3 className="text-uppercase">Payslip #{employee_id}</h3>
//                       <ul className="list-unstyled">
//                         <li>
//                           {/* Salary Month: <span>{new Date(employeeDetails.salary?.updated_at).toLocaleDateString("default", { month: "long" })}, {new Date(employeeDetails.salary.updated_at).getFullYear()}</span> */}
//                         </li>
//                       </ul>
//                     </div>
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="col-lg-12 m-b-20">
//                     <ul className="list-unstyled">
//                       <li>
//                         <h5 className="mb-0">
//                           <strong className="employee-name">{firstname} {lastname}</strong>
//                         </h5>
//                       </li>
//                       <li>
//                         <span className="designation">{designation}</span>
//                       </li>
//                       <li className="employee-id">Employee ID: {employee_id}</li>
//                       <li className="joining-date">Joining Date: {new Date(joining_date).toLocaleDateString()}</li>
//                     </ul>
//                   </div>
//                 </div>

//                 <div className="row">
//                   <div className="col-sm-6">
//                     <div>
//                       <h4 className="m-b-10">
//                         <strong>Earnings</strong>
//                       </h4>
//                       <table className="table table-bordered">
//                         <tbody>
//                           <tr>
//                             <td>
//                               <strong>Net Salary</strong> <span className="float-end">${net_salary}</span>
//                             </td>
//                           </tr>
//                         </tbody>
//                       </table>
//                     </div>
//                   </div>

//                   {/* Add other tables for Allowances and Deductions if needed */}
//                 </div>

//                 <div className="col-sm-12">
//                   <p>
//                     <strong className="net-salary">Net Salary: ${net_salary}</strong> (Fifty nine thousand six hundred and ninety-eight only.)
//                   </p>
//                 </div>

//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default PaySlip;

