import React from "react";
import { Link } from "react-router-dom";

const DeleteAlertPopup = ({ name, alertData, deleteAlert }) => {
  return (
    <div className="modal custom-modal fade" id="delete" role="dialog">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-body">
            <div className="form-header">
              <h3>{name}</h3>
              <p>Are you sure you want to delete this alert?</p>
            </div>
            <div className="modal-btn delete-action">
              <div className="row">
                <div className="col-6">
                  <Link
                    to="#"
                    onClick={() => {
                      deleteAlert(alertData);
                    }}
                    data-bs-dismiss="modal"
                    className="btn btn-primary continue-btn"
                  >
                    Delete
                  </Link>
                </div>
                <div className="col-6">
                  <Link
                    to="#"
                    data-bs-dismiss="modal"
                    className="btn btn-primary cancel-btn"
                  >
                    Cancel
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DeleteAlertPopup;
