import React from "react";
import { useQuery } from "@tanstack/react-query";
import { fetchAllEmployeesLeave } from "../../../../../hooks/useEmployeeLeave";

const NotificationAlert = () => {
  const { data, refetch, isLoading } = useQuery({
    queryKey: ["employee_leave"],
    queryFn: fetchAllEmployeesLeave,
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (data?.leaves && data.leaves.length > 0) {
    const latestLeave = [...data.leaves].sort(
      (a, b) => new Date(b.created_at) - new Date(a.created_at)
    )[0];

    return (
      <div>
        <div className="col-md-12">
          <div className="employee-alert-box">
            <div className="alert alert-outline-success alert-dismissible fade show">
              <div className="employee-alert-request">
                <i className="far fa-circle-question" />
                <p className="static">
                  Your Leave Request on{" "}
                  <span>
                    {new Date(latestLeave.created_at).toLocaleDateString()}
                  </span>{" "}
                  has been
                  {latestLeave.status === "Approved" ? " Approved" : " Pending"}
                  !!!
                </p>
              </div>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              >
                <i className="fas fa-xmark" />
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return <div>No leave requests available.</div>;
};

export default NotificationAlert;
