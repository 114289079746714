/* eslint-disable no-undef */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Table } from "antd";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  fetchEmployeePerformanceDetail,
  addPerformanceIndicator,
  deletePerformanceIndicator,
  updatePerformanceIndicator,
} from "../../../../hooks/usePerformanceIndicator";
import SearchBox from "../../../../components/SearchBox";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import PermissionWrapper from "../../../../authorization/PermissionWrapper";
import PerformanceIndicatorModal from "../../../../components/modelpopup/PerformanceIndicatorModal";
import DeleteModal from "../../../../components/modelpopup/DeleteModal";

const PerformanceIndicator = () => {
  const [deleteId, setDeleteId] = useState(null); // State for ID to delete
  const [employeeId, setEmployeeId] = useState(109); // Example employee ID, replace as needed
  const [isRefetching, setIsRefetching] = useState(false);
  const [toastType, setToastType] = useState("");

  // Fetch employee performance details
  const { data, isLoading, isError, error, refetch } = useQuery({
    queryKey: ["performance_indicator", employeeId],
    queryFn: () => fetchEmployeePerformanceDetail(employeeId),
    enabled: !!employeeId, // Query only runs if employeeId exists
    refetchOnWindowFocus: false,
  });

  // Mutations for adding, updating, and deleting indicators
  const { mutate: addPerformanceIndicatorMutation } = useMutation({
    mutationFn: addPerformanceIndicator,
    onSuccess: () => refetch(),
    onError: (error) => setToastType({ label: error.message, type: "Error" }),
  });

  const { mutate: updatePerformanceIndicatorMutation } = useMutation({
    mutationFn: updatePerformanceIndicator,
    onSuccess: () => refetch(),
    onError: (error) => setToastType({ label: error.message, type: "Error" }),
  });

  const { mutate: deletePerformanceIndicatorMutation } = useMutation({
    mutationFn: deletePerformanceIndicator,
    onSuccess: () => refetch(),
    onError: (error) => setToastType({ label: error.message, type: "Error" }),
  });

  const handleDelete = () => {
    if (deleteId) {
      deletePerformanceIndicatorMutation(deleteId);
      setDeleteId(null);
    }
  };

  const columns = [
    {
      title: "#",
      dataIndex: "id",
      sorter: (a, b) => a.id - b.id,
    },
    {
      title: "Employee ID",
      dataIndex: "employee_id",
      sorter: (a, b) => a.employee_id - b.employee_id,
    },
    {
      title: "Metric Name",
      dataIndex: ["metric", "name"],
      render: (text) => text || "N/A",
      sorter: (a, b) => a.metric?.name?.localeCompare(b.metric?.name),
    },
    {
      title: "Metric Category",
      dataIndex: ["metric", "category"],
      render: (text) => text || "N/A",
      sorter: (a, b) => a.metric?.category?.localeCompare(b.metric?.category),
    },
    {
      title: "Score",
      dataIndex: "score",
      sorter: (a, b) => a.score - b.score,
    },
    {
      title: "Feedback",
      dataIndex: "feedback",
      render: (text) => text || "No Feedback",
    },
    {
      title: "Created At",
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      render: (text) => new Date(text).toLocaleString(),
    },
    {
      title: "Action",
      render: (record) => (
        <div className="dropdown dropdown-action text-end">
          <Link
            to="#"
            className="action-icon dropdown-toggle"
            data-bs-toggle="dropdown"
            aria-expanded="false"
          >
            <i className="material-icons">more_vert</i>
          </Link>
          <div className="dropdown-menu dropdown-menu-right">
            <PermissionWrapper permissionKey={"edit-performance-matrix"}>
              <Link className="dropdown-item" to="/performance">
                <i className="fa fa-eye m-r-5" /> View/Edit
              </Link>
            </PermissionWrapper>
            <PermissionWrapper permissionKey={"delete-performance-matrix"}>
              <Link
                className="dropdown-item"
                to="#"
                data-bs-toggle="modal"
                data-bs-target="#delete"
                onClick={() => setDeleteId(record.id)}
              >
                <i className="fa fa-trash m-r-5" /> Delete
              </Link>
            </PermissionWrapper>
          </div>
        </div>
      ),
    },
  ];

  if (isLoading) return <p>Loading...</p>;
  if (isError) return <p>Error: {error.message}</p>;

  return (
    <div className="page-wrapper">
      <div className="content container-fluid">
        <Breadcrumbs
          maintitle="Performance Indicator"
          title="Dashboard"
          subtitle="Performance"
          Link="/performance"
          name="Add Performance"
          permissionKey="add-performance-matrix"
        />
        <div className="row">
          <div className="col-md-12">
            <div className="table-responsive">
              <SearchBox />
              <Table
                className="table-striped custom-table"
                style={{ overflowX: "auto" }}
                columns={columns}
                dataSource={data || []}
                rowKey={(record) => record.id}
              />
            </div>
          </div>
        </div>
      </div>
      <PerformanceIndicatorModal />
      <DeleteModal
        deleteDepartment={handleDelete}
        id={deleteId}
        Name="Delete Performance Indicator List"
      />
    </div>
  );
};

export default PerformanceIndicator;
