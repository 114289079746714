import { axiosInstance } from "./axios";
import { setAuthenticationToken } from "./auth";

// =============Fetch Allowances & Deductions==================

export const fetchAllowancesDeductions = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} =await axiosInstance.get(`deduction-allowances`)
    return data;
}

// =============Employee Salary==================

export const fetchSalary = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} =await axiosInstance.get(`salary`)
    return data;
}

export const addSalary = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const {data} =await axiosInstance.post(`salary`,payload)
    return data;
}

export const updateSalary = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} = await axiosInstance.put(`salary`,res)
    return data?.data;
}
export const deleteSalary = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.delete(`salary?id=${id}`);
    return data;
}


// ================PaySlip====================

export const fetchPayslip = async () => {
    setAuthenticationToken(localStorage.getItem('token'))
    const{data} = await axiosInstance.get(`payslip`)
    return data.data;
}