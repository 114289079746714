import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchActivities = async () => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.get(`activities`);
    return data.data; // Access the employee_queries array
};


export const addActivities = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.post(`activities`, payload);
    return data; // Return full response for detailed handling
  };
  

export const deleteActivities = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`activities?id=${id}`)
    return data?.data;
}

export const updateActivities = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`activities`,res)
    return data?.data;
}


