import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import AllEmployeeAddPopup from "../../../components/modelpopup/AllEmployeeAddPopup";
import Breadcrumbs from "../../../components/Breadcrumbs";
import DeleteModal from "../../../components/modelpopup/DeleteModal";
import EmployeeListFilter from "../../../components/EmployeeListFilter";
import RolesPermissions from "../Administration/Settings/RolesPermissions";
import SalarySettings from "../Administration/Settings/SalarySettings";
import {
  addEmployee,
  deleteEmployee,
  fetchAllEmployees,
  fetchEmployeeID,
  searchEmployee,
  updateEmployeePost,
} from "../../../hooks/useEmployee";
import { useMutation, useQuery } from "@tanstack/react-query";
import InfoToast from "../../layout/InfoToast";
import { fetchDesignations } from "../../../hooks/useDesignation";
import { fetchShift } from "../../../hooks/useShift";
import GlobalLoader from "../../layout/GlobalLoader";

const AllEmployee = () => {
  const [selectedEmployee, setSelectedEmployee] = useState({});
  const [isRefetching, setIsRefetching] = useState(false);
  const [editable, setEditable] = useState(false);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState("");
  const [searchResults, setSearchResults] = useState(null);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setSuccess(false);
    }, 5000);
    return () => clearTimeout(timeoutId);
  }, [success]);

  const handleSolidPrimaryToastClose = () => {
    setSuccess(false);
  };

  // ==================================Get Shift data================================
  const { data: shiftData } = useQuery({
    queryKey: ["shifts"],
    queryFn: fetchShift,
    refetchOnWindowFocus: false,
  });

  // ==================================Get Designations data================================
  const {
    data: design,
  } = useQuery({
    queryKey: ["designations"],
    queryFn: fetchDesignations,
    refetchOnWindowFocus: false,
  });

  // ==================================Get Employee data================================
  const { data, error, status, refetch, isLoading } = useQuery({
    queryKey: ["allEmployees"],
    queryFn: fetchAllEmployees,
    refetchOnWindowFocus: false,
  });

  // ==================================Add Employee data================================
  const { mutate: addEmployeeMutation, isPending } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: addEmployee,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({ label: "Employee Added", type: "Added" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: error?.response?.data?.message || error?.message || "An Error Occurred While Adding Employee",
        type: "Error",
      });
    },
  });

  const addEmployeeData = (data) => {
    addEmployeeMutation(data);
  };

  // ==================================Update Employee data================================
  const { mutate: updateEmployeeMutation, isPending: updateIsPending } = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: updateEmployeePost,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({ label: "Employee Updated", type: "Updated" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: error?.response?.data?.message || error?.message || "An Error Occurred While Updating Employee",
        type: "Error",
      });
    },
  });

  const updateEmployeeData = (data) => {
    updateEmployeeMutation(data);
  };

  // ==================================Delete Employee data================================
  const { mutate: deleteEmployeeMutation, isPending:  deleteEmployeeIsPending} = useMutation({
    onMutate: () => {
      setIsRefetching(true);
    },
    mutationFn: deleteEmployee,
    onSuccess: () => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({ label: "Employee Deleted", type: "Deleted" });
    },
    onError: (error) => {
      refetch()
        .then(() => setIsRefetching(false))
        .then(setSuccess(true));
      setToastType({
        label: error?.response?.data?.message || error?.message || "An Error Occurred While Deleting Employee",
        type: "Error",
      });
    },
  });

  const deleteEmployeeData = (data) => {
    deleteEmployeeMutation(data);
  };

  // ============================Search employee ============================
  const { mutate: searchEmployeesMutation, isPending:  searchEmployeeIsPending} = useMutation({
    mutationFn: fetchEmployeeID,
    onSuccess: (data) => {
      setSearchResults(data);
      setSuccess(true);
      setToastType({ label: "Search", type: "Updated" });
    },
  });

  const predefinedStatusOptions = [
    { value: "pending", label: "Probation" },
    { value: "active", label: "Active" },
    { value: "terminated", label: "Terminated" },
    { value: "resigned", label: "Resigned" },
    { value: "onleave", label: "On Leave" },
    { value: "suspended", label: "Suspended" },
    { value: "probationary", label: "Probationary" },
    { value: "contract", label: "Contract" },
    { value: "retired", label: "Retired" },
    { value: "inactive", label: "Inactive" },
  ];
  
  // Merge predefined statuses with dynamic ones from the API (if available)
  const mergedStatusOptions = [
    ...new Set([
      ...(data?.status?.map((item) => ({ value: item.value, label: item.label })) || []),
      ...predefinedStatusOptions,
    ]),
  ];
  

  const searchEmployees = (filters) => {
    if (
      filters.employee_id ||
      filters.name ||
      filters.department ||
      filters.designation ||
      filters.status
    ) {
      searchEmployeesMutation(filters);
    } else {
      setSearchResults(null);
      refetch();
    }
  };
  if (isLoading || isPending || updateIsPending || deleteEmployeeIsPending || searchEmployeeIsPending) {
    return <GlobalLoader />
  }
  if (status === "error") {
    return (
      <div className="page-wrapper">
        <span className="content container-fluid">Error: {error.message}</span>
      </div>
    );
  }

  const employeesToDisplay = searchResults?.employees || data?.employees;
//   const employeeStatus = data.employees[0].status;
//   console.log('status:', employeeStatus); 
// console.log('designations:', data?.designations);
// console.log('departments:', data?.department);


  return (
    <div>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div
            onClick={() => {
              setSelectedEmployee({});
              setEditable(false);
            }}
          >
            <Breadcrumbs
              maintitle="Employee"
              title="Dashboard"
              subtitle="All Employees"
              modal="#add_employee"
              name="Add Employee"
              Linkname="/employees"
              Linkname1="/employees-list"
            />
          </div>
          <EmployeeListFilter
        //  status={mergedStatusOptions}
            designation={data?.designations}
            department={data?.department}
            searchEmployees={searchEmployees}
          />

          <div className="row">
            {employeesToDisplay?.map((employee) => (
              <div
                className="col-md-4 col-sm-6 col-12 col-lg-4 col-xl-3"
                key={employee.id}
              >
                <div className="profile-widget">
                  <div className="profile-img">
                    <Link to={`/profile?id=${employee?.id}`} className="avatar">
                      <img src={employee.avatar} alt="employee" />
                    </Link>
                  </div>
                  <div className="dropdown profile-action">
                    <Link
                      to="#"
                      className="action-icon dropdown-toggle"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      <i className="material-icons">more_vert</i>
                    </Link>
                    <div className="dropdown-menu dropdown-menu-right">
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#edit_employee"
                        onClick={() => {
                          setSelectedEmployee(employee);
                          setEditable(true);
                        }}
                      >
                        <i className="fa fa-pencil m-r-5" /> Edit
                      </Link>
                      <Link
                        className="dropdown-item"
                        to="#"
                        data-bs-toggle="modal"
                        data-bs-target="#delete"
                        onClick={() => {
                          setSelectedEmployee(employee);
                        }}
                      >
                        <i className="fa-regular fa-trash-can m-r-5" /> Delete
                      </Link>
                    </div>
                  </div>
                  <Link to={`/profile?id=${employee?.id}`}>
                    <h4 className="user-name m-t-10 mb-0 text-ellipsis">
                      {(employee?.firstname || "") +
                        " " +
                        (employee?.lastname || "")}
                    </h4>
                    <div className="small text-muted">
                      TXP - {employee?.uuid}
                    </div>
                    <div className="small text-muted">
                      {employee?.designation?.name}
                    </div>
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <AllEmployeeAddPopup
        addEmployeeData={addEmployeeData}
        department={design?.department}
        designation={design?.designations}
        employee={selectedEmployee}
        edit={editable}
        updateEmployeeData={updateEmployeeData}
        shiftData={shiftData}
      />
      {/* Delete Modal */}
      <DeleteModal
        deleteDepartment={deleteEmployeeData}
        id={selectedEmployee?.id}
        Name="Delete Employee"
      />
      {/* Delete Modal */}
      {success && (
        <InfoToast
          success={success}
          handleClose={handleSolidPrimaryToastClose}
          toastType={toastType}
        />
      )}
    </div>
  );
};

export default AllEmployee;
