import { axiosInstance } from './axios';
import { setAuthenticationToken } from './auth';

// ============== fetch employee performance details ====================

export const fetchEmployeePerformanceDetail = async (employee_id) => {
  setAuthenticationToken(localStorage.getItem('token'));
  
  if (!employee_id) {
    throw new Error("Employee ID is required");
  }

  try {
    const { data } = await axiosInstance.get(`employee-performance-detail?employee_id=${employee_id}`);
    
    if (!data.data || data.data.length === 0) {
      throw new Error(data.message || "Employee not found");
    }

    return data.data; // Return the performance details array
  } catch (error) {
    // Re-throw the error with a user-friendly message
    throw new Error(error.response?.data?.message || "Failed to fetch employee performance details");
  }
};

export const fetchEmployeePerformance = async () => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.get(`employee-performance`);
    return data.data; // Access the employee_queries array
};


export const addPerformanceIndicator = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.post(`performance-indicator`, payload);
    return data; // Return full response for detailed handling
  };
  

export const deletePerformanceIndicator = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`performance-indicator?id=${id}`)
    return data?.data;
}

export const updatePerformanceIndicator = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`performance-indicator`,res)
    return data?.data;
}
export const updatePerformanceIndicatorStatus = async (res) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.put(`Performance Indicator?id=${res.id}`,res)
    return data?.data;
}

