import React, { useEffect, useState } from "react";
import axios from "axios";
import Reports from "./Reports";
import Statistics from "./statistics";
import Breadcrumbs from "../../../../../components/Breadcrumbs";
import { base_url } from "../../../../../base_urls";
import ImportantNotifications from "./importantNotifications";
import AllActivities from "./allActivities";
import EmployeeOfMonth from "./employeeOfMonth";
import UpcomingHolidays from "./upcomingHolidays";

const AdminDashboard = () => {
  const [users, setUsers] = useState([]);
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  useEffect(() => {
    axios.get(base_url + "/api/dash.json").then((res) => setUsers(res.data));
  }, []);

  return (
    <div className="main-wrapper">
      <div className="page-wrapper">
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs maintitle="Welcome Admin!" title="Dashboard" />
          <Reports />

          <div className="row ">
            <div className="col-md-5">
              <ImportantNotifications />
            </div>
            <div className="col-md-7">
              <AllActivities />
            </div>
          </div>

          <Statistics />

          <div className="row">
            <div className="col-md-6">
              <EmployeeOfMonth />
            </div>
            <div className="col-md-6">
              <UpcomingHolidays />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminDashboard;
