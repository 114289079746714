// import React from 'react';
// import { Link } from 'react-router-dom';

// const importantNotifications = () => {
//   return (
  
//     <div>
//       {/* <div className="row"> */}
//          <div className="card flex-fill">
//                 <div className="card-body">
//                   <div className="statistic-header">
//                     <h4>Important</h4>
//                     <div className="important-notification">
//                       {/* <Link to="/activities">
//                         <span className="me-1">View All</span>
//                         <ArrowRightCircle size={15} />
//                       </Link> */}
//                     </div>
//                   </div>
//                   <div className="notification-tab">
//                     <ul className="nav nav-tabs">
//                       <li>
//                         <Link
//                           to="#"
//                           className="active"
//                           data-bs-toggle="tab"
//                           data-bs-target="#notification_tab"
//                         >
//                           <i className="la la-bell" /> Notifications
//                         </Link>
//                       </li>
//                       <li>
//                         <Link
//                           to="#"
//                           data-bs-toggle="tab"
//                           data-bs-target="#schedule_tab"
//                         >
//                           <i className="la la-list-alt" /> Schedules
//                         </Link>
//                       </li>
//                     </ul>
//                     <div className="tab-content">
//                       <div className="tab-pane active" id="notification_tab">
//                         <div className="employee-noti-content">
//                           <ul className="employee-notification-list">
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-danger rounded-circle">
//                                     HR
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Your leave request has been
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>02:10 PM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-info rounded-circle">
//                                     ER
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     You’re enrolled in upcom....
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>12:40 PM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-warning rounded-circle">
//                                     SM
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Your annual compliance trai
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>11:00 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="rounded-circle">
//                                     <img
//                                       //   src={avatar1}
//                                       className="img-fluid rounded-circle"
//                                       alt="User"
//                                     />
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Jessica has requested feedba
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>10:30 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-warning rounded-circle">
//                                     DT
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Gentle remainder about train
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>09:00 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-danger rounded-circle">
//                                     AU
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Our HR system will be down
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>11:50 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                       <div className="tab-pane fade" id="schedule_tab">
//                         <div className="employee-noti-content">
//                           <ul className="employee-notification-list">
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="rounded-circle">
//                                     <img
//                                       //   src={avatar2}
//                                       className="img-fluid rounded-circle"
//                                       alt="User"
//                                     />
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     John has requested feedba
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>10:30 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-danger rounded-circle">
//                                     HR
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Your leave request has been
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>02:10 PM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-info rounded-circle">
//                                     ER
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     You’re enrolled in upcom....
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>12:40 PM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-warning rounded-circle">
//                                     SM
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Your annual compliance trai
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>11:00 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-warning rounded-circle">
//                                     DT
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Gentle remainder about train
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>09:00 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                             <li className="employee-notification-grid">
//                               <div className="employee-notification-icon">
//                                 <Link to="/activities">
//                                   <span className="badge-soft-danger rounded-circle">
//                                     AU
//                                   </span>
//                                 </Link>
//                               </div>
//                               <div className="employee-notification-content">
//                                 <h6>
//                                   <Link to="/activities">
//                                     Our HR system will be down
//                                   </Link>
//                                 </h6>
//                                 <ul className="nav">
//                                   <li>11:50 AM</li>
//                                   <li>21 Apr 2024</li>
//                                 </ul>
//                               </div>
//                             </li>
//                           </ul>
//                         </div>
//                       </div>
//                     </div>
//                   </div>
//                 </div>
//               </div>
            
//     </div>
//     // </div>
//   )
// }

// export default importantNotifications;


// dynamic notifications 


import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query"; // React Query hook
import { fetchImportantNotifications } from "../../../../../hooks/useAdminDashboard"; // Import your API function

const demoNotifications = [
  {
    id: 1,
    type: "danger",
    category: "HR",
    title: "Your leave request has been approved",
    time: "02:10 PM",
    date: "2024-04-21",
  },
  {
    id: 2,
    type: "info",
    category: "ER",
    title: "You’re enrolled in upcoming training",
    time: "12:40 PM",
    date: "2024-04-21",
  },
  {
    id: 3,
    type: "info",
    category: "ER",
    title: "You’re enrolled in upcoming training",
    time: "12:40 PM",
    date: "2024-04-21",
  },
  {
    id: 4,
    type: "info",
    category: "ER",
    title: "You’re enrolled in upcoming training",
    time: "12:40 PM",
    date: "2024-04-21",
  },
  {
    id: 5,
    type: "warning",
    category: "SM",
    title: "Your annual compliance training is due",
    time: "11:00 AM",
    date: "2024-04-21",
  },
  {
    id: 6,
    type: "success",
    category: "AU",
    title: "HR system maintenance scheduled",
    time: "09:00 AM",
    date: "2024-04-21",
  },
  {
    id: 7,
    type: "success",
    category: "AU",
    title: "HR system maintenance scheduled",
    time: "09:00 AM",
    date: "2024-04-21",
  },
  {
    id: 7,
    type: "success",
    category: "AU",
    title: "HR system maintenance scheduled",
    time: "09:00 AM",
    date: "2024-04-21",
  },
];

const ImportantNotifications = () => {
  const {
    data: notifications = demoNotifications, // Use demoNotifications as fallback
    error,
    status,
    isLoading,
  } = useQuery({
    queryKey: ["important_notifications"],
    queryFn: fetchImportantNotifications,
    initialData: demoNotifications, // Set initial data
    refetchOnWindowFocus: false,
  });

  // Get the 6 latest notifications sorted by date
  const latestNotifications = [...notifications]
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, 6);

  return (
    <div>
      <div className="card flex-fill" style={{ height: "610px" }}>
        <div className="card-body">
          <div className="statistic-header">
            <h4>Employee on Leaves</h4>
            <div className="important-notification"></div>
          </div>
          <div className="notification-tab">
            <ul className="nav nav-tabs">
              <li>
                <Link
                  to="#"
                  className="active"
                  data-bs-toggle="tab"
                  data-bs-target="#notification_tab"
                >
                  <i className="la la-bell" />Leave Notifications
                </Link>
              </li>
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="notification_tab">
                <div className="employee-noti-content">
                  {isLoading && <p>Loading notifications...</p>}
                  {status === "error" ? (
                    <p>Failed to load notifications: {error?.message}</p>
                  ) : (
                    <ul className="employee-notification-list">
                      {latestNotifications.map((notification) => (
                        <li
                          key={notification.id}
                          className="employee-notification-grid"
                        >
                          <div className="employee-notification-icon">
                            <Link to="/activities">
                              <span
                                className={`badge-soft-${
                                  notification.type || "info"
                                } rounded-circle`}
                              >
                                {notification.category || "N/A"}
                              </span>
                            </Link>
                          </div>
                          <div className="employee-notification-content">
                            <h6>
                              <Link to="/activities">
                                {notification.title}
                              </Link>
                            </h6>
                            <ul className="nav">
                              <li>{notification.time}</li>
                              <li>{notification.date}</li>
                            </ul>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="tab-pane fade" id="schedule_tab">
                <p>Schedules tab content here...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImportantNotifications;
