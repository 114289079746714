import { axiosInstance } from './axios'
import { setAuthenticationToken } from './auth'

export const fetchAllAttachments = async () => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.get(`attachments`);
    return data.data; 
};


export const addAttachments = async (payload) => {
    setAuthenticationToken(localStorage.getItem('token'));
    const { data } = await axiosInstance.post(`attachments`, payload);
    return data; // Return full response for detailed handling
  };
  

export const deleteAttachments = async (id) => {
    setAuthenticationToken(localStorage.getItem('token'))
    const { data } = await axiosInstance.delete(`attachments?id=${id}`)
    return data?.data;
}



