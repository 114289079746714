// import React from "react";
// import { Link } from "react-router-dom";
// import AddActivity from "../../../../../components/modelpopup/Crm/AddActivity";

// const allActivities = () => {
//   return (
//     <div>
//       {/* <div className="row"> */}
//       <div className="card analytics-card">
//         <div className="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3">
//           <h3>
//             Activities
//             <Link to="#">
//               <i className="la la-link" />
//             </Link>
//           </h3>
//           <div className="title-head d-flex">
//             {/* <div className="input-block mb-0">
//               <Select
//                 className="select"
//                 options={sortoption}
//                 placeholder="Sales Pipeline"
//                 styles={customStyles}
//               />
//             </div> */}
//             <Link to="/activities">
//               <span className="me-1">View All</span>
//               {/* <ArrowRightCircle size={15} /> */}
//             </Link>
//             {/* <Link
//               to="/activities"
//               className="btn add-btn"
//               // data-bs-toggle="modal"
//               // data-bs-target="#add_activity"
//             >
//               <i className="la la-eye" /> View Activities
//             </Link> */}
//           </div>
//         </div>
//         <div className="card-body">
//           <div className="activities-list">
//             <ul>
//               <li>
//                 <div className="row d-flex justify-content-between">
//                   <div className="col-md-5">
//                     <div className="activity-name">
//                       <h5>We scheduled a meeting for next week</h5>
//                       <p>Due Date : 10 Feb 2024, 09:00 am </p>
//                       <span className="badge activity-badge badge-bg-violet">
//                         <i className="la la-user-tie" />
//                         Meeting
//                       </span>
//                     </div>
//                   </div>
//                   <div className="col-md-2">
//                     <div className="user-activity">
//                       <span>
//                         <img
//                           // src={avatar19}
//                           alt="Img"
//                         />
//                       </span>
//                       <h6>Darlee Robertson</h6>
//                     </div>
//                   </div>
//                   {/* <div className="col-md-4">
//                     <div className="input-block mb-0">
//                       <Select
//                         className="select"
//                         // options={status}
//                         placeholder="Inporgress"
//                         styles={customStyles}
//                       />
//                     </div>
//                   </div> */}
//                 </div>
//               </li>
//               <li>
//                 <div className="row d-flex justify-content-between">
//                   <div className="col-md-5">
//                     <div className="activity-name">
//                       <h5>Built landing pages</h5>
//                       <p>Due Date : 12 Mar 2024, 08:30 am</p>
//                       <span className="badge activity-badge badge-bg-warning">
//                         <i className="la la-envelope" />
//                         Email
//                       </span>
//                     </div>
//                   </div>
//                   <div className="col-md-2">
//                     <div className="user-activity">
//                       <span>
//                         <img
//                           // src={avatar16}
//                           alt="Img"
//                         />
//                       </span>
//                       <h6>Carol Thomas</h6>
//                     </div>
//                   </div>
//                   {/* <div className="col-md-4">
//                     <div className="input-block mb-0">
//                       <Select
//                         className="select"
//                         options={status1}
//                         placeholder="Inporgress"
//                         styles={customStyles}
//                       />
//                     </div>
//                   </div> */}
//                 </div>
//               </li>
//               {/* <li>
//                 <div className="row d-flex justify-content-between">
//                   <div className="col-md-5">
//                     <div className="activity-name">
//                       <h5>Call John and discuss about project</h5>
//                       <p>Due Date : 20 Apr 2024, 03:15 pm</p>
//                       <span className="badge activity-badge badge-bg-success">
//                         <i className="la la-phone" />
//                         Calls
//                       </span>
//                     </div>
//                   </div>
//                   <div className="col-md-2">
//                     <div className="user-activity">
//                       <span>
//                         <img
//                           // src={avatar20}
//                           alt="Img"
//                         />
//                       </span>
//                       <h6>Sharon Roy</h6>
//                     </div>
//                   </div> */}
//                   {/* <div className="col-md-4">
//                     <div className="input-block mb-0">
//                       <Select
//                         className="select"
//                         options={status2}
//                         placeholder="Inporgress"
//                         styles={customStyles}
//                       />
//                     </div>
//                   </div> */}
//                 {/* </div>
//               </li> */}
            
//               <li>
//               <div className="row d-flex justify-content-between">
//                   <div className="col-md-5">
//                     <div className="activity-name">
//                       <h5>Regarding latest updates in project</h5>
//                       <p>Due Date : 25 May 2024, 02:10 pm </p>
//                       <span className="badge activity-badge badge-bg-violet">
//                         <i className="la la-user-tie" />
//                         Meeting
//                       </span>
//                     </div>
//                   </div>
//                   <div className="col-md-3">
//                     <div className="user-activity">
//                       <span>
//                         <img
//                           // src={avatar24}
//                           alt="Img"
//                         />
//                       </span>
//                       <h6>Jonelle Curtiss</h6>
//                     </div>
//                   </div>
//                   {/* <div className="col-md-4">
//                     <div className="input-block mb-0">
//                       <Select
//                         className="select"
//                         options={status4}
//                         placeholder="Inporgress"
//                         styles={customStyles}
//                       />
//                     </div>
//                   </div> */}
//                 </div>
//               </li>
//               <li>
//                 <div className="row d-flex justify-content-between">
//                   <div className="col-md-5">
//                     <div className="activity-name">
//                       <h5>Discussed budget proposal with Edwin</h5>
//                       <p>Due Date : 05 June 2024, 05:40 pm </p>
//                       <span className="badge activity-badge badge-bg-success">
//                         <i className="la la-phone" />
//                         Calls
//                       </span>
//                     </div>
//                   </div>
//                   <div className="col-md-2">
//                     <div className="user-activity">
//                       <span>
//                         <img
//                           // src={avatar25}
//                           alt="Img"
//                         />
//                       </span>
//                       <h6>Jonathan</h6>
//                     </div>
//                   </div>
//                   {/* <div className="col-md-4">
//                     <div className="input-block mb-0">
//                       <Select
//                         className="select"
//                         options={status5}
//                         placeholder="Inporgress"
//                         styles={customStyles}
//                       />
//                     </div>
//                   </div> */}
//                 </div>
//               </li>
//             </ul>
//           </div>
//         </div>
//       </div>
//       <AddActivity />
//     </div>
//     // </div>
//   );
// };

// export default allActivities;





import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query"; // React Query hook
import { fetchAllActivities } from "../../../../../hooks/useAdminDashboard"; // Import your API function
import AddActivity from "../../../../../components/modelpopup/Crm/AddActivity";

const demoActivities = [
  {
    id: 1,
    title: "We scheduled a meeting for next week",
    dueDate: "2024-02-10T09:00:00",
    type: "Meeting",
    badgeClass: "badge-bg-violet",
    icon: "la-user-tie",
    user: "Darlee Robertson",
    avatar: "",
  },
  {
    id: 2,
    title: "Built landing pages",
    dueDate: "2024-03-12T08:30:00",
    type: "Email",
    badgeClass: "badge-bg-warning",
    icon: "la-envelope",
    user: "Carol Thomas",
    avatar: "",
  },
  {
    id: 3,
    title: "Regarding latest updates in project",
    dueDate: "2024-05-25T14:10:00",
    type: "Meeting",
    badgeClass: "badge-bg-violet",
    icon: "la-user-tie",
    user: "Jonelle Curtiss",
    avatar: "",
  },
  {
    id: 4,
    title: "Discussed budget proposal with Edwin",
    dueDate: "2024-06-05T17:40:00",
    type: "Calls",
    badgeClass: "badge-bg-success",
    icon: "la-phone",
    user: "Jonathan",
    avatar: "",
  },
  {
    id: 5,
    title: "Reviewed quarterly goals",
    dueDate: "2024-01-15T10:30:00",
    type: "Meeting",
    badgeClass: "badge-bg-violet",
    icon: "la-user-tie",
    user: "Amelia Clarke",
    avatar: "",
  },
  {
    id: 5,
    title: "Reviewed quarterly goals",
    dueDate: "2024-01-15T10:30:00",
    type: "Meeting",
    badgeClass: "badge-bg-violet",
    icon: "la-user-tie",
    user: "Amelia Clarke",
    avatar: "",
  },
];

const AllActivities = () => {
  const {
    data: activities = demoActivities, // Use demo activities as fallback
    error,
    status,
    isLoading,
  } = useQuery({
    queryKey: ["all_activities"],
    queryFn: fetchAllActivities,
    initialData: demoActivities, // Set initial data
    refetchOnWindowFocus: false,
  });

  // Get the 4 latest activities sorted by dueDate
  const latestActivities = [...activities]
    .sort((a, b) => new Date(b.dueDate) - new Date(a.dueDate))
    .slice(0, 4);

  return (
    <div>
      <div className="card analytics-card" style={{ height: "610px" }}>
        <div className="card-header d-flex justify-content-between align-items-center flex-wrap row-gap-3">
          <h3>
            Activities
            <Link to="#">
              <i className="la la-link" />
            </Link>
          </h3>
          <div className="title-head d-flex">
            <Link to="/activities">
              <span className="me-1">View All</span>
            </Link>
          </div>
        </div>
        <div className="card-body">
          <div className="activities-list">
            {/* {isLoading && <p>Loading activities...</p>}
            {status === "error" && (
              <p>Failed to load activities: {error?.message}</p>
            )} */}
            {latestActivities.length > 0 ? (
              <ul>
                {latestActivities.map((activity) => (
                  <li key={activity.id}>
                    <div className="row d-flex justify-content-between">
                      <div className="col-md-5">
                        <div className="activity-name">
                          <h5>{activity.title}</h5>
                          <p>
                            Due Date:{" "}
                            {new Date(activity.dueDate).toLocaleString("en-US", {
                              month: "short",
                              day: "2-digit",
                              year: "numeric",
                              hour: "2-digit",
                              minute: "2-digit",
                              hour12: true,
                            })}
                          </p>
                          <span
                            className={`badge activity-badge ${activity.badgeClass}`}
                          >
                            <i className={`la ${activity.icon}`} />
                            {activity.type}
                          </span>
                        </div>
                      </div>
                      <div className="col-md-2">
                        <div className="user-activity">
                          <span>
                            <img
                              src={
                                activity.avatar || "https://via.placeholder.com/50"
                              } // Placeholder if no avatar
                              alt="User Avatar"
                            />
                          </span>
                          <h6>{activity.user}</h6>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            ) : (
              <p>No activities available.</p>
            )}
          </div>
        </div>
      </div>
      <AddActivity />
    </div>
  );
};

export default AllActivities;
