import React, { useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";

export const AddEmployeeQueryPopup = ({
  editable,
  employees,
  updateEmployeeData,
  addEmployeeData,
  selectedEmployee,
}) => {
  const [uploadedImages, setUploadedImages] = useState([]);
  // const statusOptions = [
  //   { value: "pending", label: "Pending" },
  //   { value: "in-process", label: "In-Process" },
  //   { value: "reviewed", label: "Reviewed" },
  //   { value: "completed", label: "Completed" },
  // ];

  const employeeDetails = employees?.map((emp) => ({
    value: emp?.id,
    label: (emp?.firstname || "") + " " + (emp?.lastname || ""),
  }));

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors, isValid },
    reset,
  } = useForm({
    mode: "onChange",
    defaultValues: {
      employee_id: null,
      title: "",
      description: "",
      image: null,
      status: "pending",
    },
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    // setFormData((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    if (editable) {
      const data = employeeDetails.filter((item) => {
        return item.value === selectedEmployee?.employee?.[0]?.id;
      });
      setValue("employee_id", data);
      setValue("status", selectedEmployee?.status || "pending");
      setValue("title", selectedEmployee?.title || "");
      setValue("description", selectedEmployee?.description || "");
      setValue("image", selectedEmployee?.image || null);
    } else {
      reset();
    }
  }, [editable, selectedEmployee, setValue, reset]);

  const handleImageUpload = (e) => {
    const files = Array.from(e.target.files);
    setUploadedImages((prevImages) => [...prevImages, ...files]);
  };

  const handleRemoveImage = (index) => {
    setUploadedImages((prevImages) => prevImages.filter((_, i) => i !== index));
  };

  const onSubmit = async (data) => {
    const formData = new FormData();

    // Append text fields
    if (Array.isArray(data.employee_id)){
      formData.append("employee_id", data?.employee_id?.[0]?.value);
    }
    else{
      formData.append("employee_id", data?.employee_id?.value);
    }
    formData.append("title", data.title);
    formData.append("description", data.description);
    formData.append("status", data.status);

    // Append images if any
    if (uploadedImages.length > 0) {
      uploadedImages.forEach((file) => {
        formData.append("image", file); // Append as an array if multiple files
      });
    }

    try {
      if (editable) {
        // For editing, include the ID in the payload
        formData.append("id", selectedEmployee.id);
        await updateEmployeeData(formData);
      } else {
        // For adding a new query
        await addEmployeeData(formData);
      }

      reset();
    } catch (error) {
      console.error("Error submitting form:", error);
    }
  };

  return (
    <>
      {/* Add Query Modal */}
      <div id="add_query" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {editable ? "Edit Query" : "Add Query"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Select Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employee_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={employeeDetails}
                        placeholder="Select"
                      />
                    )}
                  />
                  {errors.employee_id && (
                    <p className="text-danger">{errors.employee_id.message}</p>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Query Title <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: "Title is required" }}
                    render={({ field }) => (
                      <input {...field} className="form-control" type="text" />
                    )}
                  />
                  {errors.title && (
                    <p className="text-danger">{errors.title.message}</p>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Query Details <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Description is required" }}
                    render={({ field }) => (
                      <textarea {...field} rows={4} className="form-control" />
                    )}
                  />
                  {errors.description && (
                    <p className="text-danger">{errors.description.message}</p>
                  )}
                </div>
                {/* <div className="col-md-6">
                  <label className="col-form-label">Status</label>
                  <Select
                    name="status"
                    options={statusOptions}
                    // onChange={handleSelectChange}
                    className="select floating"
                    styles={customStyles}
                  />
                </div> */}

                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Upload Image</label>
                    <input
                      className="form-control"
                      type="file"
                      onChange={handleImageUpload}
                    />
                    <div className="mt-2">
                      {uploadedImages && (
                        <div>
                          <p>{uploadedImages.name}</p>
                          <button
                            type="button"
                            onClick={handleRemoveImage}
                            style={{
                              margin: "5px 5px",
                              border: "none",
                              background: "none",
                            }}
                          >
                            {/* <i className="fas fa-times" /> */}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={!isValid}
                    data-bs-dismiss="modal"
                  >
                    {editable ? "Save" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      <div id="edit_query" className="modal custom-modal fade" role="dialog">
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">
                {editable ? "Edit Query" : "Add Query"}
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Select Employee <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="employee_id"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={employeeDetails}
                        placeholder="Select"
                        // value={employeeDetails}
                        // defaultValue={employees[0]}
                        // styles={customStyles}
                      />
                    )}
                  />
                  {errors.employee && (
                    <p className="text-danger">{errors.employee.message}</p>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Query Title <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="title"
                    control={control}
                    rules={{ required: "Title is required" }}
                    render={({ field }) => (
                      <input {...field} className="form-control" type="text" />
                    )}
                  />
                  {errors.title && (
                    <p className="text-danger">{errors.title.message}</p>
                  )}
                </div>

                <div className="input-block mb-3">
                  <label className="col-form-label">
                    Query Details <span className="text-danger">*</span>
                  </label>
                  <Controller
                    name="description"
                    control={control}
                    rules={{ required: "Description is required" }}
                    render={({ field }) => (
                      <textarea {...field} rows={4} className="form-control" />
                    )}
                  />
                  {errors.description && (
                    <p className="text-danger">{errors.description.message}</p>
                  )}
                </div>
                {/* <div className="input-block mb-3">
                  <label className="col-form-label">Status</label>
                  <Controller
                    name="status"
                    control={control}
                    render={({ field }) => (
                      <Select
                        {...field}
                        options={statusOptions}
                        styles={customStyles}
                        placeholder="Select Status"
                      />
                    )}
                  />
                  {errors.status && (
                    <p className="text-danger">{errors.status.message}</p>
                  )}
                </div> */}

                <div className="col-md-6">
                  <div className="input-block mb-3">
                    <label className="col-form-label">Upload Image</label>
                    <input
                      className="form-control"
                      type="file"
                      onChange={handleImageUpload}
                    />
                    <div className="mt-2">
                      {uploadedImages && (
                        <div>
                          <p>{uploadedImages.name}</p>
                          <button
                            type="button"
                            onClick={handleRemoveImage}
                            style={{
                              margin: "5px 5px",
                              border: "none",
                              background: "none",
                            }}
                          >
                            {/* <i className="fas fa-times" /> */}
                          </button>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={!isValid}
                    data-bs-dismiss="modal"
                  >
                    {editable ? "Save" : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
