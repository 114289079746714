import React, { useRef, useState } from "react";
import InfoToast from "../../../layout/InfoToast";
import { useQuery, useMutation } from "@tanstack/react-query";
import {
  updateEmployeeAlert,
  addEmployeeAlert,
} from "../../../../hooks/useEmployeeAlert";
import { fetchDepartments } from "../../../../hooks/useDepartment";

const EmployeeAlert = () => {
  const [formData, setFormData] = useState({
    title: "",
    name: "",
    departments: "",
    message: "",
  });
  const [uploadedImage, setUploadedImage] = useState(null);
  const [success, setSuccess] = useState(false);
  const [toastType, setToastType] = useState({ label: "", type: "" });
  const fileUploadRef = useRef();
  const [isRefetching, setIsRefetching] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleFileChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      file: e.target.files[0],
    }));
  };

  // const uploadImageDisplay = () => {
  //   const uploadedFile = fileUploadRef.current.files[0];
  //   if (uploadedFile) {
  //     if (!["image/png", "image/jpeg"].includes(uploadedFile.type)) {
  //       setToastType({ label: "Invalid file type", type: "error" });
  //       return;
  //     }
  //     if (uploadedFile.size > 1048576) {
  //       setToastType({ label: "File size exceeds 1 MB", type: "error" });
  //       return;
  //     }
  //     setUploadedImage(uploadedFile);
  //   }
  // };

  // Fetch Deparments
  const {
    data: design,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["departments "],
    queryFn: fetchDepartments,
    refetchOnWindowFocus: false,
  });

  const { mutate: addEmployeeAlertMutation } = useMutation({
    mutationFn: addEmployeeAlert,
    onMutate: () => {
      setIsRefetching(true);
    },
    onSuccess: (response) => {
      setIsRefetching(false);
      setSuccess(true);
      setToastType({
        label: "Employee Alert Added",
        type: "success",
      });
    },
    onError: (error) => {
      setIsRefetching(false);
      setToastType({
        label: error.response?.data?.message || "An error occurred",
        type: "error",
      });
    },
  });
 const addEmployeeAlertData = (data) => {
    addEmployeeAlertMutation(data);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    const formDataToSend = new FormData();
    formDataToSend.append("title", formData.title);
    formDataToSend.append("department_id", formData.departments);  // Ensure you are using correct field
    formDataToSend.append("message", formData.message);
  
    // Ensure the image is correctly added to FormData
    if (formData.file) {
      formDataToSend.append("image", formData.file);
    }
  
    addEmployeeAlertMutation(formDataToSend);  // Sending FormData with file to mutation
  };
  

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <div className="row">
            <div className="col-md-8 offset-md-2 box-align">
              <h2 className="mb-3 mr-3">Employee Alert</h2>
              <form onSubmit={handleFormSubmit}>
                <div className="input-block row">
                  <label className="col-lg-2 mt-3">Title</label>
                  <div className="col-lg-9">
                    <input
                      name="title"
                      placeholder="Enter Title"
                      className="form-control"
                      value={formData.title}
                      onChange={handleInputChange}
                      type="text"
                      required
                    />
                  </div>
                </div>
                {/* <div className="input-block row">
                  <label className="col-lg-2 mt-3">Name</label>
                  <div className="col-lg-9">
                    <input
                      name="name"
                      placeholder="Enter Name"
                      className="form-control"
                      value={formData.name}
                      onChange={handleInputChange}
                      type="text"
                      required
                    />
                  </div>
                </div> */}
                <div className="input-block row">
                  <label className="col-lg-2 mt-3">Department</label>
                  <div className="col-lg-9 position-relative">
                    {isLoading ? (
                      <span>Loading...</span>
                    ) : isError ? (
                      <span>Error loading Department</span>
                    ) : (
                      <div className="dropdown-wrapper">
                        <select
                          name="departments"
                          className="form-control"
                          value={formData.department_id}
                          onChange={handleInputChange}
                        >
                          <option value="">Select Department</option>
                          {design?.map((d) => (
                            <option key={d.id} value={d.id}>
                              {d.department}
                            </option>
                          ))}
                        </select>
                        <i className="fas fa-chevron-down dropdown-icon"></i>
                      </div>
                    )}
                  </div>
                </div>

                <div className="input-block row">
                  <label className="col-lg-2 mt-3">Message</label>
                  <div className="col-lg-9">
                    <textarea
                      name="message"
                      placeholder="Enter Message"
                      className="form-control"
                      value={formData.message}
                      onChange={handleInputChange}
                      rows={4}
                      required
                    />
                  </div>
                </div>
                <div className="input-block row">
                  <label className="col-lg-2 mt-3">Image</label>
                  <div className="col-lg-7">
                  <input
                  type="file"
                  className="form-control"
                  id="policy_upload"
                  onChange={handleFileChange}
                  required
                />
                    <span className="form-text text-muted">
                      Recommended image size is 100px x 100px
                    </span>
                  </div>
                </div>
                <div className="submit-section">
                  <button
                    className="btn btn-primary submit-btn"
                    type="submit"
                    disabled={isRefetching}
                  >
                    {isRefetching ? "Submitting..." : "Submit"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      {success && (
        <InfoToast
          success={success}
          handleClose={() => setSuccess(false)}
          toastType={toastType}
        />
      )}
    </>
  );
};

export default EmployeeAlert;
