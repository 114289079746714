import React from "react";
import { Link } from "react-router-dom";
import { useQuery } from "@tanstack/react-query"; // React Query hook
import { fetchImportantNotifications } from "../../../../../hooks/useAdminDashboard"; // Import your API function

const demoNotifications = [
  {
    id: 1,
    type: "danger",
    category: "HR",
    title: "Your leave request has been approved",
    time: "02:10 PM",
    date: "21 Apr 2024",
  },
  {
    id: 2,
    type: "info",
    category: "ER",
    title: "You’re enrolled in upcoming training",
    time: "12:40 PM",
    date: "21 Apr 2024",
  },
  {
    id: 2,
    type: "info",
    category: "ER",
    title: "You’re enrolled in upcoming training",
    time: "12:40 PM",
    date: "21 Apr 2024",
  },
  {
    id: 2,
    type: "info",
    category: "ER",
    title: "You’re enrolled in upcoming training",
    time: "12:40 PM",
    date: "21 Apr 2024",
  },
  {
    id: 3,
    type: "warning",
    category: "SM",
    title: "Your annual compliance training is due",
    time: "11:00 AM",
    date: "21 Apr 2024",
  },
  {
    id: 4,
    type: "success",
    category: "AU",
    title: "HR system maintenance scheduled",
    time: "09:00 AM",
    date: "21 Apr 2024",
  },
];

const EmployeeNotifications = () => {
  const {
    data: notifications = demoNotifications, // Use demoNotifications as fallback
    error,
    status,
    isLoading,
  } = useQuery({
    queryKey: ["important_notifications"],
    queryFn: fetchImportantNotifications,
    initialData: demoNotifications, // Set initial data
    refetchOnWindowFocus: false,
  });

  return (
    <div>
      <div className="card flex-fill" style={{ height: "645px" }}>
        <div className="card-body">
          <div className="statistic-header">
            <h4>Important</h4>
            <div className="important-notification">
              <Link to="/activities">
                <span className="me-1">View All</span>
                {/* <ArrowRightCircle size={15} /> */}
              </Link>
            </div>
          </div>
          <div className="notification-tab">
            <ul className="nav nav-tabs">
              <li>
                <Link
                  to="#"
                  className="active"
                  data-bs-toggle="tab"
                  data-bs-target="#notification_tab"
                >
                  <i className="la la-bell" /> Notifications
                </Link>
              </li>
              {/* <li>
                <Link
                  to="#"
                  data-bs-toggle="tab"
                  data-bs-target="#schedule_tab"
                >
                  <i className="la la-list-alt" /> Schedules
                </Link>
              </li> */}
            </ul>
            <div className="tab-content">
              <div className="tab-pane active" id="notification_tab">
                <div className="employee-noti-content">
                  {isLoading && <p>Loading notifications...</p>}
                  {status === "error" ? (
                    <p>Failed to load notifications: {error?.message}</p>
                  ) : (
                    <ul className="employee-notification-list">
                      {notifications.map((notification) => (
                        <li
                          key={notification.id}
                          className="employee-notification-grid"
                        >
                          <div className="employee-notification-icon">
                            <Link to="/activities">
                              <span
                                className={`badge-soft-${
                                  notification.type || "info"
                                } rounded-circle`}
                              >
                                {notification.category || "N/A"}
                              </span>
                            </Link>
                          </div>
                          <div className="employee-notification-content">
                            <h6>
                              <Link to="/activities">{notification.title}</Link>
                            </h6>
                            <ul className="nav">
                              <li>{notification.time}</li>
                              <li>{notification.date}</li>
                            </ul>
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </div>
              <div className="tab-pane fade" id="schedule_tab">
                <p>Schedules tab content here...</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EmployeeNotifications;
