import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useQuery } from "@tanstack/react-query";
import { fetchAllowancesDeductions, fetchSalary } from "../../hooks/useSalary";

const EditSalaryModal = ({ editSalaryData, selectedSalary }) => {
  const [selectedStaff, setSelectedStaff] = useState(null);
  const [formData, setFormData] = useState({
    netSalary: "",
    earnings: {},  // earnings for allowances
    deductions: {},  // deductions for allowances
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    
    if (selectedSalary) {
      setSelectedStaff({
        value: selectedSalary.id,
        label: selectedSalary.name,
      });
      setFormData({
        netSalary: selectedSalary.netSalary,
        earnings: selectedSalary.allowances || {},  // Pre-fill earnings (allowances)
        deductions: selectedSalary.deductions || {},  // Pre-fill deductions
      });
    }
  }, [selectedSalary]);

  // Fetch allowances and deductions
  const {
    data: allowancesData,
    isLoading: isLoadingAllowances,
    error: allowancesError,
  } = useQuery({
    queryKey: ["allowancesDeductions"],
    queryFn: fetchAllowancesDeductions,
  });

  // Fetch employee names
  const {
    data: salaryData,
    isLoading: isLoadingSalary,
    error: salaryError,
  } = useQuery({
    queryKey: ["employee-salary"],
    queryFn: fetchSalary,
    refetchOnWindowFocus: false,
  });

  // Transform employee data for dropdown options
  const employeeOptions =
    salaryData?.data?.employees?.map((employee) => ({
      value: employee.id,
      label: `${employee.firstname}`,
    })) || [];

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#ff9b44" : "#fff",
      color: state.isFocused ? "#fff" : "#000",
      "&:hover": {
        backgroundColor: "#ff9b44",
      },
    }),
  };

  const handleInputChange = (e, type, key,itemId) => {
    const { value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [type]: {
        ...prev[type],
        [itemId]: {
          ...prev[type][itemId],
          value: value,
        },
      },
    }));
  };

  const validateForm = () => {
    const newErrors = {};
    if (!selectedStaff) newErrors.selectedStaff = "Please select a staff member.";
    if (!formData.netSalary) newErrors.netSalary = "Net Salary is required.";
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    
    e.preventDefault();
    if (validateForm()) {

      const validAllowancesPayload = formData?.earnings?.map((item)=>{
        return {
          allowance_id: item.allowance_id,
          amount: item.value,
        }
      })


      const validDeductionsPayload = formData?.deductions?.map((item)=>{
        return {
          deduction_id: item.deduction_id,
          amount: item.value,
        }
      })
      const payload = {
        employee_id: selectedStaff.value,
        net_salary: formData.netSalary,
        allowances: formData.earnings ? validAllowancesPayload : [],
        deductions: formData?.deductions ? validDeductionsPayload : [],
      };

      editSalaryData(payload);
    }
  };

  if (isLoadingAllowances || isLoadingSalary) return <div>Loading...</div>;
  if (allowancesError || salaryError)
    return <div>Error loading data. Please try again later.</div>;

  const { allowances, deduction: deductions } = allowancesData.data;

  return (
    <div id="edit_salary" className="modal custom-modal fade" role="dialog">
      <div
        className="modal-dialog modal-dialog-centered modal-lg"
        role="document"
      >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">Edit Staff Salary</h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-sm-6">
                  <label className="col-form-label">Select Staff</label>
                  <Select
                    value={selectedStaff}
                    options={employeeOptions}
                    onChange={setSelectedStaff}
                  />
                  {errors.selectedStaff && (
                    <div className="text-danger">{errors.selectedStaff}</div>
                  )}
                </div>
                <div className="col-sm-6">
                  <label className="col-form-label">Net Salary</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formData.netSalary}
                    onChange={(e) =>
                      setFormData({ ...formData, netSalary: e.target.value })
                    }
                  />
                  {errors.netSalary && (
                    <div className="text-danger">{errors.netSalary}</div>
                  )}
                </div>
              </div>
              {/* Allowances and Deductions */}
              <div className="row">
                <div className="col-sm-6">
                  <h4 className="text-primary">Allowances</h4>
                  {allowances.map((item) => (
                    <div className="mb-3" key={item.id}>
                      <label className="col-form-label">
                        {item.allowance}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={formData?.earnings[item.id]?.value || ""}
                        onChange={(e) =>
                          handleInputChange(e, "earnings", item.allowance, item.id)
                        }
                      />
                    </div>
                  ))}
                </div>
                <div className="col-sm-6">
                  <h4 className="text-primary">Deductions</h4>
                  {deductions.map((item) => (
                    <div className="mb-3" key={item.id}>
                      <label className="col-form-label">
                        {item.deduction}
                      </label>
                      <input
                        className="form-control"
                        type="text"
                        value={formData.deductions[item.id]?.value || ""}
                        onChange={(e) =>
                          handleInputChange(
                            e,
                            "deductions",
                            item.deduction,
                            item.id
                          )
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
              <div className="submit-section">
                <button
                  className="btn btn-primary submit-btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  type="submit"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditSalaryModal;
