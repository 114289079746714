import React, { useState } from "react";
import Breadcrumbs from "../../../../components/Breadcrumbs";
import ProfessionalExcellence from "./ProfessionalExcellence";
import PersonalExcellence from "./PersonalExcellence";
import Achievements from "./Achievements";
import CommandRoleTwo from "./CommandRoleTwo";
import PersonalUpdates from "./PersonalUpdates";
import ProfessionalGoals from "./ProfessionalGoals";
import GeneralComments from "./GeneralComments";
import ROUseOnly from "./ROUseOnly";
import HRDUseOnly from "./HRDUseOnly";
import { useQuery, useMutation } from "@tanstack/react-query";
import { fetchAllEmployees } from "../../../../hooks/useEmployee";
import {
  fetchEmployeePerformance,
  addPerformanceIndicator,
  updatePerformanceIndicator,
  deletePerformanceIndicator,
} from "../../../../hooks/usePerformanceIndicator";
import Select from "react-select";
import { type } from "@testing-library/user-event/dist/cjs/utility/type.js";

function PerformanceReview() {
  const [IsRefetch, setIsReftch] = useState(false);
  const [succes, setSuccess] = useState("");
  // const [error, setError] = useState("");
  const [toastType, setToastType] = useState("");

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedTlPmHr, setSelectedTlPmHr] = useState(null);


  // Fetch employees using React Query
  const { data, error, isLoading, refetch } = useQuery({
    queryKey: ["all_employees"],
    queryFn: fetchAllEmployees,
    refetchOnWindowFocus: false,
  });
  // Fetch Employee Performance
  // const { data, error, isLoading, refetch } = useQuery({
  //   queryKey: ["all_employees"],
  //   queryFn: fetchEmployeePerformance,
  //   refetchOnWindowFocus: false,
  // });

  const { mutate: addPerformanceIndicatorMutation } = useMutation({
    onMutate: () => {
      setIsReftch(true);
    },
    mutationFn: addPerformanceIndicator,
    onSuccess: (response) => {
      IsRefetch()
        .then(() => setIsReftch(false))
        .then(() => setSuccess(true));

      setToastType({
        label: response.message || "Performance added",
        type: "added",
      });
    },

    onError: (error) => {
      setToastType({
        label: error.response?.data.message || "An error occured",
        type: "Error",
      });
    },
  });

  const { mutate: updatePerformanceIndicatorMutation } = useMutation({
    onMutate: () => {
      setIsReftch(true);
    },
    mutationFn: updatePerformanceIndicator,
    onSuccess: (response) => {
      IsRefetch()
        .then(() => setIsReftch(false))
        .then(() => setSuccess(true));
      setToastType({
        label: response.message || "Perfromance Updated",
        type: "Updated",
      });
    },

    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error Occured",
        type: "error",
      });
    },
  });

  const { mutate: deletePerformanceIndicatorMutation } = useMutation({
    onMutate: () => {
      setIsReftch(true);
    },
    mutationFn: deletePerformanceIndicator,
    onSuccess: (response) => {
      IsRefetch()
        .then(() => setIsReftch(false))
        .then(() => setSuccess(true));

      setToastType({
        label: response.message || "Performace Deleted",
        type: "Deleted",
      });
    },

    onError: (error) => {
      setToastType({
        label: error.response?.data?.message || "An error Occured",
        type: "error",
      });
    },
  });

  const addPerformanceIndicatorData = (data) => {
    addPerformanceIndicatorMutation(data);
  };

  const updatePerformanceIndicatorData = (data) => {
    updatePerformanceIndicatorMutation(data);
  };

  const deletePerformanceIndicatorData = (data) => {
    deletePerformanceIndicatorMutation(data);
  };

  if (isLoading) {
    return <div>Loading...</div>;
  };
  if (error) {
    return <div>Error loading employees.</div>;
  }

  const employees = data?.employees || [];

  const employeeOptions = employees.map((emp) => ({
    value: emp.id,
    label: `${emp.firstname || ""} ${emp.lastname || ""}`.trim(),
    department: emp.department?.name || "Not Assigned",
    designation: emp.designation?.name || "Not Assigned",
    uuid: emp.uuid || "",
    birthday: emp.birthday || "" ,
    joining_date:emp.joining_date || "",
    company_email:emp.company_email || "",
    status:emp.status || "",
  }));

  return (
    <>
      <div className="page-wrapper">
        {/* Page Content */}
        <div className="content container-fluid">
          {/* Page Header */}
          <Breadcrumbs
            maintitle="Performance"
            title="Dashboard"
            subtitle="Performance"
          />

          {/* /Page Header */}
          <section className="review-section information">
            <div className="review-header text-center">
              <h3 className="review-title">Employee Basic Information</h3>
              {/* <p className="text-muted">Lorem ipsum dollar</p> */}
            </div>
            <div className="row">
              <div className="col-md-12 col-sm-12">
                <div className="table-responsive">
                  <table className="table table-bordered table-nowrap review-table mb-0">
                    <tbody>
                      <tr>
                        <td style={{width:"680px"}}>
                          <form>
                            {/* Employee Dropdown */}
                            <div className="input-block mb-3">
                              <label htmlFor="name">Select Employee</label>
                              <Select
                                options={employeeOptions}
                                value={selectedEmployee}
                                onChange={(selectedOption) =>
                                  setSelectedEmployee(selectedOption)
                                }
                                placeholder="Select an Employee"
                                isClearable
                                className="basic-single"
                                classNamePrefix="select"
                              />
                            </div>
                            {/* Department Field */}
                            <div className="input-block mb-3">
                              <label htmlFor="department">Department</label>
                              <input
                                type="text"
                                className="form-control"
                                id="department"
                                readOnly
                                value={selectedEmployee?.department || ""}
                                placeholder="Select an employee to view"
                              />
                            </div>
                            {/* Designation Field */}
                            <div className="input-block mb-3">
                              <label htmlFor="designation">Designation</label>
                              <input
                                type="text"
                                className="form-control"
                                id="designation"
                                readOnly
                                value={selectedEmployee?.designation || ""}
                                placeholder="Select an employee to view"
                              />
                            </div>
                            <div className="input-block">
                        <label htmlFor="empId">Emp ID</label>
                        <input
                          type="text"
                          className="form-control"
                          id="empId"
                          value={`TXP-${selectedEmployee?.uuid || ""}`}
                          readOnly
                        />
                      </div>

                            
                          </form>
                        </td>
                        <td>
                          <form >
                          <div className="input-block" >
                              <label htmlFor="dob">Date of Birth</label>
                              <input
                                type="date"
                                className="form-control"
                                id="doj"
                                value={selectedEmployee?.birthday || ""}
                                required
                              />
                              </div>
                          <div className="input-block">
                              <label htmlFor="doj">Date of Join</label>
                              <input
                                type="date"
                                className="form-control"
                                id="doj"
                                value={selectedEmployee?.joining_date
                                  || ""}
                                required
                              />
                            </div>
                            <div className="input-block">
                              <label htmlFor="qualif">Company Email </label>
                              <input
                                type="text"
                                className="form-control"
                                id="qualif"
                                value={selectedEmployee?.company_email
                                  || ""}
                                required
                              />
                            </div>
                            {/* <div className="input-block">
                              <label htmlFor="doc">Date of Confirmation</label>
                              <input
                                type="date"
                                className="form-control"
                                id="doc"
                                required
                              />
                            </div> */}
                            
                            <div className="input-block">
                              <label htmlFor="qualif1">
                                Status
                              </label>
                              <input
                                type="text"
                                className="form-control"
                                id="qualif1"
                                value={selectedEmployee?.status
                                  || ""}
                                required
                              />
                            </div>
                            
                          </form>
                        </td>
                        {/* <td>
                          <form>
                          <div className="input-block mb-3">
                              <label htmlFor="name">Select TL/PM/HR</label>
                              <Select
                                options={employeeOptions}
                                value={selectedTlPmHr}
                                onChange={(selectedOption) =>
                                  setSelectedTlPmHr(selectedOption)
                                }
                                placeholder="Select an TL/PM/HR"
                                isClearable
                                className="basic-single"
                                classNamePrefix="select"
                              />
                            </div>
                            <div className="input-block">
                              <label htmlFor="depart1">TM/PM/HR Designation:</label>
                              <input
                                type="text"
                                className="form-control"
                                value={selectedTlPmHr?.designation || ""}
                                id="depart1"
                                required
                              />
                            </div>
                            <div className="input-block">
                              <label htmlFor="depart1">Submited By:</label>
                              <input
                                type="text"
                                className="form-control"
                                id="depart1"
                                required
                              />
                            </div>
                          </form>
                        </td> */}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </section>
          <ProfessionalExcellence />
          <PersonalExcellence />
          {/* <Achievements />
          <CommandRoleTwo />
          <PersonalUpdates />
          <ProfessionalGoals />
          <GeneralComments />
          <ROUseOnly />
          // <HRDUseOnly /> */}
          <div
            className="d-flex"
            style={{ justifyContent: "flex-end", gap: "10px" }}
          >
            <div className="btn btn-primary btn-submit">Submit</div>
            <div className="btn btn-primary btn-submit">Reset</div>
          </div>
        </div>
        {/* /Page Content */}
      </div>
    </>
  );
}

export default PerformanceReview;
